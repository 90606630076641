import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import Switch from 'react-switch';

import Paper from '@Components/application/GrayPaper';
import MessageBoxPage from '@Components/application/MessageBox/MessageBoxPage';
import MessageBoxLLPage from '@Components/application/MessageBoxLL/MessageBoxLLPage';
import MiniFooter from '@Components/application/MiniFooter';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Breadcrumb from '@Components/Breadcrumb';
import Button, { ButtonStyles } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { Links, LocalStorage, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  DeathCaseInformation,
  ServiceProviderPreviewDownloadMutation,
  ServiceProviderPreviewDownloadMutationVariables,
  NotificationChatNode,
  NotificationChatChatType,
  CaseServiceProviderStatus,
  UserNode,
  RestrictedDeathCaseNode,
  RestrictedCaseServiceProviderNode,
  useDcInfoForPortalQuery,
  ServiceProviderTheirTier,
} from '@Graphql/graphqlTypes.generated';
import { mutationDownloadDCProviderInfo } from '@Graphql/providers/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import LLComments from '@Routes/dashboard/StaffPage/shared/LLComments/LLComments';
import ChatDeskPage from '@Routes/deathCase/ReadyDCPage/ChatDeskPage';
import ChatUploadModalBody from '@Routes/provider/ProviderDCInfoPage/ChatUploadModalBody';
import ProviderDCRefNumberPage from '@Routes/shared/Notification/ProviderDCRefNumberForm/ProviderDCRefNumberPage';
import SpInfoPage from '@Routes/shared/Notification/SPInfoNotificationDetails/SpInfoPage';
import SummaryCaseDetails from '@Routes/shared/Notification/SummaryCaseDetails';
import SummaryInfoContact from '@Routes/shared/Notification/SummaryInfoContact';
import SummaryInfoDeceased from '@Routes/shared/Notification/SummaryInfoDeceased';
import SummaryInfoNotifier from '@Routes/shared/Notification/SummaryInfoNotifier';
import NotificationStatusPanel from '@Routes/shared/NotificationStatusPanel';
import OtherNotifications from '@Routes/shared/OtherNotifications/OtherNotifications';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getDayMonth } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { capitalize, downloadFile } from '@Utils/helpers';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { notificationError } from '@Utils/notificationUtils';

import styles from './NotificationsPage.scss';

export interface DCDisplayInfo extends DeathCaseInformation {
  accessCode: string;
}

export interface NotificationsPageProps {
  dcInfo: DCDisplayInfo | null;
  isStaff?: boolean;
  msgAccessCode?: string;
  isChat?: boolean;
  providerDash?: boolean;
  user?: UserNode | null;
}

export enum NotificationTabs {
  TabOverview,
  TabMessages,
  TabDocuments,
  TabSPInfo,
  TabOtherNotifications,
}

interface ColloboratorSection {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  header: string;
  description: string;
}

const NotificationsPage: React.FunctionComponent<NotificationsPageProps> = ({
  dcInfo,
  msgAccessCode,
  isChat,
  providerDash = false,
  user,
  isStaff,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const enableTuCheck = useSelector(getEnableTuCheck);
  const [chatType, setChatType] = useState(NotificationChatChatType.ServiceProvider);
  const [showHelp, setShowHelp] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramStaffTab = searchParams.get('stafftab') || '';
  const staffTabSelected =
    isStaff && paramStaffTab === NotificationTabs.TabSPInfo.toString()
      ? NotificationTabs.TabSPInfo
      : NotificationTabs.TabOverview;
  const [activeTab, setActiveTab] = React.useState(
    (msgAccessCode && msgAccessCode !== '') || isChat ? NotificationTabs.TabMessages : staffTabSelected
  );
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isIpadMini = useMediaQuery({ query: ReactResponsiveQueries.IpadMini });

  const [llcommentText, setLLCommentText] = React.useState('');
  const [spNotesText, setSpNotesText] = React.useState('');
  const [messageBox, setMessageBox] = React.useState('');
  const [staffView, setStaffView] = React.useState(isStaff);

  const notifyCase = dcInfo?.deathCase;

  const setDocumentTitle = (notifyNumber: string) => {
    document.title = notifyNumber;
  };

  if (notifyCase?.notificationNumber) {
    setDocumentTitle(notifyCase.notificationNumber);
  }

  const [downloadInfoMutation, { loading: downloading }] = useMutation<
    ServiceProviderPreviewDownloadMutation,
    ServiceProviderPreviewDownloadMutationVariables
  >(mutationDownloadDCProviderInfo, {
    variables: { input: { caseServiceProviderId: dcInfo?.deathCaseServiceProvider.id || '' } },
    onCompleted: (result) => {
      downloadFile(result.serviceProviderPreviewDownload.file);
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const notificationOverviewValues = useDcInfoForPortalQuery({
    fetchPolicy: 'network-only',
    skip: !staffView,
    variables: {
      accessCode: dcInfo ? dcInfo?.accessCode : '',
      isStaff,
    },
  });

  if (notificationOverviewValues.loading) {
    return <LoadingSpinner />;
  }

  if (!dcInfo) {
    return null;
  }

  const staffDeathCase = notificationOverviewValues.data?.dcInfoForPortal.deathCase as RestrictedDeathCaseNode;

  const staffDeathCaseProvider = notificationOverviewValues.data?.dcInfoForPortal
    .deathCaseServiceProvider as RestrictedCaseServiceProviderNode;

  const { deathCaseServiceProvider, deathCase, caseAccountDetails, accessCode, caseChats } = dcInfo;

  const finalDeathCase = staffView ? staffDeathCase : deathCase;

  const finalDeathCaseProvider = staffView ? staffDeathCaseProvider : deathCaseServiceProvider;

  const isOdd = (num: number) => {
    return num % 2;
  };

  const getEmailId = () => {
    const sessionEmailId = getFromLocalStorage(LocalStorage.providerEmail);
    if (sessionEmailId !== null) {
      return sessionEmailId;
    }
    if (finalDeathCaseProvider.notificationEmail !== '') {
      return finalDeathCaseProvider.notificationEmail;
    }
    return null;
  };

  const providerEmailId = getEmailId();

  if (providerEmailId !== null) {
    setToLocalStorage(LocalStorage.providerEmail, providerEmailId);
  }

  const deathCasesTabs = [
    { title: 'Overview' },
    {
      title: 'Messages',
      disable: deathCaseServiceProvider.serviceProvider.theirTier !== ServiceProviderTheirTier.Premium,
      content: { header: t('title_message_premium' as Messages), desc: t('desc_message_premium' as Messages) },
      showLock: true,
    },
    {
      title: 'Uploaded documents',
      disable: deathCaseServiceProvider.serviceProvider.theirTier !== ServiceProviderTheirTier.Premium,
      content: { header: t('title_message_premium' as Messages), desc: t('desc_message_premium' as Messages) },
      showLock: true,
    },
  ];
  const spNotificationTabs = [
    { title: 'Overview' },
    {
      title: 'Messages & document upload',
      disable: deathCaseServiceProvider.serviceProvider.theirTier !== ServiceProviderTheirTier.Premium,
      content: { header: t('title_message_premium' as Messages), desc: t('desc_message_premium' as Messages) },
      showLock: true,
    },
  ];
  const userStaffNotificationTabs = [
    { title: 'Overview' },
    { title: 'Messages & document upload' },
    { title: 'LL Comments' },
    { title: 'SP info' },
    { title: 'Other notifications' },
  ];

  const relevantPartner = (): ColloboratorSection[] => {
    if (finalDeathCase && finalDeathCase?.partner !== null) {
      return [
        {
          firstName: finalDeathCase.partner.firstName,
          lastName: finalDeathCase.partner.lastName,
          email: finalDeathCase.partner.email,
          phone: finalDeathCase.partner.phone,
          header: 'Surviving partner details',
          description: 'The details of the deceased’s surviving partner',
        },
      ];
    }
    return [];
  };

  const relevantExecutors = (): ColloboratorSection[] => {
    if (finalDeathCase && finalDeathCase.executors && finalDeathCase.executors.length > 0) {
      return finalDeathCase.executors.map((exec: any) => ({
        firstName: exec.firstName,
        lastName: exec.lastName,
        email: exec.email,
        phone: exec.phone,
        header: 'Executors details',
        description: 'The details of the deceased’s executor',
      }));
    }
    return [];
  };

  const relevantLegals = (): ColloboratorSection[] => {
    if (finalDeathCase && finalDeathCase.legalRepresentatives && finalDeathCase.legalRepresentatives.length > 0) {
      return finalDeathCase.legalRepresentatives.map((exec: any) => ({
        firstName: exec.firstName,
        lastName: exec.lastName,
        email: exec.email,
        phone: exec.phone,
        header: 'Legal representation',
        description: 'The details of the deceased’s solicitor',
      }));
    }
    return [];
  };

  const relevantCollaborators = relevantPartner().concat(relevantExecutors(), relevantLegals());

  const openChatTypeName = finalDeathCase?.notifier
    ? `${finalDeathCase.notifier.firstName} ${finalDeathCase.notifier.lastName}`
    : '';

  const openChatTypeAccount = 'Notifier';

  const userAccountType = undefined;

  const openChatSpLatestDate = () => {
    if (caseChats.length > 0) {
      return getDayMonth(
        caseChats
          .map((x: NotificationChatNode) => new Date(x.commentedAt))
          .sort((a: any, b: any) => b - a)
          .reverse()[0]
      );
    }
    return '';
  };

  const mainContainer = isMobile ? 12 : 12;

  setToLocalStorage(LocalStorage.referenceCode, finalDeathCaseProvider.referenceCode);

  const hideLogoReference = () => {
    if (isTablet || providerDash) {
      return false;
    }
    return true;
  };

  const statusClass =
    finalDeathCaseProvider.status === CaseServiceProviderStatus.AlreadyRegistered ? styles.alRegClass : styles.topClass;

  const breadcrumbItems = [
    { title: `Death notifications: ${capitalize(finalDeathCase?.firstName)} ${capitalize(finalDeathCase?.lastName)}` },
  ];

  const staffBreadcrumbItems = [
    { title: `${t(Messages.labelNotificationOurRef)} ${finalDeathCase?.notificationNumber}` },
  ];

  const deskSize = isStaff || providerDash ? 12 : 8;

  const setMessagesDocumentsTab = () => {
    setActiveTab(NotificationTabs.TabMessages);
    window.scrollTo(0, 0);
  };

  const bodyContainer = isStaff ? 11 : 8;

  const nonStaffTabs = providerDash ? spNotificationTabs : deathCasesTabs;

  const setLLCommentCallBack = (comm: string) => {
    setLLCommentText(comm);
  };

  const setSpNotesCallback = (notes: string) => {
    setSpNotesText(notes);
  };

  const setMessageBoxTextCallback = (msg: string) => {
    setMessageBox(msg);
  };

  return (
    <>
      <section
        className={classNames(
          providerDash ? styles.notficationDashboardContainer : styles.notficationMainContainer,
          statusClass,
          {
            [styles.spMainContainer]: providerDash,
          }
        )}
      >
        <div className={classNames({ [styles.headerContainer]: isStaff })}>
          {isStaff && <Breadcrumb dashboardText="All notifications" breadcrumbItems={staffBreadcrumbItems} />}
          <Row
            className={classNames(styles.flexGap, {
              [styles.headerInset]: providerDash,
              [styles.headerContainer]: !isStaff,
              [styles.marginTop33]: isStaff,
            })}
            size={12}
          >
            {hideLogoReference() && (
              <Col className={styles.marginTop6}>
                <Row column>
                  <Col>
                    <a href={Links.home}>
                      <img
                        width="173"
                        height="37"
                        src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                        alt=""
                      />
                    </a>
                  </Col>
                  <Col className={styles.referenceNumber}>
                    <Typography
                      msg={`${t(Messages.labelNotificationOurRef)} ${finalDeathCase?.notificationNumber}`}
                      tag="span"
                      size="l"
                      className={styles.whiteSpace}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            <Col size={isTablet ? mainContainer : bodyContainer}>
              {userAccountType && (
                <div className={styles.professionalInfo}>
                  <Row constant size={12}>
                    <Col>
                      <Icon className={styles.infoIcon} icon="info" />
                    </Col>
                    <Col alignCenter className={styles.chatInfoSpacing}>
                      <Typography
                        msg="This death notification is from Oracle Solicitors &amp; Partners"
                        tag="h5"
                        size="l"
                      />
                    </Col>
                  </Row>
                  <Row className={styles.listContainer}>
                    <ul>
                      <li>
                        <a href="/#" target="_blank" rel="noopener noreferrer" download>
                          <Typography
                            tag="div"
                            msg="View power of attorney document"
                            size="s"
                            color="white"
                            underline
                          />
                        </a>
                      </li>

                      <li>
                        <a href="/#" target="_blank" rel="noopener noreferrer" download>
                          <Typography
                            tag="div"
                            msg="View letter of engagement or instruction"
                            size="s"
                            color="white"
                            underline
                          />
                        </a>
                      </li>

                      <li>
                        <a href="/#" target="_blank" rel="noopener noreferrer" download>
                          <Typography tag="div" msg="View request letter" size="s" color="white" underline />
                        </a>
                      </li>
                    </ul>
                  </Row>
                </div>
              )}

              {providerDash && <Breadcrumb breadcrumbItems={breadcrumbItems} />}

              <Row>
                <Typography
                  msg={`${t(Messages.titleProviderAccessPage)} ${finalDeathCaseProvider.serviceProvider.name}`}
                  tag="div"
                  bold
                  size="l"
                />
              </Row>
              <ProviderDCRefNumberPage accessCode={accessCode} referenceCode={finalDeathCaseProvider.referenceCode} />

              <NotificationStatusPanel
                status={finalDeathCaseProvider.status}
                accessCode={accessCode}
                deathCaseServiceProvider={finalDeathCaseProvider}
                providerDash={providerDash}
                isStaff={isStaff}
                deathCase={finalDeathCase}
              />

              <div
                onClick={() => setShowHelp(!showHelp)}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13) {
                    setShowHelp(!showHelp);
                  }
                }}
                className={classNames(
                  showHelp ? styles.helpContainer : styles.helpCollapsedContainer,
                  styles.handSymbol
                )}
                //eslint-disable-next-line
              tabIndex={0}
              >
                <Row constant size={12} alignCenter>
                  <Col alignCenter className={styles.bulbContainer}>
                    <Icon icon="bulb" />
                  </Col>
                  <Col size={isMobile ? 11 : 8}>
                    <Typography msg="How to manage this notification" tag="h5" size="l" bold />
                  </Col>
                  <Col className={styles.subtitleSpacing} textRight size={isMobile ? 1 : 4}>
                    <Icon size={IconSizes.s} icon={showHelp ? 'chevronUp' : 'chevronDown'} />
                  </Col>
                </Row>
                {showHelp && (
                  <Row
                    className={classNames(styles.handSymbol, styles.mt24, styles.flexGap)}
                    onClick={() => setShowHelp(!showHelp)}
                    size={12}
                  >
                    <Col className={styles.equalWidth}>
                      <Row column>
                        <Col className={styles.mt1}>
                          <Icon size={IconSizes.switchIcon} icon="shuffle" className={styles.messageSquare} />
                        </Col>
                        <Col>
                          <Typography msg="Switch or close" tag="div" size="m" bold color="profRegisterBackground" />
                        </Col>
                        <Col className={styles.helpStatusVerfication}>
                          <Typography
                            msg="Use the account information in the overview tab to close or switch the account, depending on what the customer has requested"
                            tag="p"
                            size="l"
                          />
                        </Col>
                        {finalDeathCase.shouldPassPartnersUtility && (
                          <>
                            <Col>
                              <Typography msg="Switch to who" tag="div" size="m" bold />
                            </Col>
                            <Col>
                              <Typography
                                msg={`${finalDeathCase.partner.firstName} ${finalDeathCase.partner.lastName}`}
                                tag="p"
                                size="l"
                              />
                            </Col>
                            <Col>
                              <Typography msg={finalDeathCase.partner.email} tag="p" size="l" />
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>

                    <Col className={styles.equalWidth}>
                      <Row column>
                        <Col>
                          <Icon icon="message-square" size={IconSizes.noteIcons} className={styles.messageSquare} />
                        </Col>
                        <Col>
                          <Typography
                            msg="Message the customer"
                            tag="div"
                            size="m"
                            bold
                            color="profRegisterBackground"
                          />
                        </Col>
                        <Col className={styles.helpStatusVerfication}>
                          <Typography
                            msg="Use the messages tab to communicate with the customer directly for any query"
                            tag="p"
                            size="l"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col className={styles.equalWidth}>
                      <Row column>
                        <Col>
                          <Icon className={styles.messageSquare} icon="layers-outline" size={IconSizes.noteIcons} />
                        </Col>
                        <Col>
                          <Typography
                            msg="Provide additional info"
                            tag="div"
                            size="m"
                            bold
                            color="profRegisterBackground"
                          />
                        </Col>
                        <Col className={styles.helpStatusVerfication}>
                          <Typography
                            msg="Use the documents tab to upload any supplemental account information such as the details of shared account holders or final balances"
                            tag="p"
                            size="l"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>

              <Tabs
                className={styles.tabContainer}
                tabs={isStaff ? userStaffNotificationTabs : nonStaffTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </Col>
          </Row>
        </div>
        <Row>
          {!isTablet && !isStaff && <div className={styles.greenEllipsis} />}
          {!isTablet && !isStaff && <div className={styles.greenEllipsis1} />}
          {hideLogoReference() && (
            <Col
              className={classNames({ [styles.pr12]: activeTab !== NotificationTabs.TabOtherNotifications })}
              size={1}
            />
          )}
          <Col
            className={classNames(styles.mobileBody, {
              [styles.pl34]: providerDash,
              [styles.pl8]: !isStaff,
              [styles.staffBody]: isStaff && activeTab === NotificationTabs.TabOtherNotifications,
            })}
            size={isTablet ? 12 : deskSize}
          >
            {activeTab === NotificationTabs.TabOverview && (
              <>
                <Row className={styles.marginTop33}>
                  <Typography
                    msg="This is your notification dashboard, from here you have access to all of the information you need to process the death of the deceased; as well as send messages to the notifier; contact Life ledger; and upload files such as final statements."
                    tag="div"
                    size="m"
                    color="footerColor"
                  />
                </Row>
                {isStaff && (
                  <Row size={12} className={styles.marginTop27}>
                    <Col size={9}>
                      <Typography msg="Life Ledger staff view" tag="div" size="l" bolder className={styles.lh24} />
                      <Typography
                        msg={t('toggle_notify_msg' as Messages)}
                        tag="div"
                        size="m"
                        color="footerColor"
                        html
                      />
                    </Col>
                    <Col size={3} className={styles.flexEnd}>
                      <Switch
                        onChange={() => setStaffView(!staffView)}
                        checked={staffView || false}
                        height={27.69}
                        width={54}
                        onColor="#F6C600"
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </Col>
                  </Row>
                )}
                <Row className={styles.marginTop33}>
                  <Typography msg="Account details" tag="h4" size="xl" />
                </Row>
                <div className={styles.colContainer}>
                  <SummaryInfoDeceased
                    deathCase={finalDeathCase}
                    userAccountType={userAccountType}
                    deathCaseServiceProvider={finalDeathCaseProvider}
                    accessCode={accessCode}
                  />

                  <SummaryCaseDetails
                    instructionLetters={finalDeathCase.instructionLetters}
                    caseDetails={caseAccountDetails}
                    userAccountType={userAccountType}
                    deathCaseServiceProvider={finalDeathCaseProvider}
                    accessCode={accessCode}
                    deathCase={finalDeathCase}
                  />
                </div>

                {finalDeathCase.notifier && finalDeathCase.notifier.firstName && (
                  <>
                    <Row constant className={styles.marginTop33}>
                      <Typography msg="Notifier" tag="h4" size="xl" />
                      <Tooltip
                        title="What is a notifier?"
                        message="The notifier is the person who sent you the notification of death, it can be a relative or friend of the deceased; probate specialist; or solicitor."
                        position={TooltipPosition.top}
                      >
                        <Icon className={styles.infoIcon} icon="info" />
                      </Tooltip>
                    </Row>
                    <div className={styles.colContainer}>
                      <SummaryInfoContact
                        key={finalDeathCase.notifier.id}
                        label={t(Messages.labelNotifierName)}
                        email={finalDeathCase.notifier.email}
                        phone={finalDeathCase.notifier.phone}
                        relationshipTitle={t(Messages.titleCollaboratorRelationship)}
                        notifierNote={t(Messages.labelNotifierNote)}
                        address1={finalDeathCase.notifier.address1}
                        address2={finalDeathCase.notifier.address2}
                        companyName={finalDeathCase.notifier.professionalCompany}
                        city={finalDeathCase.notifier.city}
                        postalCode={finalDeathCase.notifier.postalCode}
                        header="Notifier’s details"
                        description="The notifier is the person who sent the death notification"
                        userAccountType={userAccountType}
                        isFromNotifier={true}
                        deathCase={finalDeathCase}
                        deathCaseServiceProvider={finalDeathCaseProvider}
                        accessCode={accessCode}
                      />
                      {(finalDeathCaseProvider.serviceProvider.showNotifierIdDocuments ||
                        finalDeathCaseProvider.serviceProvider.isUserValidationRequired ||
                        enableTuCheck) && (
                        <SummaryInfoNotifier
                          deathCase={finalDeathCase}
                          userAccountType={userAccountType}
                          deathCaseServiceProvider={finalDeathCaseProvider}
                          accessCode={accessCode}
                          setActiveTabCallback={setMessagesDocumentsTab}
                          user={user}
                        />
                      )}

                      {/* {finalDeathCaseProvider.serviceProvider.showNotifierIdDocuments && (
                        <SummarInfoNotifierIdDocument
                          userAccountType={userAccountType}
                          caseServiceProvider={finalDeathCaseProvider}
                          accessCode={accessCode}
                        />
                      )} */}
                    </div>
                    {relevantCollaborators.length > 0 && (
                      <>
                        <Row constant className={styles.marginTop33}>
                          <Typography msg="Collaborators" tag="h4" size="xl" />
                          <Tooltip
                            title="What is a collaborator?"
                            message={t(Messages.tooltipCollaboratorInfo)}
                            position={TooltipPosition.top}
                          >
                            <Icon className={styles.infoIcon} icon="info" />
                          </Tooltip>
                        </Row>
                        <div className={styles.relevantContainer}>
                          {relevantCollaborators.map((c: ColloboratorSection, idx: number) => {
                            return (
                              <div key={idx} className={isOdd(idx) ? styles.relevantBox : styles.relevantBoxPadding}>
                                <SummaryInfoContact
                                  label="Name"
                                  name=""
                                  firstName={c.firstName}
                                  lastName={c.lastName}
                                  email={c.email}
                                  phone={c.phone}
                                  header={c.header}
                                  description={c.description}
                                  userAccountType={userAccountType}
                                  isCollaborator
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}

                    {finalDeathCaseProvider.serviceProvider.allowCsvDownload && (
                      <Row className={styles.csvFileButton}>
                        <Button
                          label="Download information as a CSV file"
                          onClick={() => downloadInfoMutation()}
                          disabled={downloading}
                          style={ButtonStyles.transparent}
                          isFullWidth={isIpadMini}
                        />
                      </Row>
                    )}
                  </>
                )}
              </>
            )}
            {activeTab === NotificationTabs.TabMessages && !isStaff && !user && (
              <>
                <Row className={styles.marginTop33}>
                  <Typography
                    msg="Use the chat box below to send messages safely and securely to the notifier."
                    tag="div"
                    size="m"
                    family="helvetica"
                    color="footerColor"
                  />
                </Row>
                <Row size={12} className={classNames(styles.marginRight16, styles.marginTop33, styles.gap8)}>
                  <Col size={3} className={styles.marginRight16}>
                    <Paper width="width-full" noInsideSpacing>
                      <div className={styles.internalSpacing}>
                        <Row className={styles.header}>
                          <CardTitle heading="Open chats" />
                        </Row>
                        <div
                          className={
                            chatType === NotificationChatChatType.ServiceProvider
                              ? styles.chatTypeSelected
                              : styles.chatTypeUnSelected
                          }
                          onClick={() => setChatType(NotificationChatChatType.ServiceProvider)}
                        >
                          <Row size={12}>
                            <Col size={9}>
                              <Typography msg={openChatTypeName} tag="span" size="s" bold />
                            </Col>
                            <Col size={3}>
                              <Typography msg={openChatSpLatestDate()} tag="span" size="s" color="footerColor" />
                            </Col>
                          </Row>
                          <Row size={12}>
                            <Col size={12}>
                              <Typography msg={openChatTypeAccount} tag="span" size="s" color="footerColor" />
                            </Col>
                          </Row>
                        </div>

                        <Row className={styles.bottomBorder}>
                          <span />
                        </Row>
                      </div>
                    </Paper>
                  </Col>
                  <Col size={9}>
                    {chatType === NotificationChatChatType.ServiceProvider ? (
                      <MessageBoxPage
                        deathCaseServiceProvider={finalDeathCaseProvider}
                        chatTitlePersonName={openChatTypeName}
                        chatType={chatType}
                        accessCode={accessCode}
                      />
                    ) : (
                      <MessageBoxLLPage deathCaseServiceProvider={finalDeathCaseProvider} chatType={chatType} />
                    )}
                  </Col>
                </Row>
              </>
            )}
            {activeTab === NotificationTabs.TabMessages && user && (
              <>
                <Typography
                  msg="Use the chat box below to send messages safely and securely to the notifier."
                  color="footerColor"
                  tag="div"
                  size="m"
                  className={styles.marginTop33}
                />

                <ChatDeskPage
                  isCustomer={false}
                  accessCode={accessCode}
                  deathCaseServiceProvider={finalDeathCaseProvider}
                  deathCase={finalDeathCase}
                  user={user}
                  msgParent={messageBox}
                  setMsgCallback={setMessageBoxTextCallback}
                />
              </>
            )}
            {activeTab === NotificationTabs.TabDocuments && (
              <>
                {!isStaff && (
                  <>
                    <Row className={styles.marginTop33}>
                      <Typography
                        msg="Upload any relevant documentation, this will be shared with the notifier."
                        tag="div"
                        size="m"
                        family="helvetica"
                        color="footerColor"
                      />
                    </Row>
                    <div className={styles.marginTop33}>
                      <ChatUploadModalBody
                        caseData={finalDeathCase}
                        deathCaseServiceProvider={finalDeathCaseProvider}
                        accessCode={accessCode}
                      />
                    </div>
                  </>
                )}
                {isStaff && (
                  <div className={styles.marginTop33}>
                    <LLComments
                      llcommentParent={llcommentText}
                      setLLCommentCallback={setLLCommentCallBack}
                      caseServiceProviderId={finalDeathCaseProvider.notificationId}
                      user={user}
                      accessCode={accessCode}
                    />
                  </div>
                )}
              </>
            )}
            {activeTab === NotificationTabs.TabSPInfo && (
              <>
                <Row className={styles.marginTop33}>
                  <Typography msg="Service Provider Information" tag="h4" size="xl" bold />
                </Row>
                <div>
                  <SpInfoPage
                    setSpNotesCallback={setSpNotesCallback}
                    spNotesParent={spNotesText}
                    serviceProviderId={finalDeathCaseProvider.serviceProvider.id}
                    user={user}
                  />
                </div>
              </>
            )}

            {activeTab === NotificationTabs.TabOtherNotifications && (
              <div className={styles.marginTop33}>
                <Row justifyCenter>
                  <Typography
                    msg="This is your notification dashboard, from here you have access to all of the information you need to process the death of the deceased; as well as send messages to the notifier; contact Life ledger; and upload files such as final statements."
                    tag="div"
                    size="m"
                    color="footerColor"
                    className={styles.mobileBody}
                  />
                </Row>
                <OtherNotifications
                  baseCaseId={finalDeathCase.id}
                  caseServiceProviderId={finalDeathCaseProvider.notificationId}
                />
              </div>
            )}
            {!isTablet && <MiniFooter />}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default NotificationsPage;
