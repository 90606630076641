import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import TextInput from '@Components/form/inputs/TextInput';
import ToggleSwitchInput from '@Components/form/inputs/ToggleSwitchInput';
import FormError from '@Components/FormError';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import { Messages } from '@Config/messages';
import { queryAgentPortalUserInvitations, queryAgentPortalUsers } from '@Graphql/agent/queries';
import {
  AddInvitationMutation,
  AddInvitationMutationVariables,
  ServiceProviderPortalUserNode,
  PortalUpdateUserMutation,
  PortalUpdateUserMutationVariables,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import { mutationAddInvitation, mutationPortalUpdateUser } from '@Graphql/serviceProvidersPortal/mutations';
import {
  queryProfessionalPortalUserInvitations,
  queryProfessionalPortalUsers,
  queryServiceProviderPortalUsers,
  queryServiceProviderUserInvitations,
} from '@Graphql/serviceProvidersPortal/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import { validateEmail } from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import userStyles from './AddUser.scss';

export interface AddUserProps {
  userNode?: ServiceProviderPortalUserNode;
  firstButtonText: string;
}

enum AddUserFields {
  email = 'email',
  isPortalAdmin = 'isPortalAdmin',
  //isMfaEnabled = 'isMfaEnabled',
}

const AddUser: React.FunctionComponent<ModalProviderProps<AddUserProps>> = ({
  modalData: { userNode, firstButtonText },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const onButtonClick = () => {
    closeModal();
  };

  const secondaryQueryName =
    user?.accountType === UserAccountType.Professional ? queryProfessionalPortalUsers : queryAgentPortalUsers;
  const secondaryQueryInvite =
    user?.accountType === UserAccountType.Professional
      ? queryProfessionalPortalUserInvitations
      : queryAgentPortalUserInvitations;

  const [addInvite] = useMutation<AddInvitationMutation, AddInvitationMutationVariables>(mutationAddInvitation, {
    refetchQueries: [
      getOperationName(
        user?.accountType === UserAccountType.ServiceProvider ? queryServiceProviderPortalUsers : secondaryQueryName
      ) || '',
      getOperationName(
        user?.accountType === UserAccountType.ServiceProvider
          ? queryServiceProviderUserInvitations
          : secondaryQueryInvite
      ) || '',
    ],
    onCompleted: (response: any) => {
      dispatch(notificationSuccess(t(response.addInvitation.message)));
      dispatch(closeModal());
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [updateUser] = useMutation<PortalUpdateUserMutation, PortalUpdateUserMutationVariables>(
    mutationPortalUpdateUser,
    {
      refetchQueries: [
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider ? queryServiceProviderPortalUsers : secondaryQueryName
        ) || '',
        getOperationName(
          user?.accountType === UserAccountType.ServiceProvider
            ? queryServiceProviderUserInvitations
            : secondaryQueryInvite
        ) || '',
      ],
      onCompleted: (response: any) => {
        dispatch(notificationSuccess(t(response.portalUpdateUser.message)));
        dispatch(closeModal());
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  return (
    <>
      <Formik
        initialValues={{
          [AddUserFields.email]: userNode?.info.email || '',
          [AddUserFields.isPortalAdmin]: userNode?.isAdmin || false,
          //[AddUserFields.isMfaEnabled]: userNode?.info.isMfaEnabled || false,
        }}
        validationSchema={Yup.object({
          [AddUserFields.email]: validateEmail(t),
        })}
        onSubmit={(values) => {
          if (userNode) {
            updateUser({
              variables: {
                input: {
                  id: userNode?.info.id,
                  isPortalAdmin: values.isPortalAdmin,
                  // isMfaEnabled: values.isMfaEnabled,
                },
              },
            });
          } else {
            addInvite({
              variables: {
                input: {
                  email: values.email.trim(),
                  isAdmin: values.isPortalAdmin,
                  //  isMfaEnabled: values.isMfaEnabled,
                },
              },
            });
          }
        }}
      >
        {({ isSubmitting, status }) => (
          <Form className={userStyles.formContainer}>
            <GridRow>
              <GridCol size={10}>
                <Field
                  name={AddUserFields.email}
                  type="text"
                  component={TextInput}
                  disabled={!!userNode}
                  label={t(Messages.labelProviderEmailAddress)}
                />
              </GridCol>
            </GridRow>

            <GridRow className={userStyles.providerRoleSection}>
              <GridCol size={12}>
                <Field
                  name={AddUserFields.isPortalAdmin}
                  label={t(Messages.labelProviderRole)}
                  component={ToggleSwitchInput}
                />
              </GridCol>
            </GridRow>

            {/* <GridRow className={userStyles.mfaEnabledSection}>
              <GridCol size={12}>
                <Field
                  name={AddUserFields.isMfaEnabled}
                  label={t(Messages.msgEnableMultiFactorAuthentication)}
                  component={ToggleSwitchInput}
                />
              </GridCol>
            </GridRow> */}

            <div className={userStyles.actions}>
              <GridRow fullWidth withRowGap>
                <GridCol size={7}>
                  <Button
                    label={firstButtonText}
                    size={ButtonSizes.fill}
                    type={ButtonTypes.submit}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </GridCol>

                <GridCol size={5}>
                  <Button
                    label={t(Messages.buttonCancel)}
                    size={ButtonSizes.fill}
                    style={ButtonStyles.white}
                    onClick={onButtonClick}
                  />
                </GridCol>
              </GridRow>
            </div>
            <FormError formError={status} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;
