import { Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import FileInput from '@Components/form/inputs/FileInput/FileInput';
import FormError from '@Components/FormError';
import NavigationBlock, { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { LocalStorage, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseNode, DocumentDocumentType, UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { uploadDocuments } from '@Store/dc/dc.actions';
import { manipulateTranslations } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { deleteConfirmationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import styles from './CertificateForm.scss';

export interface CertificateFormProps extends NavigationProps {
  containerClasses?: string;
}

export interface CertificateFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

interface CertificateFormPrivateProps extends CertificateFormProps, CertificateFormStateProps {}

enum CertificateFormFields {
  // deathRegisteredDate = 'deathRegisteredDate',
  hasCertificate = 'hasCertificate',
}

const CertificateForm: React.FunctionComponent<CertificateFormPrivateProps> = ({
  data,
  back,
  next,
  setSubpage,
  // containerClasses,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(getUser);

  const isIndividualUser = user?.accountType === UserAccountType.Individual;
  // const currentLanguage = useSelector(getCurrentLanguage);

  const setNextStep = () => (next ? setSubpage(next) : {});

  const id = data.item?.id;
  if (!id) {
    return null;
  }

  setToLocalStorage(LocalStorage.deathCaseId, id);

  const removeCertificateHandler = deleteConfirmationHandler(t, dispatch, DocumentDocumentType.Certificate, false);
  // const deathRegisteredDate = data.item?.deathRegisteredDate ? data.item?.deathRegisteredDate : '';
  const hasCertificateFiles = !!data?.item?.certificates?.length;

  const matchCreateTag = location.pathname.split('/').includes('tag=radc');

  const getSaveButton = (isDisabled: boolean) => (
    <Button type={ButtonTypes.submit} size={ButtonSizes.fill} disabled={isDisabled} label="Save" isFullWidth />
  );

  return (
    <>
      {!isIndividualUser && (
        <CautionBox
          containerClassName={styles.cautionBox}
          info={manipulateTranslations(
            t,
            'label_caution_death_certificate',
            'If you don’t have these documents right now, you can skip this section and come back to it later, but you will not be able to send notifications until this section is complete'
          )}
          title={manipulateTranslations(t, 'label_caution', 'Caution')}
        />
      )}

      <Typography
        tag="div"
        msg={t('subtitle_death_certificate_form' as Messages)}
        size="l"
        className={styles.topSpacing}
      />

      {!isIndividualUser && (
        <Typography
          tag="div"
          msg={t('death_certificate_help_text' as Messages)}
          size="m"
          color="footerColor"
          className={styles.topSpacing}
        />
      )}
      <Formik
        initialValues={{
          [CertificateFormFields.hasCertificate]: hasCertificateFiles,
        }}
        validationSchema={() => {
          return Yup.lazy(() => {
            return Yup.object({
              // [CertificateFormFields.deathRegisteredDate]: hasCertificate ? validateDate(t) : validateAny(),
            });
          });
        }}
        onSubmit={() => {
          if (isIndividualUser) {
            dispatch(
              showModal({
                type: ModalTypes.deathRegisterSuccessFormModal,
                params: {
                  data: {
                    caseId: id,
                    title: t(Messages.titleDeathCaseCreatedSuccess),
                    bodyMsg: t(Messages.infoDeathCaseCreated),
                    //  question: t(Messages.labelQuestionSendNotifications),
                    firstButtonMsg: t(Messages.buttonSendDeathNotifications),
                    makeCenter: true,
                    lifeCaseData: data.item,
                  },
                  title: '',
                  modalSize: PaperWidths.widthSuccess,
                  showCloseIcon: false,
                  disableOutsideClick: true,
                },
              })
            );
          } else {
            setNextStep();
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div>
              <>
                <div className={styles.topSpacing15}>
                  <FileInput
                    onDrop={(files: File[]) => {
                      dispatch(
                        uploadDocuments.started({
                          baseCaseId: id,
                          documentType: DocumentDocumentType.Certificate,
                          files,
                        })
                      );
                    }}
                    onRemoveClick={removeCertificateHandler}
                    files={data.item?.certificates || []}
                    disabled={data.item ? data.item.certificates.length > 0 : false}
                  />
                </div>
              </>
              {!hasCertificateFiles && !matchCreateTag && <FormError formError="label_compulsory_field" />}
            </div>
            <NavigationBlock
              isDisabled={!hasCertificateFiles}
              isSubmitting={isSubmitting}
              back={back}
              next={next}
              hideSkip={isIndividualUser}
              setSubpage={setSubpage}
              SaveButton={isIndividualUser ? getSaveButton(!hasCertificateFiles) : undefined}
              nextButtonTooltipMsg={
                values.hasCertificate && !data.item?.certificates.length ? t(Messages.tooltipMissingDocument) : ''
              }
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CertificateForm;
