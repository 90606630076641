import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import * as React from 'react';
import { useSelector } from 'react-redux';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { LocalStorage } from '@Config/constants';
import {
  CaseServiceProviderStatus,
  ServiceProviderPortalUserNode,
  useCaseServiceProviderPortalQuery,
  useCaseServiceProvidersQuery,
  useServiceProviderPortalStatisticsLazyQuery,
  useServiceProviderPortalUsersQuery,
} from '@Graphql/graphqlTypes.generated';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { DateFormat, formatDateForExchange, languageBasedDate, stringToDate } from '@Utils/dates';
import { capitalize } from '@Utils/helpers';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';

import styles from './CaseSummary.scss';
import SpAssignee from './SpAssignee';

export interface NotificationStatusCounts {
  Sent: number;
  InProgress: number;
  Completed: number;
}

export interface CaseSummaryContainerProps {
  status: CaseServiceProviderStatus[];
  setLatestCount?: (counts: NotificationStatusCounts) => void;
  isStaff?: boolean;
  baseCaseId?: string;
  caseServiceProviderId?: string;
  spId?: string;
}

export interface AssigneeSelectionOptions {
  staffAssigneeId: string;
  notificationRef: string;
}

const CaseSummaryContainer: React.FunctionComponent<CaseSummaryContainerProps> = ({
  status,
  setLatestCount,
  isStaff,
  baseCaseId,
  caseServiceProviderId,
  spId,
}) => {
  const [globalFilterValue, setGlobalFilterValue] = React.useState('');
  const currentLanguage = useSelector(getCurrentLanguage);
  const [sortField, setSortField] = React.useState('sentAt');
  const [sortOrder, setSortOrder] = React.useState<1 | -1 | null | undefined>(1);
  const [notificationStaff, setNotificationStaff] = React.useState<AssigneeSelectionOptions[]>([]);
  const [filterValues, setFilterValues] = React.useState(() => {
    const storedFilteredValues = getFromLocalStorage(LocalStorage.spFilteredValues);
    if (storedFilteredValues) return JSON.parse(storedFilteredValues);
    return {
      deceasedNameType: [] as string[],
      accountIdentifierPrimaryType: [] as string[],
      filterSentAt: [] as any[],
      sentAtType: '',
      filterDueBy: [] as any[],
      dueByType: '',
      filterDateOfDeath: [] as any[],
      dateOfDeathType: '',
      status: [] as string[],
      assignee: [] as string[],
    };
  });

  const [filters, setFilters] = React.useState(() => {
    const storedFilteredValues = getFromLocalStorage(LocalStorage.spFilters);
    if (storedFilteredValues) return JSON.parse(storedFilteredValues);
    return {
      deceasedName: { value: null, matchMode: FilterMatchMode.IN },
      accountIdentifierPrimary: { value: null, matchMode: FilterMatchMode.IN },
      status: { value: null, matchMode: FilterMatchMode.IN },
      staffAssigned: { value: null, matchMode: FilterMatchMode.IN },
      filterSentAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterDateOfDeath: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      filterDueBy: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    };
  });

  const dataForSelect = (queryData: any) =>
    queryData?.map((item: ServiceProviderPortalUserNode) => {
      return {
        value: item.info.id,
        label: `${item.info.firstName} ${item.info.lastName}`,
      };
    });

  const usersData = useServiceProviderPortalUsersQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    skip: isStaff,
  });

  const [getProviderStatistics, statisticValues] = useServiceProviderPortalStatisticsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (statisticValues.data && setLatestCount) {
        const shortCounts = statisticValues.data.serviceProviderPortalStatistics;
        const statisticCounts: NotificationStatusCounts = {
          Sent: shortCounts.SENT,
          InProgress: shortCounts.INProgress,
          Completed: shortCounts.COMPLETED + shortCounts.NOTFound,
        };
        setLatestCount(statisticCounts);
      }
    },
  });

  const caseData = useCaseServiceProviderPortalQuery({
    variables: {
      status,
      serviceProvider: spId,
    },
    fetchPolicy: 'network-only',
    skip: isStaff,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (!isStaff) {
        getProviderStatistics({
          variables: {
            serviceProvider: spId,
          },
        });
      }
    },
  });

  const caseServiceData = useCaseServiceProvidersQuery({
    variables: {
      baseCase: baseCaseId,
      //status,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !isStaff,
  });

  React.useEffect(() => {
    setToLocalStorage(LocalStorage.spFilteredValues, JSON.stringify(filterValues));
    setToLocalStorage(LocalStorage.spFilters, JSON.stringify(filters));
  }, [filterValues, filters]);

  const onSort = (event: any) => {
    setSortOrder(event.sortField !== sortField ? -1 : event.sortOrder);
    setSortField(event.sortField);
  };

  const usersList = dataForSelect(usersData.data?.serviceProviderPortalUsers);

  if (caseData.loading || caseServiceData.loading) {
    return <LoadingSpinner />;
  }

  const getItems = () => {
    const modes = !isStaff
      ? caseData.data?.caseServiceProviderPortal.edges
          ?.map((edge: any) => edge.node)
          .sort((a: any, b: any) => {
            const aDate = new Date(a.sentAt);
            const bDate = new Date(b.sentAt);
            if (aDate > bDate) return 1;
            if (aDate < bDate) return -1;
            return 0;
          })
      : caseServiceData.data?.caseServiceProviders.edges
          ?.map((edge: any) => edge.node)
          .filter((x: any) => x.id !== caseServiceProviderId);

    const nodes =
      modes &&
      modes.map((rec: any) => {
        return {
          id: rec.id,
          deceasedName: `${capitalize(rec.deceasedFirstname)} ${capitalize(rec.deceasedSurname)}`,
          accountIdentifierPrimary: rec.accountIdentifierPrimary,
          filterSentAt: rec.sentAt ? stringToDate(formatDateForExchange(rec.sentAt)) : null,
          sentAt: rec.sentAt ? rec.sentAt : '',
          filterDateOfDeath: rec.dateOfDeath ? stringToDate(formatDateForExchange(rec.dateOfDeath)) : null,
          dateOfDeath: rec.dateOfDeath ? rec.dateOfDeath : '',
          filterDueBy: rec.dueBy ? stringToDate(formatDateForExchange(rec.dueBy)) : null,
          dueBy: rec.dueBy ? rec.dueBy : '',
          accessCode: rec.accessCode,
          staffAssigned: rec.serviceProviderUser ? rec : 'Unassigned',
          status: rec.status ? capitalize(rec.status) : '',
          ref: rec.ref ? `NO${rec.ref}` : '-',
          assignee: rec.assignee,
          email: rec.notifier ? rec.notifier.email : '',
          notifierName: rec.notifier ? `${rec.notifier.firstName} ${rec.notifier.lastName}` : '',
        };
      });
    return nodes;
  };

  const items = getItems();

  const filteredData = (): any[] => {
    const searchedData = items?.filter((rowData: any) => {
      const spUserName = rowData.staffAssigned.serviceProviderUser
        ? `${rowData.staffAssigned.serviceProviderUser.firstName} ${rowData.staffAssigned.serviceProviderUser.lastName}`
        : '';
      return (
        (rowData.deceasedName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.accountIdentifierPrimary.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.sentAt.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.email.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.notifierName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          rowData.dateOfDeath.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
          spUserName.toLowerCase().includes(globalFilterValue.toLowerCase())) &&
        (filterValues.deceasedNameType.length === 0 || filterValues.deceasedNameType.includes(rowData.deceasedName)) &&
        (filterValues.accountIdentifierPrimaryType.length === 0 ||
          filterValues.accountIdentifierPrimaryType.includes(rowData.accountIdentifierPrimary)) &&
        (filterValues.assignee.length === 0 ||
          filterValues.assignee.includes(
            `${rowData.staffAssigned.serviceProviderUser.firstName} ${rowData.staffAssigned.serviceProviderUser.lastName}`
          )) &&
        checkDateType(rowData.filterSentAt, filterValues.filterSentAt[0], filterValues.sentAtType) &&
        checkDateType(rowData.filterDateOfDeath, filterValues.filterDateOfDeath[0], filterValues.dateOfDeathType)
      );
    });
    const newData = [...new Map(searchedData?.map((item: any) => [item.ref, item])).values()];
    return newData;
  };

  const checkDateType = (date: any, filter: any, dateType: any) => {
    if (!filter || filter.length === 0) return true;
    if (!date) {
      return false;
    }
    const cellDate = new Date(date);
    const filterDate = new Date(filter);
    switch (dateType) {
      case 'dateIs':
        return cellDate.toDateString() === filterDate.toDateString();
      case 'dateIsNot':
        return cellDate.toDateString() !== filterDate.toDateString();
      case 'dateBefore':
        return cellDate < filterDate;
      case 'dateAfter':
        return cellDate > filterDate;
      default:
        return true;
    }
  };

  const deceasedNameOptions = Array.from(new Set(items?.map((rowData: any) => rowData.deceasedName)));

  const uniqueIdentifierOptions = Array.from(new Set(items?.map((rowData: any) => rowData.accountIdentifierPrimary)));

  // const statusOptions = Array.from(new Set(items?.map((rowData: any) => rowData.status)));

  const userListOptions = Array.from(new Set(usersList?.map((rowData: any) => rowData.label)));
  const onGlobalFilterChange = (e: any) => {
    const { value } = e.target;
    setGlobalFilterValue(value);
  };

  const deceasedNameSortedOptions = deceasedNameOptions.sort((dc1: any, dc2: any) => dc1.localeCompare(dc2));
  const uniqueIdebtifierSortedOptions = uniqueIdentifierOptions.sort((dc1: any, dc2: any) => dc1.localeCompare(dc2));
  // const statusSortedOptions = statusOptions.sort((dc1: any, dc2: any) => dc1.localeCompare(dc2));
  const assigneeSortedOption = userListOptions.sort((dc1: any, dc2: any) => dc1.localeCompare(dc2));

  const renderDeceasedNameMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filterValues.deceasedNameType}
        options={deceasedNameSortedOptions}
        onChange={(e) => {
          setFilterValues({ ...filterValues, deceasedNameType: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Deceased's Name"
        style={{ maxWidth: '14rem' }}
      />
    );
  };

  const renderUniqueIdentifierMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filterValues.accountIdentifierPrimaryType}
        options={uniqueIdebtifierSortedOptions}
        onChange={(e) => {
          setFilterValues({ ...filterValues, accountIdentifierPrimaryType: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Unique Identifier"
        style={{ maxWidth: '14rem' }}
      />
    );
  };

  // const renderStatusMultiSelect = (options: any) => {
  //   return (
  //     <MultiSelect
  //       value={filterValues.status}
  //       options={statusSortedOptions}
  //       onChange={(e) => {
  //         setFilterValues({ ...filterValues, status: e.value });
  //         options.filterCallback(e.value);
  //       }}
  //       placeholder="Select status"
  //       style={{ maxWidth: '14rem' }}
  //     />
  //   );
  // };

  const renderSenAtCalender = (options: any) => {
    return (
      <Calendar
        value={stringToDate(filterValues.filterSentAt[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterSentAt.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilterValues({ ...filterValues, filterSentAt: [e.value], sentAtType: options.filterModel.matchMode });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const renderDateOfDeathCalender = (options: any) => {
    return (
      <Calendar
        value={DateFormat(filterValues.filterDateOfDeath[0])}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
          const value = options.filterModel.matchMode;
          const _filters = { ...filters };
          _filters.filterDateOfDeath.constraints[0].matchMode = value;
          setFilters(_filters);
          setFilterValues({
            ...filterValues,
            filterDateOfDeath: [e.value],
            dateOfDeathType: options.filterModel.matchMode,
          });
        }}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const renderAssigneeMultiSelect = (options: any) => {
    return (
      <MultiSelect
        value={filterValues.assignee}
        options={assigneeSortedOption}
        onChange={(e) => {
          setFilterValues({ ...filterValues, assignee: e.value });
          options.filterCallback(e.value);
        }}
        placeholder="Select Assignee"
      />
    );
  };

  const assignStaffUserToNotification = (notificationId: string, staffId: string) => {
    const index = notificationStaff.findIndex(
      (option: AssigneeSelectionOptions) => option.notificationRef === notificationId
    );

    if (index !== -1) {
      // If the notificationRef already exists, update the staffAssigneeId
      setNotificationStaff((prevOptions: AssigneeSelectionOptions[]) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[index].staffAssigneeId = staffId;
        return updatedOptions;
      });
    } else {
      // If the notificationRef doesn't exist, add a new object to the array
      setNotificationStaff((prevOptions) => [
        ...prevOptions,
        { notificationRef: notificationId, staffAssigneeId: staffId },
      ]);
    }
  };

  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <Row justifyEnd constant size={12} className={styles.mb18}>
          <Col constant size={2}>
            <input
              type="text"
              onChange={onGlobalFilterChange}
              className={styles.input}
              autoComplete="off"
              placeholder="Search"
              value={globalFilterValue}
              // disabled={cursorId !== ''}
            />
            <Icon icon="search" className={styles.visibilityToggleIcon} size={IconSizes.s} />
          </Col>
        </Row>

        <DataTable
          value={filteredData()}
          id="notificationsTable"
          stripedRows
          paginator
          // scrollHeight="490px"
          emptyMessage="No records found"
          filters={filters}
          // onFilter={handleFilter}
          // removableSort
          // sortMode="multiple"
          // multiSortMeta={[{ field: 'dueBy', order: -1 }]}
          sortField={sortField}
          sortOrder={sortOrder}
          scrollable
          onSort={(e: any) => onSort(e)}
          rows={10}
          className={styles.fonts}
          // filterDisplay={cursorId === '' ? 'menu' : undefined}
          rowsPerPageOptions={[10, 25, 50, 100]}
          resizableColumns
          globalFilterFields={[
            'deceasedName',
            'accountIdentifierPrimary',
            'status',
            'sentAt',
            'filterSentAt',
            'filterDateOfDeath',
            'filterDueBy',
            'dateOfDeath',
            'dueBy',
            'staffAssigned',
            'assignee',
          ]}
        >
          <Column
            field="deceasedName"
            header="Deceased's name"
            sortable
            filter
            filterField="deceasedName"
            filterMenuStyle={{ width: '14rem' }}
            showFilterMenu={true}
            showFilterMatchModes={false}
            showClearButton={true}
            showApplyButton={false}
            filterElement={renderDeceasedNameMultiSelect}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, deceasedName: [] });
            }}
          />
          <Column
            field="accountIdentifierPrimary"
            header="Account or policy number"
            sortable
            filter
            filterField="accountIdentifierPrimary"
            filterMenuStyle={{ width: '14rem' }}
            showFilterMenu={true}
            showFilterMatchModes={false}
            showClearButton={true}
            showApplyButton={false}
            filterElement={renderUniqueIdentifierMultiSelect}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, accountIdentifierPrimaryType: [] });
            }}
          />
          {/* <Column
            field="status"
            header="Status"
            sortable
            filter
            filterField="status"
            filterMenuStyle={{ width: '14rem' }}
            showFilterMenu={true}
            showFilterMatchModes={false}
            showClearButton={true}
            showApplyButton={false}
            filterElement={renderStatusMultiSelect}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, status: [] });
            }}
          /> */}

          <Column
            field="sentAt"
            sortable
            header="Sent at"
            filterField="filterSentAt"
            dataType="date"
            filter
            filterElement={renderSenAtCalender}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, filterSentAt: [] });
            }}
            showFilterMenu={true}
            showClearButton={true}
            showApplyButton={false}
            body={(rowData: any) => {
              return rowData.sentAt ? languageBasedDate(rowData.sentAt, currentLanguage) : '-';
            }}
          />

          <Column
            field="dateOfDeath"
            sortable
            header="Date of death"
            filterField="filterDateOfDeath"
            dataType="date"
            filter
            filterElement={renderDateOfDeathCalender}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, filterDateOfDeath: [] });
            }}
            showFilterMenu={true}
            showClearButton={true}
            showApplyButton={false}
            body={(rowData: any) => {
              return rowData.dateOfDeath ? languageBasedDate(rowData.dateOfDeath, currentLanguage) : '-';
            }}
          />
          <Column
            header="Assignee"
            field="staffAssigned"
            body={(rowData: any) => {
              return (
                <>
                  <SpAssignee
                    rec={rowData}
                    usersList={usersList}
                    staffAssigneeCallback={assignStaffUserToNotification}
                    assignedList={notificationStaff}
                  />
                </>
              );
            }}
            style={{ minWidth: '15rem' }}
            filter
            filterField="staffAssigned"
            showFilterMenu={true}
            showFilterMatchModes={false}
            showClearButton={true}
            showApplyButton={false}
            filterElement={renderAssigneeMultiSelect}
            onFilterClear={() => {
              setFilterValues({ ...filterValues, assignee: [] });
            }}
          />

          <Column
            field="accessCode"
            header=""
            body={(rowData: any) => {
              return (
                <a
                  className={styles.linkStyle}
                  href={`${window.location.origin}/notification/${rowData.accessCode}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View Notification
                </a>
              );
            }}
          />
        </DataTable>
      </div>
    </>
  );
};

export default CaseSummaryContainer;
