import { CaseServiceProviderStatus, DeathCaseStatus, LifeCaseStatus } from '@Graphql/graphqlTypes.generated';
import { getAttributeValue } from '@Utils/helpers';

export enum Forms {
  loginForm = 'login-form',
}

export enum Cookies {
  crfToken = 'csrftoken',
  defaultLang = 'lang',
}

export enum LocalStorage {
  userToken = 'userToken',
  deathCaseId = 'deathCaseId',
  lifeCaseId = 'lifeCaseId',
  noDcContent = 'noDcContent',
  noLcContent = 'noLcContent',
  providerEmail = 'providerEmail',
  lifeCaseTag = 'lifeCaseTag',
  deathCaseTag = 'deathCaseTag',
  enablePerformanceCookie = 'enablePerformanceCookie',
  idVerificationType = 'idVerificationType',
  notificationStatus = 'notificationStatus',
  cookieConsent = 'cookieConsent',
  referenceCode = 'referenceCode',
  referenceDeathCase = 'referenceDeathCase',
  isNotificationSummary = 'isNotificationSummary',
  welcomeTourProfessional = 'welcomeTourProfessional',
  uninvitedProfessional = 'uninvitedProfessional',
  professionalActiveCase = 'professionalActiveCase',
  idVerificationFirstFail = 'idVerificationFirstFail',
  caseServiceProviders = 'caseServiceProviders',
  referenceLifeCase = 'referenceLifeCase',
  settingFormChanged = 'settingFormChanged',
  obCompanySelected = 'obCompanySelected',
  obCompanyIds = 'obCompanyIds',
  spNotification = 'spNotification',
  refreshSpDashboard = 'refreshSpDashboard',
  widgetUserNotification = 'widgetUserNotification',
  assignUser = 'assignUser',
  renewModal = 'renewModal',
  caseTypeState = 'caseTypeState',
  filteredValues = 'filteredValues',
  filters = 'filters',
  accessTokenExpiry = 'accessTokenExpiry',
  analyticsCookie = 'analyticsCookie',
  functionalCookie = 'functionalCookie',
  socialLoginType = 'socialLoginType',
  spFilteredValues = 'spFilteredValues',
  spFilters = 'spFilters',
}

export const permanentLocalStorages = ['enablePerformanceCookie', 'cookieConsent', 'welcomeTourProfessional'];

export enum ModalTypes {
  confirmModal = 'confirm-modal',
  providerFormModal = 'provider-form-modal',
  inviteToCollaborateFormModal = 'invite-to-collaborate-form-modal',
  editCollaboratorFormModal = 'edit-collaborator-form-modal',
  fileUploadModal = 'file-upload-modal',
  slugBasedDataModal = 'slug-based-data-modal',
  newProviderFormModal = 'new-provider-form-modal',
  idVerfificationFileUploadModal = 'id-verfification-file-upload-modal',
  userInputFormModal = 'user-input-form-modal',
  addUserModal = 'add-user-modal',
  cookieSettingsFormModal = 'cookie-settings-form-modal',
  registeredDeathCaseConfirmFormModal = 'registered-death-case-confirm-form-modal',
  notificationStatusUpdateFormModal = 'notification-status-update-form-modal',
  deathRegisterSuccessFormModal = 'death-register-success-form-modal',
  paymentFormModal = 'payment-form-modal',
  discoveryDescModal = 'discovery-desc-modal',
  somethingWrongModal = 'something-wrong-modal',
  lifeCaseActionsModal = 'life-case-actions-modal',
  mobileNotificationActionsModal = 'mobile-notification-actions-modal',
  emptyContentModal = 'empty-content-Modal',
  logoPreviewModal = 'logo-preview-modal',
  welcomeGuideModal = 'welcome-guide-modal',
  authorityActionModal = 'authority-action-modal',
  deathCaseWelcomeModal = 'dc-welcome-modal',
  idValidationErrorModal = 'id-validation-error-modal',
  termsAndConditionsModal = 'terms-and-conditions-modal',
  mobileDcCardActionsModal = 'mobile-dc-card-actions-modal',
  paymentModeModal = 'payment-mode-modal',
  discoveryModal = 'discovery-modal',
  loadingModal = 'loading-modal',
  deathCaseNotificationModal = 'death-case-notification-modal',
  saveChangesModal = 'save-changes-modal',
  companyMissingInfoModal = 'company-missing-info-Mmodal',
  obNeverLeaveModal = 'ob-never-leave-modal',
  paymentUpgradeModal = 'payment-upgrade-modal',
  cancelSubscriptionConfirmModal = 'cancel-subscription-confirm-modal',
  genericModal = 'generic-modal',
  basicServicePayment = 'basic-service-payment',
  smartModal = 'smart-modal',
  renewSubscription = 'renew-subscription',
  registerLCModal = 'register-lc-modal',
  newWelcomeModal = 'new-welcome-modal',
  obBanksNoSupport = 'ob-banks-no-support',
  changeAddressModal = 'change-address-modal',
  paymentDetailsModal = 'payment-details-modal',
}

export enum Links {
  home = '/:dest?',
  lifeLedger = 'http://www.lifeledger.com/',
  login = '/login/:collaboratorKey?',
  logout = '/logout',
  register = '/register',
  individualRegister = '/register/:accountType/:collaboratorKey?',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password/:passwordKey',
  verifyEmail = '/verify/:verificationKey',
  invite = '/invite/:collaboratorKey?',
  settings = '/settings/:subpage/:transpage?',
  dcCreate = '/death-case/create/:tag?',
  dcEdit = '/death-case/edit/:id/:subpage?/:missing?/:tag?',
  dcReady = '/death-case/ready/:id/:dest?',
  lcCreate = '/lc/create/:tag?',
  lcEdit = '/lc/edit/:id/:subpage?/:missing?/:tag?',
  lcReady = '/lc/ready/:id/:tab?',
  providerDCInfo = '/provider/dcinfo/:msgAccessCode?',
  userNotification = '/user/dcinfo',
  professionalDashboard = '/professional/dashboard',
  providerDashboard = '/provider/dashboard',
  staffDashboard = '/staff/notifications',
  privacy = 'privacy-policy',
  contactUs = 'contact',
  termsConditions = 'terms-conditions',
  success = '/success',
  payments = '/payments',
  paymentSuccess = '/payments/success',
  paymentError = '/payments/error',
  configureMFA = '/configure/mfa',
  dcRefer = '/death-case/refer',
  dashboard = '/dashboard',
  notificationDCInfo = '/provider/dcinfo',
  notificationDashboard = '/notification/:accessCode',
  serviceProviderReports = '/provider/reports',
  activity = '/activity',
  craCompanies = '/cra-companies/:id/:page?/:tag?',
  beSpokeLogin = '/bespoke-login',
  bankConnectSuccess = '/callback',
  glfp = '/glfp',
  institutions = '/institutions/:id',
  retargetLifeRegistration = '/register-life',
  retargetDeathRegistration = '/register-death',
  bankNotConfigured = '/not-configured/:id',
  automatedReportForm = '/provider/dcreport',
  microsoftLanding = '/ms-landing',
  lifeEvent = '/life-event/create/:type?',
  lifeEventEdit = '/life-event/edit/:subpage?/:missing?',
  lifeEventSelect = '/life-event/:type?',
  lvlogin = '/provider',
  ssoLogin = '/sso/login',
}

export enum ApplicationLanguages {
  EN = 'en',
}

export const Defaults = {
  language: ApplicationLanguages.EN,
  dateFormat: 'YYYY-MM-DD',
};

export enum ApiErrorStatusCodes {
  forbidden = 403,
}

export const Timeouts = {
  resendEmail: 10, // in seconds
  yotiPollingPeriod: 2, // in seconds
  idValidationPolling: 2, // in seconds
};

export enum DataExchangeFormats {
  date = 'YYYY-MM-DD',
  year = 'YYYY',
  UKdate = 'DD-MM-YYYY',
}

export const emptySelectorValue = {
  value: '',
  label: '',
};

export enum DateFormats {
  shortDate = 'shotDate',
  dateWithTime = 'dateWithTime',
  dateWithMonthAsText = 'dateWithMonthAsText',
  dayMonth = 'dayMonth',
}

export type CategoryEntry = {
  [lng in ApplicationLanguages]: string;
};

export type FormatCategories = {
  [cat in DateFormats]: CategoryEntry;
};

export const languageBasedFormats: FormatCategories = {
  [DateFormats.shortDate]: {
    [ApplicationLanguages.EN]: 'DD/MM/YYYY',
  },
  [DateFormats.dateWithTime]: {
    [ApplicationLanguages.EN]: 'DD-MM-YYYY HH:mm',
  },
  [DateFormats.dateWithMonthAsText]: {
    [ApplicationLanguages.EN]: 'D MMMM YYYY',
  },
  [DateFormats.dayMonth]: {
    [ApplicationLanguages.EN]: 'DD/MM',
  },
};

export const validationLimits = {
  postalCode: 10,
};

export const initialBirthDate = new Date(1980, 1, 1);

export const freeServiceProvidersForLC = 3;

export const fileUploadSettings = {
  supportedUploadFormats: ['.pdf', '.jpg', '.jpeg', '.webp', '.png'],
  // 10MB in bytes
  maxUploadSize: 10485760,
  maxFiles: 20,
  imageUploadFormats: ['.jpg', '.jpeg', '.png', '.wep', '.webp'],
};

export type PaginationSettings = {
  firstLoad: number;
  loadMore: number;
};

export const pageSize = {
  lastDeathCases: 2,
  lastLifeCases: 2,
  deathCasesColumn: { firstLoad: 4, loadMore: 10 } as PaginationSettings,
  lifeCasesColumn: { firstLoad: 9, loadMore: 10 } as PaginationSettings,
  archivedColumn: { firstLoad: 6, loadMore: 10 } as PaginationSettings,
  lifeCasesList: { firstLoad: 9, loadMore: 9 } as PaginationSettings,
  collaboratorsTable: { firstLoad: 5, loadMore: 10 } as PaginationSettings,
  allCases: 100,
  tableCases: 10,
};

export enum SnackbarPosition {
  bc = 'bc',
}

export const notificationsConfig = {
  defaultConf: {
    autoDismiss: 7,
    position: SnackbarPosition.bc,
  },
};

export enum NodeDefiner {
  DeathCase = 'DeathCaseNode',
  LifeCase = 'LifeCaseNode',
  DocumentNode = 'DocumentNode',
}

export const PreActiveDCStatuses = [DeathCaseStatus.Draft, DeathCaseStatus.Approved];
export const PreCompletedDCStatuses = [...PreActiveDCStatuses, DeathCaseStatus.Active];
export const PreArchivedDCStatuses = [...PreCompletedDCStatuses, DeathCaseStatus.Completed];
export const AllLCStatuses = [LifeCaseStatus.Draft, LifeCaseStatus.Prepared];

export enum ReactResponsiveQueries {
  Tablet = '(max-width: 1024px)',
  Mobile = '(max-width:576px)',
  DesktopS = '(max-width:1280px)',
  MobileBetweenTablet = '(min-width: 577px) and (max-width: 767px)',
  IpadAir = '(max-width:820px)',
  DesktopM = '(max-width:1348px)',
  TabletBetweenDesktop = '(min-width: 769px) and (max-width: 1148px)',
  MidDesktopBetweenMaxDesktop = '(min-width: 1025px) and (max-width: 1436px)',
  IpadAirBetweenSmallDesktop = '(min-width: 821px) and (max-width: 1016px)',
  UnopenedStatus = '(min-width: 1025px) and (max-width: 1079px)',
  ReplyExepctedBy = '(min-width: 1025px) and (max-width: 1190px)',
  SmallDesktopReply = '(max-width: 1190px)',
  IpadAirBetweenDesktopS = '(min-width: 821px) and (max-width: 1024px)',
  mobileBnAllSix = '(min-width: 577px) and (max-width: 666px)',
  IpadMini = '(max-width: 767px)',
  StartFromIpadMini = '(min-width:768px)',
  TabletMin = '(min-width: 768px)',
  Res1118 = '(max-width:1118px)',
  Res875 = '(max-width:875px)',
  Res768 = '(max-width:768px)',
  Res1323 = '(max-width:1323px)',
  Res935 = '(max-width:935px)',
  Res769 = '(max-width: 769px)',
  Res904 = '(max-width: 904px)',
  Res722 = '(max-width: 722px)',
  Res986 = '(max-width: 986px)',
  Res1400Max = '(max-width: 1400px)',
  Res1400Min = '(min-width: 1400px)',
  Res1387Max = '(max-width: 1387px)',
  Res1293Min = '(min-width: 1293px)',
  Res1210Max = '(max-width: 1210px)',
  Res1306Max = '(max-width: 1306px)',
  ResMin1025AndMax1144 = '(min-width: 1025px) and (max-width: 1144px)',
  Res768MinAnd890Max = '(min-width: 768px) and (max-width: 890px)',
  Res1050 = '(max-width: 1050px)',
}

export const initialStripeElementState = {
  complete: false,
  elementType: '',
  empty: false,
  error: null,
  value: null,
};

export const sendEmail = true;

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum CaseTags {
  lifeCaseCreated = 'ralc',
  lifeCaseCompleted = 'ralf',
  deathCaseCreated = 'radc',
  deathCaseEdit = 'rade',
  deathCaseCompleted = 'radf',
  registeredDeathCase = 'regDeathCase',
  registeredLifeCase = 'regLifeCase',
}

export enum IdVerficationFormFields {
  yoti = 'yoti',
  transUnion = 'transUnion',
}

export const AddressPortal =
  'https://api.getaddress.io/find/postalCode?expand=true&api-key=pYMHYxdgr0GeCDR4t4DR2A33577';

export const placeholderSelectorValue = (placeholder: string) => {
  return { value: '', label: placeholder };
};

export const colorArray = [
  '#B1C0E9',
  '#97C5B9',
  '#C8B4E9',
  '#ED8E79',
  '#F1E895',
  '#BC8F8F',
  '#D3CABE',
  '#EEEB8D',
  '#D8E79D',
  '#A1E9CE',
  '#CC99FF',
];

export enum OnboardingCaseSteps {
  isFirstCase = 'isFirstCase',
  lastStepCompleted = 'lastStepCompleted',
}

export const alphabets = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export enum ObTransactionType {
  undefined = 'undefined',
  defined = 'defined',
}

export const EnterKeyCode = 13;

export enum AppVersion {
  first = '1.0',
  second = '2.0',
}

export const allStatus = [
  CaseServiceProviderStatus.Closed,
  CaseServiceProviderStatus.NotFound,
  CaseServiceProviderStatus.Confirmed,
  CaseServiceProviderStatus.CompletedByLl,
  CaseServiceProviderStatus.CompletedClosed,
  CaseServiceProviderStatus.NoActionRequired,
  CaseServiceProviderStatus.CompletedSwitched,
  CaseServiceProviderStatus.AlreadyRegistered,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
  CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
  CaseServiceProviderStatus.InProgressAutoPopulationFailed,
  CaseServiceProviderStatus.InProgress,
  CaseServiceProviderStatus.Sent,
];

export const addressPortal = () => {
  const envName = getAttributeValue('env');
  if (envName.toLowerCase() === 'local') {
    return `http://localhost:8000/widget/verify-postcode/`;
  }
  if (envName.toLowerCase() === 'dev' || window.location.hostname === 'localhost') {
    return 'https://dev.api.lifeledger.com/widget/verify-postcode/';
  }
  return 'https://api.lifeledger.com/widget/verify-postcode/';
};

export const googleClientId = '327842205185-2j5p9hqcn7qcj68hcaqi020hsu4uv7pi.apps.googleusercontent.com';
