export enum SettingsSubpages {
  Account = 'account',
  IDValidation = 'validation',
  PasswordChange = 'password',
  Billing = 'billing',
  Users = 'users',
  Company = 'company',
  RequiredFields = 'requiredfields',
}

export enum NonAdminSettingsSubpages {
  Account = 'account',
  IDValidation = 'validation',
  PasswordChange = 'password',
}

export enum AccountSettingsSubpages {
  Account = 'account',
  IDValidation = 'validation',
  PasswordChange = 'password',
  // Billing = 'billing',
}

export enum ProfAccountSettingsSubpages {
  Account = 'account',
  IDValidation = 'validation',
  PasswordChange = 'password',
}

export enum ProfCompanySettingsSubpages {
  Users = 'users',
  // Payments = 'payments',
  Company = 'company',
}

export enum ServiceProviderSettingsSubpages {
  Account = 'account',
  PasswordChange = 'password',
}

export enum ServiceProviderCompanySettingsSubpages {
  Users = 'users',
  //  Payments = 'payments',
  Company = 'company',
  RequiredFields = 'requiredfields',
}
