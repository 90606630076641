import { classNames } from 'primereact/utils';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MiniFooter from '@Components/application/MiniFooter';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs, { TabItem } from '@Components/Tabs';
import Typography from '@Components/Typography';
import { LocalStorage } from '@Config/constants';
import {
  ServiceProviderOrganizationType,
  UserAccountType,
  useServiceProviderCompaniesQuery,
  useServiceProviderQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import DeathCasesForm from '@Routes/provider/DeathCasesPage/DeathCasesForm/DeathCasesForm';
import { getUser } from '@Store/auth/auth.selector';
import { deleteFromLocalStorage, getFromLocalStorage } from '@Utils/localStorage';
import { confirmRegisteredDeathCaseHandler } from '@Utils/modal';

import { NotificationStatusCounts } from '../DeathCasesPage/DeathCasesForm/CaseSummary/CaseSummaryContainer';

import ProviderDashboardNavbar from './ProviderDashboardNavbar';
import styles from './ProviderDashboardPage.scss';

export interface ProviderDashboardPageProps {}

export enum NotificationStatusTabs {
  TabUnassigned,
  TabInProgress,
  TabCompleted,
}

const ProviderDashboardPage: React.FunctionComponent<ProviderDashboardPageProps> = () => {
  deleteFromLocalStorage(LocalStorage.deathCaseId);

  const t = useTranslations();
  const user = useSelector(getUser);
  // const wrapperRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [newTab, setNewTab] = React.useState(0);
  const [unassignedCount, setUnassignedCount] = React.useState(0);
  const [inProgressCount, setInProgressCount] = React.useState(0);
  const [completedCount, setCompletedCount] = React.useState(0);
  const [kpiTab, setKpiTab] = React.useState(0);

  const serviceProviderId = user?.serviceProvider || '';

  const spGroups = useServiceProviderCompaniesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const providerInfo = useServiceProviderQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
    skip: user?.accountType !== UserAccountType.ServiceProvider,
    variables: {
      id: serviceProviderId,
    },
  });

  React.useEffect(() => {
    if (user?.accountType === UserAccountType.Professional && user.hasRefDeathCase) {
      confirmRegisteredDeathCaseHandler(t, dispatch);
    }
    const handleRefresh = () => {
      if (getFromLocalStorage(LocalStorage.refreshSpDashboard)) {
        deleteFromLocalStorage(LocalStorage.refreshSpDashboard);
        window.location.reload();
      }
    };

    const windowEvents = () => {
      window.addEventListener('storage', handleRefresh);
      return () => {
        window.removeEventListener('storage', handleRefresh);
      };
    };

    windowEvents();
  }, [user, t, dispatch]);

  if (spGroups.loading) {
    return <LoadingSpinner />;
  }

  const nodeList = spGroups.data?.serviceProviderCompanies.map((company: any) => {
    let edges = company.serviceProvider.edges.map((edge: any) => {
      const { name, id, organizationType } = edge.node;
      return { name, id, organizationType };
    });

    const holdingCompany = company.serviceProviderCompany;
    delete holdingCompany.__typename;
    edges = edges.concat([holdingCompany]);
    return edges;
  });

  const sisterCompanies = nodeList?.reduce((acc: any, val: any) => acc.concat(val), []);

  const sortedSisterCompanies = sisterCompanies.sort((a: any, b: any) => {
    if (a.organizationType === 'HOLDING_COMPANY' && b.organizationType === 'COMPANY') {
      return -1; // a should come before b
    }
    if (a.organizationType === 'COMPANY' && b.organizationType === 'HOLDING_COMPANY') {
      return 1; // b should come before a
    }
    return 0; // no change in order
  });

  const filteredSisterCompanies =
    providerInfo.data?.serviceProvider.organizationType === ServiceProviderOrganizationType.Company
      ? sortedSisterCompanies.filter((sp: any) => sp.id === providerInfo.data?.serviceProvider.id)
      : sortedSisterCompanies;

  const companyTabs = filteredSisterCompanies.map((company: any) => {
    return {
      title: company.name,
    } as TabItem;
  });

  const getSPId = () => {
    return filteredSisterCompanies.length > 0 ? filteredSisterCompanies[newTab].id : serviceProviderId;
  };

  const resetCount = (counts: NotificationStatusCounts) => {
    setUnassignedCount(counts.Sent);
    setInProgressCount(counts.InProgress);
    setCompletedCount(counts.Completed);
  };

  return (
    <Col className={styles.overallContainer} size={12}>
      <ProviderDashboardNavbar />
      {/* <Typography msg={t(Messages.labelYourNotifications)} tag="h3" size="xxl" />
        <Row size={isTablet ? 8 : 5}>
          <Typography msg={t(Messages.subtitleYourNotifications)} tag="div" size="l" color="footerColor" />
        </Row> */}
      <div className={classNames(styles.cardBackground, styles.mt34)}>
        <Tabs tabs={companyTabs} activeTab={newTab} setActiveTab={setNewTab} />
        <Row className={classNames(styles.mt34, styles.gap4, styles.mb19)}>
          <Col
            onClick={() => setKpiTab(NotificationStatusTabs.TabUnassigned)}
            className={classNames(styles.kpiContainer, {
              [styles.selectedBox]: kpiTab === NotificationStatusTabs.TabUnassigned,
            })}
          >
            <Typography textCenter size="l" tag="h5" msg="Unassigned" bold />
            <Typography tag="div" textCenter size="m" msg={unassignedCount.toString()} />
          </Col>
          <Col
            onClick={() => setKpiTab(NotificationStatusTabs.TabInProgress)}
            className={classNames(styles.kpiContainer, {
              [styles.selectedBox]: kpiTab === NotificationStatusTabs.TabInProgress,
            })}
          >
            <Typography textCenter size="l" tag="h5" msg="In progress" bold />
            <Typography tag="div" textCenter size="m" msg={inProgressCount.toString()} />
          </Col>
          <Col
            onClick={() => setKpiTab(NotificationStatusTabs.TabCompleted)}
            className={classNames(styles.kpiContainer, {
              [styles.selectedBox]: kpiTab === NotificationStatusTabs.TabCompleted,
            })}
          >
            <Typography textCenter size="l" tag="h5" msg="Completed" bold />
            <Typography tag="div" textCenter size="m" msg={completedCount.toString()} />
          </Col>
        </Row>
        <DeathCasesForm setLatestCount={resetCount} tabSelected={kpiTab} spId={getSPId()} />
      </div>
      <MiniFooter centerAlign noMaxWidth />
    </Col>
  );
};

export default ProviderDashboardPage;
