import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@Components/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Typography from '@Components/Typography';
import { EnterKeyCode } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  UpgradeStripeSubscriptionMutation,
  UpgradeStripeSubscriptionMutationVariables,
  usePackagesQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpgradeStripeSubscription } from '@Graphql/lc/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DiscoveryInfo from '@Routes/lifeCase/ReadyLCPage/DiscoveryInfo';
import { normaliseGqlError } from '@Utils/form';
import { paymentModeHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import { ModalProviderProps } from '../Modal';

import styles from './PaymentUpgradeModalBody.scss';

export interface PaymentUpgradeModalBodyProps {
  title: string;
  bodyMsg: string;
  baseCase: string;
  onClose?: () => void;
}

const PaymentUpgradeModalBody: React.FunctionComponent<ModalProviderProps<PaymentUpgradeModalBodyProps>> = ({
  closeModal,
  modalData: { title, baseCase },
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const [showDiscoveryWorks, setShowDiscoveryWorks] = React.useState(false);

  const packagesData = usePackagesQuery({
    variables: {
      productType: 'LIFE_CASE',
    },
    fetchPolicy: 'network-only',
  });

  const basicPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'BASIC_LIFE_CASE');
  const discoveryPackage = packagesData.data?.packages.find((p: any) => p.features[0] === 'DISCOVERY');
  const upgradePrice = (discoveryPackage?.unitAmount ?? 0) - (basicPackage?.unitAmount ?? 0);

  const [upgradeStripeSubscription, { loading }] = useMutation<
    UpgradeStripeSubscriptionMutation,
    UpgradeStripeSubscriptionMutationVariables
  >(mutationUpgradeStripeSubscription, {
    onCompleted: () => {
      dispatch(notificationSuccess(t('msg_subscription_upgraded' as Messages)));
      closeModal();
      window.location.replace(window.location.pathname);
    },
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  if (packagesData.loading) {
    return <LoadingSpinner />;
  }

  const basicPriceId =
    window.location.hostname === 'app.lifeledger.com'
      ? 'price_1M2qGBLnDfwm8yU9MyZrPYLM'
      : 'price_1M2Cu6LnDfwm8yU9029zrPTq';

  // const upText = ', by upgrading we will';

  return !showDiscoveryWorks ? (
    <>
      <div className={styles.paddingBody}>
        <Typography msg={title} tag="h4" bold size="xl" />

        <div className={classNames(styles.bottomSpacing, styles.lh164)}>
          <Typography msg={t('payment_upgrade_subtitle' as Messages)} size="l" tag="div" />
        </div>

        <div className={styles.flexBox}>
          <div className={styles.packageCard}>
            <Typography textCenter size="l" tag="h5" msg={t('title_basic_life_planning_package' as Messages)} bold />

            {basicPackage?.currencySymbol && (
              <Row justifyCenter constant className={styles.mb16}>
                <Typography className={styles.fromLabel} msg="From" tag="div" size="l" />
                &nbsp;
                <Typography
                  bold
                  msg={`${basicPackage?.currencySymbol}${basicPackage?.unitAmount}`}
                  tag="div"
                  size="xxxl"
                />
                <Typography
                  msg={`/${basicPackage.recurringInterval.toLowerCase()}`}
                  tag="div"
                  size="l"
                  className={styles.tenure}
                />
              </Row>
            )}
            <Row className={styles.liGap}>
              <Row constant alignCenter className={classNames(styles.listItemGap, styles.lineHeight)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
                <Typography
                  className={classNames(styles.f16, styles.footerColor)}
                  msg={t('label_unlimited_companies' as Messages)}
                  tag="div"
                />
              </Row>

              <Row constant alignCenter className={classNames(styles.listItemGap, styles.lineHeight, styles.h80)}>
                <Icon className={styles.unavailable} icon="close" size={IconSizes.tick1} />
                <Typography
                  className={styles.f16}
                  msg={t('msg_credit_reference' as Messages)}
                  tag="div"
                  color="dividerColor"
                />
              </Row>

              <Row constant alignCenter className={classNames(styles.listItemGap, styles.lineHeight, styles.h70)}>
                <Icon className={styles.unavailable} icon="close" size={IconSizes.tick1} />
                <Typography
                  className={styles.f16}
                  msg={t('label_bank_not_link' as Messages)}
                  tag="div"
                  color="dividerColor"
                />
              </Row>
            </Row>
            <Row constant justifyCenter alignCenter className={classNames(styles.listItemGap, styles.mtAuto)}>
              <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
              <Typography className={styles.f16} msg={t('msg_current_plan' as Messages)} tag="div" color="green" />
            </Row>
          </div>
          <div className={classNames(styles.packageCard, styles.recommendedCard)}>
            <div className={styles.recommendedStrip}>
              <Typography color="white" bold size="xs" tag="div" msg={t('label_recommended' as Messages)} />
            </div>
            <Row constant className={styles.iconTile} alignCenter>
              <Icon className={styles.starIcon} size={IconSizes.s24} icon="star" />
              <Typography size="l" tag="h5" msg={t('title_ll_discovery_package' as Messages)} />
            </Row>

            {discoveryPackage?.currencySymbol && (
              <Row constant justifyCenter className={styles.mb16}>
                <Typography className={styles.fromLabel} msg="From" tag="div" size="l" />
                &nbsp;
                <Typography
                  bold
                  msg={`${discoveryPackage?.currencySymbol}${discoveryPackage?.unitAmount}`}
                  tag="div"
                  size="xxxl"
                />
                <Typography
                  className={styles.tenure}
                  msg={`/${discoveryPackage.recurringInterval.toLowerCase()}`}
                  tag="div"
                  size="l"
                />
              </Row>
            )}
            <Row className={styles.liGap}>
              <Row constant alignCenter className={styles.listItemGap}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.ss} />
                <Typography
                  className={styles.footerColor}
                  msg={t('label_unlimited_companies' as Messages)}
                  tag="div"
                  size="l"
                />
              </Row>

              <Row constant alignCenter className={classNames(styles.listItemGap, styles.h80)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.ss} />
                <div className={classNames(styles.f18, styles.footerColor, styles.lineHeight)} />
                <span className={classNames(styles.f18, styles.footerColor, styles.lineHeight)}>
                  Run a &nbsp;
                  <b>credit reference agency check</b>
                  &nbsp;to identify all credit-associated accounts
                </span>
              </Row>

              <Row constant alignCenter className={classNames(styles.listItemGap, styles.h70)}>
                <Icon className={styles.ticks} icon="tick" size={IconSizes.ss} />
                <span className={classNames(styles.f18, styles.footerColor, styles.lineHeight)}>
                  <b>Link bank account</b>
                  &nbsp; to automatically sync all companies
                </span>
              </Row>
            </Row>
            <Typography
              textCenter
              msg={t('msg_dicovery_package' as Messages)}
              color="electricBlue"
              tag="div"
              size="l"
              underline
              tabIndex={0}
              className={classNames(styles.handSymbol, styles.mtAuto)}
              // onKeyDown={(e: any) => {
              //   if (e.keyCode === EnterKeyCode) {
              //     closeModal()
              //   }
              // }}
              // onClick={closeModal}
            />
          </div>
        </div>

        <Button
          onClick={() => {
            upgradeStripeSubscription({
              variables: {
                input: {
                  toPackage: discoveryPackage?.id || '',
                  baseCase,
                },
              },
            });
          }}
          label={`Upgrade now from £${upgradePrice} per year`}
          isFullWidth
          className={styles.mt31}
          loading={loading}
        />
        <Row className={classNames(styles.listItemGap, styles.gap10)} alignCenter constant>
          <Icon className={styles.ticks} icon="tick" size={IconSizes.tick1} />
          <Typography msg={t('payment_upgrade_description' as Messages)} tag="div" size="m" color="footerColor" />
        </Row>

        <Typography
          textCenter
          msg={t('no_thanks_payment_upgrade' as Messages)}
          color="electricBlue"
          tag="div"
          size="l"
          underline
          tabIndex={0}
          className={styles.handSymbol}
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              closeModal();
            }
          }}
          onClick={closeModal}
        />
      </div>
    </>
  ) : (
    <div className={styles.paddingBody}>
      <Row
        constant
        onClick={() => {
          setShowDiscoveryWorks(false);
        }}
        alignCenter
        className={styles.backButton}
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (e.keyCode === EnterKeyCode) {
            setShowDiscoveryWorks(false);
          }
        }}
      >
        <Icon icon="chevronLeft" />
        <Typography tag="div" size="l" msg={t(Messages.buttonBack)} />
      </Row>
      <DiscoveryInfo />
      <Row constant className={styles.pb25}>
        <Button
          onClick={() => {
            closeModal();
            paymentModeHandler(8, dispatch, baseCase, basicPriceId, '');
          }}
          label={t('button_upgrade_ll_discovery' as Messages)}
          isFullWidth
          className={styles.mt31}
        />
      </Row>
    </div>
  );
};

export default PaymentUpgradeModalBody;
