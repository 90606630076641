import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import GrayPaper from '@Components/application/GrayPaper';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import AutoComplete from '@Components/form/inputs/AutoComplete';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import FileInput from '@Components/form/inputs/FileInput/FileInput';
import MultiRadioButton from '@Components/form/inputs/MultiRadioButton/MultiRadioButton';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { AddressPortal, emptySelectorValue, EnterKeyCode, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { uploadDocumentsMutation } from '@Graphql/dc/dc.mutations';
import { mutationCreateCollaborator, mutationUpdateDC, mutationUploadDocuments } from '@Graphql/dc/mutations';
import { queryTotalNotificationsByStatus } from '@Graphql/dc/queries';
import {
  ServiceProviderIdentifierType,
  useServiceProviderCategoriesQuery,
  useServiceProviderLazyQuery,
  useServiceProvidersLazyQuery,
  useServiceProviderSubCategoriesLazyQuery,
  AccountDataDefinitionInputType,
  AccountDetailsInput,
  CaseServiceProviderFragment,
  useCaseAccountDetailsLazyQuery,
  UpdateCaseAccountDetailsMutation,
  UpdateCaseAccountDetailsMutationVariables,
  UserVerificationStatus,
  CaseProviderCreateMutation,
  CaseProviderCreateMutationVariables,
  CaseProviderUpdateMutation,
  CaseProviderUpdateMutationVariables,
  useServiceProviderDataFieldsCustomLazyQuery,
  AccountDataDefinitionNode,
  UserTitle,
  BaseQuestionInputType,
  ServiceProviderQuestionNode,
  DocumentDocumentType,
  DeathCaseUpdateMutation,
  DeathCaseUpdateMutationVariables,
  UploadDocumentMutation,
  DocumentNode,
  DeathCaseFragment,
  CollaboratorPermission,
  CollaboratorRelationshipFe,
  DeathCaseUpdateMutationInput,
  CreateCollaboratorMutation,
  CreateCollaboratorMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationCaseProviderUpdate,
  mutationDeathCaseProviderCreate,
  mutationUpdateCaseAccountDetails,
} from '@Graphql/providers/mutations';
import { queryCaseServiceProviders } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import KnownExecutors from '@Routes/deathCase/DeathCasePage/ExecutorsForm/KnownExecutors';
import { GeneralFormAddressFields } from '@Routes/lifeCase/LifeCasePage/GeneralFormAddress/GeneralFormAddress';
import SpList from '@Routes/provider/SpList';
import { getUser } from '@Store/auth/auth.selector';
import { removeDocuments, sendOne } from '@Store/dc/dc.actions';
import { normaliseGqlError } from '@Utils/form';
import { addressHelper, capitalize, deathCaseUpdateMutationInputKeys, LooseObject, noop } from '@Utils/helpers';
import { idVerfiyfileUploadHandler, newProviderHandler, deleteConfirmationHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import {
  validateAny,
  validateEmail,
  validateMinLength,
  validateName,
  validatePhone,
  validatePostalCodeCharacters,
  validateRequired,
  validateRequiredBoolean2,
  validateSelector,
} from '@Utils/validator';

import { Address, FullAddress } from '../../../model/Address';
import { ModalProviderProps } from '../Modal';

import styles from './SmartModalBody.scss';

export interface SmartModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  id: string;
  isLifeCase?: boolean;
  caseServiceProvider?: CaseServiceProviderFragment;
  deathCaseData: DeathCaseFragment;
}

export type CaseSubmitValues = {
  [key: string]: any; // Adjust the type based on your actual values
};

export enum ProviderFormFields {
  category = 'category',
  subCategory = 'subCategory',
  provider = 'provider',
  providerRadioControl = 'providerRadioControl',
  allProvider = 'allProvider',
}

export enum ProviderBankFieldLabels {
  accountNumber = 'account_number',
  sortCode = 'sort_code',
}

export enum NotificationFields {
  switchToWhom = 'switchToWhom',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  email = 'email',
  shouldNotify = 'shouldNotify',
  profRelationship = 'profRelationship',
  relationship = 'relationship',
  infoUpdate = 'infoUpdate',
  title = 'title',
}

export enum TabFields {
  deceasedInfo = "Deceased's info",
  will = 'Will',
  documents = 'Documents',
  confirmAndSend = 'Confirm & send',
}

export enum ExecutorFormFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  permission = 'permission',
  title = 'title',
}

export interface ExecutorCollaboratorFields {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  permission: CollaboratorPermission;
  title: string;
  relationship: string;
  isAlsoExecutor?: boolean;
  isAlsoAdministrator?: boolean;
  shouldNotify: boolean;
}

enum IndividualAddressFormFields {
  autoAddress = 'autoAddress',
  autoPrevAddress = 'autoPrevAddress',
  chooseAddress = 'chooseAddress',
  choosePrevAddress = 'choosePrevAddress',
  buildingName = 'building_name',
  buildingNumber = 'building_number',
  address1 = 'address_1',
  address2 = 'address_2',
  city = 'city',
  postalCode = 'postal_code',
  overThreeYearsAddress = 'is_address_over_three',
  prevBuildingName = 'prev_building_name',
  prevBuildingNumber = 'prev_building_number',
  prevAddress1 = 'previous_address_1',
  prevAddress2 = 'previous_address_2',
  prevCity = 'previous_city',
  prevPostalCode = 'previous_postal_code',
  dob = 'birth_date',
  dod = 'death_date',
  executor = 'me_executor',
  administrator = 'me_administrator',
  hasExecutor = 'executors_present',
  hasAdministrator = 'administrators_present',
  will = 'has_will',
  deathCertificate = 'death_ceritifcate',
}

const SmartModalBody: React.FunctionComponent<ModalProviderProps<SmartModalBodyProps>> = ({
  modalData: { onClose = noop, secondButtonText, id, isLifeCase = false, caseServiceProvider, deathCaseData },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const [showCategorySection, setShowCategorySection] = React.useState(true);
  const [showOrSection, setShowOrSection] = React.useState(true);

  const [autoProviderValue, setAutoProviderValue] = React.useState('');

  // const [currentPostalCode, setCurrentPostalCode] = React.useState('');
  const [prevPostalCode, setPrevPostalCode] = React.useState('');

  // const [showClearAddress, setShowClearAddress] = React.useState(false);
  const [showPrevClearAddress, setShowPrevClearAddress] = React.useState(false);

  // const [searching, setSearching] = React.useState(false);
  const [prevSearching, setPrevSearching] = React.useState(false);

  const [formVisible, setFormVisible] = React.useState(true);

  const [execs, setExecs] = React.useState<ExecutorCollaboratorFields[]>([]);

  // const [addressResult, setAddressResult] = React.useState<FullAddress[]>([]);
  const [prevAddressResult, setPrevAddressResult] = React.useState<FullAddress[]>([]);

  // const [assignedAddress, setAssignedAddress] = React.useState(-1);
  const [prevAssignedAddress, setPrevAssignedAddress] = React.useState(-2);

  // const [postCodeTabOut, setPostCodeTabOut] = React.useState(false);
  const [prevPostCodeTabOut, setPrevPostCodeTabOut] = React.useState(false);

  const [addressOverThree, setAddressOverThree] = React.useState(false);
  const [prevManualAddress, setPrevManualAddress] = React.useState(false);

  const [showEmailCaution, setShowEmailCaution] = React.useState(false);

  const [uploadedFiles, setUploadedFiles] = React.useState<DocumentNode[]>([]);
  const [uploadedWillFiles, setUploadedWillFiles] = React.useState<File[]>([]);
  const [caseUploadedFiles, setCaseUploadedFiles] = React.useState<DocumentNode[]>([]);

  const [filesToSubmit, setFilesToSubmit] = React.useState<File[]>([]);

  const [submitAction, setSubmitAction] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [execGreyBox, setExecGreyBox] = React.useState('');

  const [showSpInputFields, setShowInputFields] = React.useState(caseServiceProvider !== undefined);
  const [showCategoryListForm, setShowCategoryListForm] = React.useState(false);
  const [categorySelected, setCategorySelected] = React.useState<{ label: string; value: string } | undefined>(
    undefined
  );

  const [companyForm, setCompanyForm] = React.useState(false);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [has_Will, setHas_Will] = React.useState(false);

  const PermissionOptions = [
    { label: 'Full Access', value: CollaboratorPermission.FullAccess },
    { label: 'Owner', value: CollaboratorPermission.Owner },
    { label: 'Read only', value: CollaboratorPermission.ReadOnly },
  ];

  const onProviderButtonClick = () => {
    onClose();
    closeModal();
    newProviderHandler(t, id, dispatch);
  };

  const categoryValues = useServiceProviderCategoriesQuery({
    fetchPolicy: 'network-only',
  });

  const [getSubCategoryData, subCategoriesValues] = useServiceProviderSubCategoriesLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getProvidersData, providersValues] = useServiceProvidersLazyQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
  });

  const [getServiceProvider, serviceProviderValues] = useServiceProviderLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getCaseAccountDetailsValues, caseAccountDetailsValues] = useCaseAccountDetailsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getSpDcCustomFields, spDcCustomFields] = useServiceProviderDataFieldsCustomLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [updateCaseAccountDetails] = useMutation<
    UpdateCaseAccountDetailsMutation,
    UpdateCaseAccountDetailsMutationVariables
  >(mutationUpdateCaseAccountDetails, {
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [createCaseProviderDCMutation] = useMutation<CaseProviderCreateMutation, CaseProviderCreateMutationVariables>(
    mutationDeathCaseProviderCreate,
    {
      refetchQueries: [
        getOperationName(queryCaseServiceProviders) || '',
        getOperationName(queryTotalNotificationsByStatus) || '',
      ],
      onCompleted: (op: any) => {
        if (submitAction === 'send') {
          if (user?.verificationStatus === UserVerificationStatus.Verified) {
            dispatch(sendOne.started({ deathCaseProviderId: op.caseProviderCreate.caseServiceProvider.id }));
            closeModal();
          } else {
            idVerfiyfileUploadHandler(dispatch, op.caseProviderCreate.caseServiceProvider.id, submitAction);
          }
        } else {
          const toastMsg = isLifeCase ? 'Company added successfully' : `Company added to 'Ready to send'`;
          dispatch(notificationSuccess(toastMsg));
          closeModal();
        }
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        setIsSubmitting(false);
      },
    }
  );

  const [updateCaseProviderDCMutation] = useMutation<CaseProviderUpdateMutation, CaseProviderUpdateMutationVariables>(
    mutationCaseProviderUpdate,
    {
      refetchQueries: [
        getOperationName(queryCaseServiceProviders) || '',
        getOperationName(queryTotalNotificationsByStatus) || '',
      ],
      onCompleted: () => {
        if (submitAction === 'send') {
          if (user?.verificationStatus === UserVerificationStatus.Verified) {
            dispatch(sendOne.started({ deathCaseProviderId: caseServiceProvider ? caseServiceProvider?.id : '' }));
            closeModal();
          } else {
            idVerfiyfileUploadHandler(dispatch, caseServiceProvider?.id, submitAction);
          }
        } else {
          closeModal();
        }
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const [createCollabs] = useMutation<CreateCollaboratorMutation, CreateCollaboratorMutationVariables>(
    mutationCreateCollaborator,
    {
      // onError: (error: any) => {
      //   dispatch(notificationError(normaliseGqlError(error.message)));
      // },
    }
  );

  const [updateDCMutation] = useMutation<DeathCaseUpdateMutation, DeathCaseUpdateMutationVariables>(mutationUpdateDC, {
    // onError: (error: any) => {
    //   dispatch(notificationError(normaliseGqlError(error.message)));
    // },
    onCompleted: () => {
      if (execs.length > 0) {
        execs.forEach((item: any) => {
          createCollabs({
            variables: {
              input: {
                baseCase: deathCaseData.id,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email ?? '',
                phone: item.phone,
                relationship: has_Will
                  ? CollaboratorRelationshipFe.Executor
                  : CollaboratorRelationshipFe.WillAdministrator,
                permission: item.permission,
                shouldNotify: true,
                title: item?.title,
              },
            },
          });
        });
      }
    },
  });

  const [updateDocumentsMutation] = useMutation<UploadDocumentMutation, any>(mutationUploadDocuments, {
    onCompleted: (output: any) => {
      dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
      const newDocument = uploadedFiles.concat(output.uploadDocument.document);
      setUploadedFiles(newDocument);
    },
    onError: (apiError: any) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
    },
  });

  React.useEffect(() => {
    if (caseServiceProvider) {
      getSubCategoryData({
        variables: { parentCategory: caseServiceProvider.serviceProvider.subCategory.parentCategory.id },
      });
      getProvidersData({ variables: { subCategory: caseServiceProvider.serviceProvider.subCategory.id } });
      getServiceProvider({ variables: { id: caseServiceProvider.serviceProvider.id } });
      getSpDcCustomFields({ variables: { baseCase: id, serviceProvider: caseServiceProvider.serviceProvider.id } });
      getCaseAccountDetailsValues({
        variables: {
          caseServiceProvider: caseServiceProvider.id,
        },
      });
    }
  }, [
    caseServiceProvider,
    getSubCategoryData,
    getProvidersData,
    getServiceProvider,
    getCaseAccountDetailsValues,
    getSpDcCustomFields,
    id,
  ]);

  if (categoryValues.loading || spDcCustomFields.loading) {
    return <LoadingSpinner />;
  }

  function getControl(fieldType: string) {
    let controlName = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
      case AccountDataDefinitionInputType.Textarea:
      case AccountDataDefinitionInputType.Expandcollapse:
        controlName = TextInput;
        break;
      case AccountDataDefinitionInputType.Dropdown:
        controlName = SelectInput;
        break;
      case AccountDataDefinitionInputType.Date:
        controlName = DatePickerInput;
        break;
      case AccountDataDefinitionInputType.Checkbox:
        controlName = Checkbox;
        break;
      case AccountDataDefinitionInputType.Radiobutton:
        controlName = MultiRadioButton;
        break;
      default:
        break;
    }
    return controlName;
  }

  function getControlType(fieldType: string) {
    let controlType = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
      case AccountDataDefinitionInputType.Expandcollapse:
        controlType = 'text';
        break;
      case AccountDataDefinitionInputType.Checkbox:
        controlType = CheckBoxTypes.checkbox;
        break;
      case AccountDataDefinitionInputType.Textarea:
        controlType = 'textarea';
        break;
      case AccountDataDefinitionInputType.Radiobutton:
        controlType = CheckBoxTypes.radio;
        break;
      default:
        break;
    }
    return controlType;
  }

  const dataForSelect = (queryData: any) =>
    queryData?.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const dataForDynamicSelect = (queryData: any) =>
    queryData?.map((item: any) => {
      return {
        value: item,
        label: item,
      };
    });

  const serviceProviderCategories = categoryValues.data?.serviceProviderCategories.map((mc: any) => {
    return {
      value: '',
      label: mc.name,
      options: mc.subCategories.map((sbc: any) => {
        return {
          value: sbc.id,
          label: sbc.name,
        };
      }),
    };
  });

  const serviceProviderSubCategories = dataForSelect(subCategoriesValues.data?.serviceProviderSubCategories);
  const serviceProviders = dataForSelect(providersValues.data?.serviceProviders);
  const serviceProviderInputType = serviceProviderValues.data?.serviceProvider?.identifierType;

  const categoryInitialValue =
    // caseServiceProvider
    //   ? serviceProviderCategories.find((c: any) => c.value === parentCategoryId):
    emptySelectorValue;
  const subCategoryInitialValue =
    caseServiceProvider && serviceProviderSubCategories
      ? serviceProviderSubCategories.find((s: any) => s.value === caseServiceProvider.serviceProvider.subCategory.id)
      : emptySelectorValue;
  const providerInitialValue =
    caseServiceProvider && serviceProviders
      ? serviceProviders.find((p: any) => p.value === caseServiceProvider.serviceProvider.id)
      : emptySelectorValue;

  const getExtraControls = () => {
    return spDcCustomFields.data?.serviceProviderDataFieldsCustom
      ? spDcCustomFields.data?.serviceProviderDataFieldsCustom.notificationData.sort(
          (a: any, b: any) =>
            a.widgetDisplayOrder - b.widgetDisplayOrder ||
            b.isPrimaryIdentifier - a.isPrimaryIdentifier ||
            b.isMandatory - a.isMandatory
        )
      : [];
  };

  const compareVersions = (a: string, b: string) => {
    const aParts = a.split('.').map(Number);
    const bParts = b.split('.').map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i += 1) {
      const aVal = aParts[i] || 0;
      const bVal = bParts[i] || 0;

      if (aVal !== bVal) {
        return aVal - bVal;
      }
    }

    return 0;
  };

  const getCaseQuestions = () => {
    const spQuestions = spDcCustomFields.data?.serviceProviderDataFieldsCustom;
    return spQuestions
      ? spQuestions.caseData.sort((a: any, b: any) => compareVersions(a.customDisplayOrder, b.customDisplayOrder))
      : [];
  };

  const sortedCaseQuestions = getCaseQuestions();

  const switchWhomOptions = [
    { label: 'Me', value: 'Me' },
    { label: 'Add new', value: 'add_new' },
  ];

  const sortedProviderControls = getExtraControls();

  const validateCustomPrimaryField = (inputType: string): Yup.Schema<string | boolean> => {
    if (inputType === AccountDataDefinitionInputType.Dropdown) {
      return validateSelector(t);
    }
    if (inputType === AccountDataDefinitionInputType.FileUpload) {
      return validateAny();
    }
    if (inputType === AccountDataDefinitionInputType.Checkbox) {
      return validateRequiredBoolean2(t);
    }
    return validateRequired(t);
  };

  const uniqueCustomTabNames = sortedCaseQuestions
    .filter((item, index, self) => index === self.findIndex((tab) => tab.customTabName === item.customTabName))
    .map((item) => item.customTabName);

  const handleAutoProviderChange = (item: any) => {
    getServiceProvider({ variables: { id: item } });
    getSpDcCustomFields({ variables: { baseCase: id, serviceProvider: item } });
    setShowCategorySection(false);
    setShowOrSection(false);
    setAutoProviderValue(item);
    setShowInputFields(true);
  };

  let schemaObj: LooseObject = {};
  schemaObj = {
    [ProviderFormFields.category]: showCategorySection ? validateSelector(t) : validateAny(),
    [ProviderFormFields.subCategory]: showCategorySection ? validateSelector(t) : validateAny(),
    [ProviderFormFields.provider]: showCategorySection ? validateSelector(t) : validateAny(),
    [GeneralFormAddressFields.previousAddress1]: addressOverThree ? validateRequired(t) : validateAny(),
    [GeneralFormAddressFields.previousCity]: addressOverThree ? validateRequired(t) : validateAny(),
    [GeneralFormAddressFields.previousPostalCode]: addressOverThree ? validateRequired(t) : validateAny(),
  };

  const reqAccountDataDefinitions = sortedProviderControls.filter(
    (control: any) => control.isPrimaryIdentifier === true || control.isMandatory === true
  );

  reqAccountDataDefinitions.forEach((f: any) => {
    schemaObj[f.id] = validateCustomPrimaryField(f.inputType);
  });

  const caseQuestionsMandatoryFields = sortedCaseQuestions.filter(
    (control: any) => control.question.isMandatory === true
  );

  caseQuestionsMandatoryFields.forEach((f: any) => {
    schemaObj[f.question.id] = validateCustomPrimaryField(f.question.inputType);
  });
  const newSchema = Yup.object(schemaObj);

  let initialValues: LooseObject = {};
  initialValues = {
    [ProviderFormFields.category]: categoryInitialValue,
    [ProviderFormFields.subCategory]: subCategoryInitialValue,
    [ProviderFormFields.provider]: providerInitialValue,
    [ProviderFormFields.allProvider]: '',
  };

  const setAccountInputValues = (f: any) => {
    const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
    const currentControl = accountData?.find((x: any) => f.inputLabel === x.key);
    if (f.inputType === AccountDataDefinitionInputType.Dropdown) {
      const dropdownValues = dataForDynamicSelect(f.inputValues);
      const dropdownValue = dropdownValues.find((d: any) => d.value === currentControl?.value);
      return dropdownValue;
    }
    return currentControl ? currentControl.value : '';
  };

  const AutoToggleForm = () => {
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
      const prevSearchedAddress = prevAddressResult.find((addr: FullAddress) => addr.id === prevAssignedAddress);
      if (prevSearchedAddress) {
        setFieldValue(GeneralFormAddressFields.prevBuildingName, addressHelper(prevSearchedAddress.buildingName));
        setFieldValue(GeneralFormAddressFields.prevBuildingNumber, addressHelper(prevSearchedAddress.buildingNumber));
        setFieldValue(GeneralFormAddressFields.previousAddress1, addressHelper(prevSearchedAddress.address1));
        setFieldValue(GeneralFormAddressFields.previousAddress2, addressHelper(prevSearchedAddress.address2));
        setFieldValue(GeneralFormAddressFields.previousCity, addressHelper(prevSearchedAddress.city));
        setFieldValue(GeneralFormAddressFields.previousPostalCode, addressHelper(prevSearchedAddress.postalCode));
      }

      if (!addressOverThree) {
        setFieldValue(GeneralFormAddressFields.prevBuildingName, '');
        setFieldValue(GeneralFormAddressFields.prevBuildingNumber, '');
        setFieldValue(GeneralFormAddressFields.previousAddress1, '');
        setFieldValue(GeneralFormAddressFields.previousAddress2, '');
        setFieldValue(GeneralFormAddressFields.previousCity, '');
        setFieldValue(GeneralFormAddressFields.previousPostalCode, '');
        setPrevManualAddress(false);
      }
    }, [setFieldValue]);

    return null;
  };

  const titleOptions = Object.values(UserTitle)
    .filter((titles: string) => titles !== UserTitle.NotSpecified)
    .map((desTitle: string) => ({
      label: desTitle.charAt(0).toUpperCase() + desTitle.slice(1).toLowerCase(),
      value: desTitle,
    }));

  sortedProviderControls.forEach((f: any) => {
    if (f.inputType === AccountDataDefinitionInputType.Checkbox) {
      const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
      const currentControl = accountData?.find((x: any) => f.inputLabel === x.key);
      if (currentControl) {
        const definedArray = currentControl.value.split(';');
        //eslint-disable-next-line
        f.inputValues.map((iv: string, indexPos: number) => {
          initialValues[`checkbox_${indexPos}_PrimaryField_${f.id}`] = definedArray.includes(iv);
        });
      }
    } else {
      initialValues[f.id] = setAccountInputValues(f);
    }
  });

  const checkIfCheckboxControlSelected = (accKeys: string[]) => {
    const isPresent: boolean[] = [];
    accKeys.forEach((headers: string) => {
      if (headers.startsWith('checkbox')) {
        isPresent.push(true);
      } else {
        isPresent.push(false);
      }
    });
    if (isPresent.includes(true)) {
      return true;
    }

    return false;
  };

  const profRelationships = [
    {
      label: 'Executor',
      value: 'EXECUTOR',
      infoMessage:
        "Anyone who makes a will must name an executor. An executor is legally responsible for carrying out the instructions in the person's will and handling their estate (their money, property, and possessions).",
      infoTitle: 'What is an executor?',
    },
    {
      label: 'Administrator',
      value: 'WILL_ADMINISTRATOR',
      infoMessage:
        'An administrator is appointed by the courts in the absence of a will. An administrator is legally responsible for handling the estate of the deceased (their money, property, and possessions).',
      infoTitle: 'What is an administrator?',
    },
    { label: 'Neither of the above', value: 'NONE' },
  ];

  enum CollaboratorRelationship {
    Child = 'CHILD',
    Friend = 'FRIEND',
    Parent = 'PARENT',
    Partner = 'PARTNER',
    Sibling = 'SIBLING',
  }

  const relationshipOptions = Object.values(CollaboratorRelationship).map((rel: any) => ({
    label: capitalize(rel),
    value: rel,
  }));

  const childQuestionVisibility = (
    hasParent: boolean,
    values: any,
    item: AccountDataDefinitionNode,
    parentQuestion: AccountDataDefinitionNode | undefined
  ) => {
    if (hasParent && parentQuestion) {
      if (parseInt(item.widgetDisplayOrder.split('.')[2], 10) > 0) {
        const splitWidgetOrder = item.widgetDisplayOrder.split('.');
        const grandParentWidgetOrder = `${splitWidgetOrder[0]}.0${parseInt(splitWidgetOrder[1], 10) - 1}.0${
          parseInt(splitWidgetOrder[2], 10) - 1
        }`;
        const grandParentQuestion = sortedProviderControls.find(
          (swd: AccountDataDefinitionNode) => swd.widgetDisplayOrder === grandParentWidgetOrder
        );
        return (
          values[parentQuestion.id] !== '' &&
          grandParentQuestion &&
          values[grandParentQuestion.id] === parentQuestion.answerCondition
        );
      }
      return values[parentQuestion.id] === item.answerCondition;
    }
    return true;
  };

  const onFocus = () => setShowEmailCaution(true);

  const onBlur = () => {
    setShowEmailCaution(false);
  };

  const removeItem = (firstName: any) => {
    setExecs((prevItems) => prevItems.filter((item) => item.firstName !== firstName));
  };

  const removeCertificateHandler = (fileId: string, index: number) => {
    dispatch(removeDocuments.started({ id: fileId, index, documentType: DocumentDocumentType.Certificate }));
    const fileNameToBeDeleted = uploadedFiles.find((doc: DocumentNode) => doc.id === fileId)?.fileName;
    const existingUploadedDocs = uploadedFiles.filter((doc: DocumentNode) => doc.id !== fileId);
    const exisFileDocs = filesToSubmit.filter((doc: File) => doc.name !== fileNameToBeDeleted);
    setUploadedFiles(existingUploadedDocs);
    setFilesToSubmit(exisFileDocs);
  };

  const controlSize = isMobile ? 12 : 10;

  const resultPrevAddressOptions =
    prevAddressResult &&
    prevAddressResult.map((add: FullAddress) => ({
      label: add.formattedAddress,
      value: add.id,
    }));

  const getAddress = () => {
    const paramPostalCode = prevPostalCode;
    if (paramPostalCode !== '') {
      setPrevSearching(true);
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      fetch(AddressPortal.replace('postalCode', paramPostalCode), requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((apiData) => {
          if (apiData.Message) {
            setPrevAddressResult([]);
            setPrevSearching(false);
          } else {
            const outputData = new Address(apiData);
            if (outputData.addresses.length === 0) {
              dispatch(notificationError('Address not found for this postal code'));
            }
            setPrevAddressResult(outputData.addresses);
            setPrevSearching(false);
            setPrevPostCodeTabOut(false);
          }
        });
    }
  };

  const dynamicHtml = (item: AccountDataDefinitionNode, values: any) => {
    const splitWidgetOrder = item.widgetDisplayOrder.split('.');
    const hasParent = parseInt(splitWidgetOrder[1], 10) > 0 || parseInt(splitWidgetOrder[2], 10) > 0;
    let parentWidgetOrder = '';
    let parentQuestion: AccountDataDefinitionNode | undefined;
    if (hasParent) {
      parentWidgetOrder =
        parseInt(splitWidgetOrder[2], 10) > 0
          ? `${splitWidgetOrder[0]}.${splitWidgetOrder[1]}.0${(parseInt(splitWidgetOrder[2], 10) - 1, 10)}`
          : `${splitWidgetOrder[0]}.0${(parseInt(splitWidgetOrder[1], 10) - 1, 10)}.${splitWidgetOrder[2]}`;
      parentQuestion = sortedProviderControls.find(
        (swd: AccountDataDefinitionNode) => swd.widgetDisplayOrder === parentWidgetOrder
      );
    }
    if (item.inputType === AccountDataDefinitionInputType.Dropdown) {
      return (
        <GridRow>
          <GridCol size={12}>
            <Field
              name={`${item.id}`}
              type={getControlType(item.inputType)}
              component={getControl(item.inputType)}
              label={item.inputLabel}
              options={dataForDynamicSelect(item.inputValues)}
              required={item.isPrimaryIdentifier || item.isMandatory}
            />
          </GridCol>
        </GridRow>
      );
    }
    if (
      item.inputType === AccountDataDefinitionInputType.Radiobutton &&
      childQuestionVisibility(hasParent, values, item, parentQuestion)
    ) {
      let options = [];

      options = item.inputValues.map((v: string) => {
        return {
          label: v,
          value: v,
        };
      });

      return (
        <GridRow>
          <GridCol size={12}>
            <Field
              name={item.id}
              component={getControl(item.inputType)}
              label={item.inputLabel}
              options={options}
              required={item.isPrimaryIdentifier || item.isMandatory}
            />
          </GridCol>
        </GridRow>
      );
    }

    if (item.inputType === AccountDataDefinitionInputType.Checkbox) {
      return (
        <GridRow key="idx">
          <GridCol size={12}>
            <Field
              name={`${item.inputLabel}`}
              type={getControlType(item.inputType)}
              component={getControl(item.inputType)}
              label={item.inputLabel}
              required={item.isPrimaryIdentifier || item.isMandatory}
            />
          </GridCol>
        </GridRow>
      );
    }

    if (item.inputType === AccountDataDefinitionInputType.FileUpload) {
      return (
        <>
          <Typography
            msg={`${item.inputLabel}${item.isMandatory || item.isPrimaryIdentifier ? '*' : ''}`}
            tag="span"
            size="l"
          />
          <Row>
            <Col size={12}>
              <div>
                <FileInput
                  onDrop={(files: File[]) => {
                    setFilesToSubmit(files);
                    updateDocumentsMutation({
                      variables: {
                        input: {
                          baseCaseId: id,
                          documentType: DocumentDocumentType.Other,
                          files,
                        },
                      },
                    });
                  }}
                  files={uploadedFiles}
                  onRemoveClick={removeCertificateHandler}
                />
              </div>
            </Col>
          </Row>
        </>
      );
    }

    if (
      item.inputType === AccountDataDefinitionInputType.Collaborator &&
      childQuestionVisibility(hasParent, values, item, parentQuestion)
    ) {
      return (
        <div className={styles.indentFlex}>
          <div className={styles.indentBar} />
          <div className={styles.w100}>
            <Typography msg="Please provide the details of this person" tag="div" size="l" />

            <GridRow>
              <GridCol size={6}>
                <Field
                  name={NotificationFields.title}
                  component={SelectInput}
                  options={titleOptions}
                  label="Title"
                  placeholder="--select--"
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.firstName}
                  component={TextInput}
                  label="First name"
                  placeholder="Enter first name(s)"
                  options={switchWhomOptions}
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.lastName}
                  component={TextInput}
                  label="Surname"
                  placeholder="Enter surname(s)"
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.phone}
                  component={TextInput}
                  label="Contact number"
                  placeholder="Enter contact number"
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.email}
                  component={TextInput}
                  label="Email"
                  placeholder={values.shouldNotify ? 'No email address' : 'Enter email address'}
                />
              </GridCol>
            </GridRow>

            {parentQuestion && values[parentQuestion?.id] === 'Add new' && (
              <Field
                name={NotificationFields.shouldNotify}
                label="This person does not have an email address"
                component={Checkbox}
                type={CheckBoxTypes.checkbox}
              />
            )}

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.profRelationship}
                  options={profRelationships}
                  component={MultiRadioButton}
                  label="This person is the:"
                />
              </GridCol>
            </GridRow>

            <GridRow>
              <GridCol size={12}>
                <Field
                  name={NotificationFields.relationship}
                  options={relationshipOptions}
                  component={MultiRadioButton}
                  label="What is your relationship to the deceased?"
                />
              </GridCol>
            </GridRow>
          </div>
        </div>
      );
    }
    if (childQuestionVisibility(hasParent, values, item, parentQuestion)) {
      return (
        <GridRow>
          <GridCol size={12}>
            <Field
              name={`${item.id}`}
              type={getControlType(item.inputType)}
              component={getControl(item.inputType)}
              label={item.inputLabel}
              required={item.isPrimaryIdentifier || item.isMandatory}
            />
          </GridCol>
        </GridRow>
      );
    }

    return <></>;
  };

  const helpTextHtml = (detailedHelpText: string) => {
    return (
      <GridRow>
        <GridCol size={10}>
          <Typography msg={detailedHelpText} tag="div" size="s" color="footerColor" />
        </GridCol>
      </GridRow>
    );
  };

  const caseChildQuestionVisibility = (
    hasParent: boolean,
    values: any,
    item: ServiceProviderQuestionNode,
    parentQuestion: ServiceProviderQuestionNode | undefined
  ) => {
    if (hasParent && parentQuestion) {
      if (parseInt(item.question.displayOrder.split('.')[3], 10) > 0) {
        const splitWidgetOrder = item.question.displayOrder.split('.');
        const grandParentWidgetOrder = `${splitWidgetOrder[0]}.0${parseInt(splitWidgetOrder[1], 10)}.00`;
        const grandParentQuestion = sortedCaseQuestions.find(
          (swd: any) => swd.question.displayOrder === grandParentWidgetOrder
        );
        return (
          values[parentQuestion.question.id] === item.answerCondition &&
          grandParentQuestion &&
          values[grandParentQuestion.question.id] === parentQuestion.answerCondition
        );
      }
      return values[parentQuestion.question.id] === item.answerCondition;
    }
    return true;
  };

  const handlePrevAddressChange = (item: any) => {
    setPrevAssignedAddress(item.value);
    setPrevManualAddress(true);
  };

  const dynamicHtmlCaseQuestions = (item: ServiceProviderQuestionNode, idx: number, parentValues: any) => {
    const splitWidgetOrder = item.question.displayOrder.split('.');
    const isSingleIntended = parseInt(splitWidgetOrder[2], 10) > 0;
    const isDoubleIntended = splitWidgetOrder.length > 3;
    let parentWidgetOrder = '';
    if (isSingleIntended) {
      parentWidgetOrder = `${splitWidgetOrder[0]}.${splitWidgetOrder[1]}.00`;
    }
    if (isDoubleIntended) {
      parentWidgetOrder = `${splitWidgetOrder[0]}.${splitWidgetOrder[1]}.${splitWidgetOrder[2]}`;
    }
    const parentQuestion = sortedCaseQuestions.find(
      (swd: any) => swd.question.displayOrder === parentWidgetOrder
    ) as ServiceProviderQuestionNode;

    const hasWill = sortedCaseQuestions.find((swd: any) => swd.question.fieldName === 'has_will');

    if (hasWill) {
      setHas_Will(parentValues[hasWill.question.id] === 'Yes');
    }

    if (item.question.inputType === BaseQuestionInputType.Dropdown) {
      return (
        <>
          <div className={classNames(isSingleIntended ? styles.indentFlex : '')}>
            <div className={isSingleIntended ? styles.indentBar : ''} />
            <div>
              <div className={classNames(isDoubleIntended ? styles.indentFlex : '')}>
                <div className={isDoubleIntended ? styles.indentBar : ''} />
                <div className={styles.w50}>
                  <Field
                    name={item.id}
                    type={getControlType(item.question.questionType)}
                    component={getControl(item.question.questionType)}
                    label={item.question.questionText}
                    options={dataForDynamicSelect(item.question.inputValues.split(','))}
                    required={item.question.isIdentifier || item.question.isMandatory}
                  />
                </div>
              </div>
            </div>
          </div>
          {item.detailedHelpText && helpTextHtml(item.detailedHelpText)}
        </>
      );
    }
    if (item.question.fieldName === 'is_address_over_three') {
      let options = [];

      if (parentValues[item.question.id] === 'No') {
        setAddressOverThree(true);
      }

      if (parentValues[item.question.id] === 'Yes') {
        setAddressOverThree(false);
      }

      const formLabel = (optionValue: string) => {
        if (item.question.fieldName === 'property_type') {
          return t(`${Messages.labelBaseCasePropertyPrefix}${optionValue.toLowerCase()}` as Messages);
        }
        return optionValue;
      };

      if (Array.isArray(item.question.inputValues)) {
        options = item.question.inputValues.map((v: string) => {
          return {
            label: formLabel(v.trim()),
            value: v.trim(),
          };
        });
      } else {
        options = item.question.inputValues.split(',').map((v: string) => {
          return {
            label: formLabel(v.trim()),
            value: v.trim(),
          };
        });
      }
      return (
        <>
          <AutoToggleForm />
          <Row>
            <Col className={styles.grayBlock} />
            <Col className={styles.whiteBlock}>
              <Typography msg={`${deathCaseData.address1}`} tag="div" size="s" />
              <Typography msg={`${deathCaseData.address2}`} tag="div" size="s" />
              <Typography msg={`${deathCaseData.city}`} tag="div" size="s" />
              <Typography msg={`${deathCaseData.postalCode}`} tag="div" size="s" />
            </Col>
          </Row>

          <div className={classNames(isSingleIntended ? styles.indentFlex : '')}>
            <div className={isSingleIntended ? styles.indentBar : ''} />
            <div>
              <div className={classNames(isDoubleIntended ? styles.indentFlex : '')}>
                <div className={isDoubleIntended ? styles.indentBar : ''} />
                <div>
                  <Field
                    name={item.question.id}
                    component={getControl(item.question.inputType)}
                    label={item.question.questionText}
                    options={options}
                    required={item.question.isIdentifier || item.question.isMandatory}
                  />
                </div>
              </div>
            </div>
          </div>
          {parentValues[item.question.id] === 'No' && (
            <div className={styles.indentFlex}>
              <div className={styles.indentBar} />
              <div className={styles.w100}>
                <Typography tag="div" size="l" msg={t(Messages.fieldAddPreviousAddress)} />
                <Row className={styles.addressContainer} size={12}>
                  <Col size={8}>
                    <input
                      name={GeneralFormAddressFields.previousAutoAddress}
                      type="text"
                      value={prevPostalCode}
                      autoComplete="off"
                      className={styles.input}
                      onChange={(e: any) => {
                        setPrevPostalCode(e.currentTarget.value);
                        setShowPrevClearAddress(true);
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && validatePostalCodeCharacters(prevPostalCode)) {
                          e.preventDefault();
                          getAddress();
                        }
                      }}
                      onBlur={() => {
                        if (validateMinLength(prevPostalCode) && resultPrevAddressOptions.length === 0) {
                          setPrevPostCodeTabOut(true);
                        } else {
                          setPrevPostCodeTabOut(false);
                        }
                      }}
                    />
                    {showPrevClearAddress && (
                      <Icon
                        icon="close-outline"
                        className={styles.visibilityToggleIcon}
                        size={IconSizes.s}
                        onClick={() => {
                          setShowPrevClearAddress(false);
                          setPrevPostalCode('');
                          setPrevAddressResult([]);
                          setPrevAssignedAddress(-2);
                          setPrevPostCodeTabOut(false);
                        }}
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            setShowPrevClearAddress(false);
                            setPrevPostalCode('');
                            setPrevAddressResult([]);
                            setPrevAssignedAddress(-2);
                            setPrevPostCodeTabOut(false);
                          }
                        }}
                      />
                    )}
                  </Col>
                  <Col size={4} className={classNames(styles.searchButtonContainer)}>
                    <Button
                      type={ButtonTypes.button}
                      style={ButtonStyles.transparent}
                      loading={prevSearching}
                      label={t(Messages.buttonSearch)}
                      icon="search"
                      iconSize={IconSizes.sxs}
                      onClick={() => getAddress()}
                      isFullWidth={true}
                      constant
                      disabled={!validatePostalCodeCharacters(prevPostalCode)}
                    />
                  </Col>
                </Row>
                {resultPrevAddressOptions.length > 0 && (
                  <Row className={styles.topSpacing} size={12}>
                    <Col size={12}>
                      <Field
                        name={GeneralFormAddressFields.choosePrevAddress}
                        component={SelectInput}
                        label=""
                        placeholder="---- Select your previous address ----"
                        options={resultPrevAddressOptions}
                        disabled={!resultPrevAddressOptions.length}
                        onSelectChange={handlePrevAddressChange}
                        //isFullWidth={isMobile || isTablet}
                        constant
                        onFocus={() => setPrevPostCodeTabOut(false)}
                      />
                    </Col>
                  </Row>
                )}

                <GridRow>
                  <GridCol className={styles.adresslink} size={9}>
                    <div
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setPrevManualAddress(!prevManualAddress);
                        }
                      }}
                      onClick={() => setPrevManualAddress(!prevManualAddress)}
                      //eslint-disable-next-line
                      tabIndex={0}
                    >
                      <Typography msg="Or enter address manually" tag="span" />
                    </div>
                  </GridCol>
                </GridRow>
                {prevManualAddress && (
                  <>
                    <GridRow>
                      <GridCol size={4}>
                        <Field
                          name={GeneralFormAddressFields.prevBuildingName}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingName)}
                        />
                      </GridCol>
                      <GridCol size={4}>
                        <Field
                          name={GeneralFormAddressFields.prevBuildingNumber}
                          type="text"
                          component={TextInput}
                          label={t(Messages.fieldBuildingNumber)}
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={GeneralFormAddressFields.previousAddress1}
                          type="text"
                          component={TextInput}
                          label="Address line #1"
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={12}>
                        <Field
                          name={GeneralFormAddressFields.previousAddress2}
                          type="text"
                          component={TextInput}
                          label="Address line #2"
                        />
                      </GridCol>
                    </GridRow>
                    <GridRow>
                      <GridCol size={4}>
                        <Field
                          name={GeneralFormAddressFields.previousCity}
                          type="text"
                          component={TextInput}
                          label="City"
                        />
                      </GridCol>
                      <GridCol size={4}>
                        <Field
                          name={GeneralFormAddressFields.previousPostalCode}
                          type="text"
                          component={TextInput}
                          label="Postal code"
                        />
                      </GridCol>
                    </GridRow>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      );
    }
    if (
      item.question.inputType === BaseQuestionInputType.Radiobutton &&
      item.question.inputValues !== '' &&
      item.question.fieldName !== 'is_address_over_three' &&
      caseChildQuestionVisibility(isSingleIntended || isDoubleIntended, parentValues, item, parentQuestion)
    ) {
      if (
        item.question.fieldName === IndividualAddressFormFields.executor ||
        item.question.fieldName === IndividualAddressFormFields.administrator
      ) {
        setExecGreyBox(item.question.id);
      }
      let options = [];

      const formLabel = (optionValue: string) => {
        if (item.question.fieldName === 'property_type') {
          return t(`${Messages.labelBaseCasePropertyPrefix}${optionValue.toLowerCase()}` as Messages);
        }
        return optionValue;
      };

      if (Array.isArray(item.question.inputValues)) {
        options = item.question.inputValues.map((v: string) => {
          return {
            label: formLabel(v.trim()),
            value: v.trim(),
          };
        });
      } else {
        options = item.question.inputValues.split(',').map((v: string) => {
          return {
            label: formLabel(v.trim()),
            value: v.trim(),
          };
        });
      }

      return (
        <>
          <div className={classNames(isSingleIntended ? styles.indentFlex : '')}>
            <div className={isSingleIntended ? styles.indentBar : ''} />
            <div>
              <div className={classNames(isDoubleIntended ? styles.indentFlex : '')}>
                <div className={isDoubleIntended ? styles.indentBar : ''} />
                <div>
                  <Field
                    name={item.question.id}
                    component={getControl(item.question.inputType)}
                    label={item.question.questionText}
                    options={options}
                    required={item.question.isIdentifier || item.question.isMandatory}
                  />
                </div>
              </div>
            </div>
          </div>

          {item.detailedHelpText && helpTextHtml(item.detailedHelpText)}
        </>
      );
    }

    if (item.question.inputType === BaseQuestionInputType.Checkbox) {
      return (
        <>
          {item.question.inputValues &&
            item.question.inputValues.split(',').map((inpVal: any, index: number) => (
              <GridRow key={`div_${index}`}>
                <GridCol size={12}>
                  <Field
                    name={item.question.id}
                    type={getControlType(item.question.inputType)}
                    component={getControl(item.question.inputType)}
                    label={inpVal}
                  />
                </GridCol>
              </GridRow>
            ))}

          {item.question.inputValues === undefined && (
            <GridRow>
              <GridCol size={12}>
                <Field
                  name={`checkbox_PrimaryField_${item.question.id}`}
                  type={getControlType(item.question.inputType)}
                  component={getControl(item.question.inputType)}
                  label={item.question.questionText}
                />
              </GridCol>
            </GridRow>
          )}

          {item.detailedHelpText && helpTextHtml(item.detailedHelpText)}
        </>
      );
    }

    if (item.question.inputType === BaseQuestionInputType.FileUpload) {
      const getFileType = (caseItem: ServiceProviderQuestionNode) => {
        if (caseItem.question.questionText.toLowerCase().includes('death')) {
          return DocumentDocumentType.Certificate;
        }
        return DocumentDocumentType.Will;
      };

      const fileType = getFileType(item);
      const label =
        fileType === DocumentDocumentType.Certificate ? 'subtitle_death_certificate_form' : 'msg_upload_will';
      const showWill = () => {
        if (fileType === DocumentDocumentType.Will) {
          return has_Will;
        }
        return true;
      };
      return (
        showWill() && (
          <>
            <Typography tag="div" msg={t(label as Messages)} size="l" />
            <FileInput
              isSmartModal
              onDrop={
                (files: File[]) => {
                  const iFiles = files.map((upfile: File) => {
                    return {
                      file: upfile.name,
                      id: `file${caseUploadedFiles.length + 1}`,
                      documentType: fileType,
                      fileName: upfile.name,
                    } as DocumentNode;
                  });
                  setCaseUploadedFiles(iFiles);
                  setUploadedWillFiles(files);
                }
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onRemoveClick={() => deleteConfirmationHandler(t, dispatch, fileType)}
              files={caseUploadedFiles}
            />
          </>
        )
      );
    }

    if (
      item.question.inputType === BaseQuestionInputType.Collaborator &&
      caseChildQuestionVisibility(isSingleIntended || isDoubleIntended, parentValues, item, parentQuestion)
    ) {
      return (
        <>
          <div className={classNames(isSingleIntended ? styles.indentFlex : '')}>
            <div className={isSingleIntended ? styles.indentBar : ''} />
            <div className={styles.w100}>
              <div className={classNames(isDoubleIntended ? styles.indentFlex : '')}>
                <div className={isDoubleIntended ? styles.indentBar : ''} />
                <div className={styles.w100}>
                  <>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        [ExecutorFormFields.firstName]: '',
                        [ExecutorFormFields.lastName]: '',
                        [ExecutorFormFields.email]: '',
                        [ExecutorFormFields.phone]: '+44',
                        [ExecutorFormFields.permission]: emptySelectorValue,
                        [ExecutorFormFields.title]: emptySelectorValue,
                      }}
                      validationSchema={Yup.object({
                        [ExecutorFormFields.firstName]: validateName(t),
                        [ExecutorFormFields.lastName]: validateName(t),
                        [ExecutorFormFields.email]: validateEmail(t),
                        [ExecutorFormFields.phone]: validatePhone(t),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const addedCollabs: ExecutorCollaboratorFields = {
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.email,
                          relationship: CollaboratorRelationshipFe.Executor,
                          phone: values.phone,
                          title: values.title?.value,
                          permission: CollaboratorPermission.FullAccess,
                          shouldNotify: true,
                        };
                        const existingExecs = [...execs, addedCollabs];
                        setExecs(existingExecs);
                        resetForm();
                        setFormVisible(false);
                      }}
                    >
                      {({ status, handleSubmit, setFieldValue, setStatus }) => (
                        <>
                          {execs.length > 0 && parentValues[execGreyBox] === 'No' && (
                            <KnownExecutors
                              executors={execs.filter((exe: any) => typeof exe === 'object' && !Array.isArray(exe))}
                              setStatus={setStatus}
                              user={user}
                              caseId={deathCaseData.id}
                              isLifeCase={false}
                              isCollabrator
                              hasWill={has_Will}
                              onClick={(exeId) => removeItem(exeId)}
                              // updateExecs={setUpdatedExecs}
                              // has_Will={has_Will}
                            />
                          )}
                          {formVisible && parentValues[execGreyBox] === 'No' && (
                            <Form>
                              <Typography msg="Please provide the details of this person" tag="div" />
                              <GrayPaper width="width-full">
                                <Row>
                                  <Typography
                                    msg={
                                      has_Will === true
                                        ? `Executor ${execs.length + 1}`
                                        : `Administrator ${execs.length + 1}`
                                    }
                                    tag="div"
                                    size="m"
                                    bold
                                  />
                                </Row>
                                <GridRow>
                                  <GridCol size={12}>
                                    <Field
                                      name={ExecutorFormFields.title}
                                      component={SelectInput}
                                      label="Title"
                                      options={titleOptions}
                                      required
                                      placeholder="--select--"
                                    />
                                  </GridCol>
                                </GridRow>
                                <GridRow>
                                  <GridCol size={12}>
                                    <Field
                                      name={ExecutorFormFields.firstName}
                                      type="text"
                                      component={TextInput}
                                      label="First name"
                                      placeholder="Enter first name(s)"
                                      // disabled={values.meExecutor}
                                      required
                                    />
                                  </GridCol>
                                </GridRow>
                                <GridRow>
                                  <GridCol size={12}>
                                    <Field
                                      name={ExecutorFormFields.lastName}
                                      type="text"
                                      component={TextInput}
                                      label="Last name"
                                      placeholder="Enter last name(s)"
                                      //disabled={values.meExecutor}
                                      required
                                    />
                                  </GridCol>
                                </GridRow>
                                <GridRow>
                                  <GridCol
                                    size={12}
                                    // className={styles.executorsFormFieldWrapper}
                                  >
                                    <Field
                                      name={ExecutorFormFields.email}
                                      type="text"
                                      component={TextInput}
                                      label="Email address"
                                      placeholder="Enter email address"
                                      // disabled={values.meExecutor}
                                      required
                                      onBlur={onBlur}
                                      onFocus={onFocus}
                                      onChange={(e: any) => {
                                        setFieldValue(ExecutorFormFields.email, e.target.value);
                                      }}
                                    />
                                  </GridCol>
                                </GridRow>
                                {showEmailCaution && (
                                  <GridRow>
                                    <GridCol
                                      size={12}
                                      // className={styles.executorsFormFieldWrapper}
                                    >
                                      <CautionBox
                                        containerClassName={styles.cautionBox}
                                        info={
                                          "We'll be sending an email to this individual, inviting them to make an account so they can see the information you've given."
                                        }
                                      />
                                    </GridCol>
                                  </GridRow>
                                )}
                                <GridRow>
                                  <GridCol
                                    size={12}
                                    // className={styles.executorsFormFieldWrapper}
                                  >
                                    <Field
                                      name={ExecutorFormFields.phone}
                                      type="text"
                                      component={TextInput}
                                      label="Phone number"
                                      placeholder="Enter phone number"
                                      required
                                    />
                                  </GridCol>
                                </GridRow>

                                <GridRow>
                                  <GridCol
                                    size={12}
                                    // className={styles.executorsFormFieldWrapper}
                                  >
                                    <Field
                                      name={ExecutorFormFields.permission}
                                      label="Level of access"
                                      component={SelectInput}
                                      options={PermissionOptions}
                                      selectClass={ClassNamesForOverwrite.SelectMenuPosition}
                                      placeholder="--select--"
                                      //  disabled={values.meExecutor}
                                      required
                                    />
                                  </GridCol>
                                </GridRow>

                                <Row justifyCenter className={styles.buttonGaps}>
                                  <Button
                                    type={ButtonTypes.submit}
                                    label="Add to case"
                                    // icon={`${iconLink}1YDAn2aUxTyrpMKwyRi8iClHuAi2CP702`}
                                    constant
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      handleSubmit();
                                    }}
                                  />
                                </Row>
                              </GrayPaper>

                              <FormError formError={status} />
                            </Form>
                          )}
                        </>
                      )}
                    </Formik>
                  </>
                  {parentValues[execGreyBox] === 'No' && (
                    <Row justifyCenter>
                      <Button
                        label="Add another"
                        // icon={`${iconLink}1QloRUFML54oV7cK_K36u_LvafA2_5r8f`}
                        style={ButtonStyles.transparent}
                        onClick={() => setFormVisible(true)}
                        constant
                      />
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    if (caseChildQuestionVisibility(isSingleIntended || isDoubleIntended, parentValues, item, parentQuestion)) {
      return (
        <>
          <div className={classNames(isSingleIntended ? styles.indentFlex : '')}>
            <div className={isSingleIntended ? styles.indentBar : ''} />
            <div>
              <div className={classNames(isDoubleIntended ? styles.indentFlex : '')}>
                <div className={isDoubleIntended ? styles.indentBar : ''} />
                <div>
                  <Field
                    name={item.question.id}
                    type={getControlType(item.question.inputType)}
                    component={getControl(item.question.inputType)}
                    label={item.question.questionText}
                    required={item.isIdentifier || item.isMandatory}
                    idx={idx}
                    idSelector={`date${idx}`}
                    placeholder={item.helpText}
                  />
                </div>
              </div>
            </div>
          </div>
          {item.detailedHelpText && helpTextHtml(item.detailedHelpText)}
        </>
      );
    }
    return <></>;
  };

  const cannotNotify = serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify;

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      borderBottom: 'unset',
      padding: '1px',
      marginTop: '-39px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderTop: 'unset !important',
      fontSize: '16px',
      paddingLeft: '32px !important',
      paddingBottom: '8px !important',
      paddingTop: 'unset !important',
      backgroundColor: state.isFocused ? '#DFDFDF' : '',
    }),
    groupHeading: (provided: any) => ({
      ...provided,
      color: '#252525',
      fontWeight: '700',
      paddingLeft: '16px',
      marginBottom: 'unset',
      textTransform: 'capitalize',
      fontSize: '16px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      marginLeft: '8px',
    }),
  };

  const mobileFormTitle = companyForm ? 'Select service provider' : 'Filter by category';
  const mobileFormSubtitle = companyForm
    ? 'Search manually for the company you need to notify'
    : 'Select the category that the company you want to notify belongs to';

  const slaInfo = cannotNotify && !isLifeCase && serviceProviderValues.data?.serviceProvider.userSlaInDays !== null;

  const caseQuestionsHtml = (values: any) => (
    <>
      {uniqueCustomTabNames.map((tabName: string) => {
        const filteredQuestions = sortedCaseQuestions.filter(
          (question) => question.customTabName === tabName && question.isActive
        );

        const hasSpecificQuestion = filteredQuestions.some((question) => question.customQuestionText === 'Will');

        const showField = () => {
          const hasWill = sortedCaseQuestions.find((swd: any) => swd.question.fieldName === 'has_will');

          if (tabName === TabFields.will && !hasWill) {
            return false;
          }

          if (tabName === TabFields.documents && filteredQuestions.length > 0) {
            return (
              filteredQuestions.length === 1 && hasWill && hasSpecificQuestion && values[hasWill.question.id] === 'Yes'
            );
          }
          return filteredQuestions.length > 0 && tabName !== TabFields.confirmAndSend;
        };
        return showField() ? (
          <div key={tabName}>
            <Typography msg={tabName} tag="div" size="xl" bold />
            <div className={styles.caseQuestionContainer}>
              {filteredQuestions.map((question: any, idx: number) => {
                return dynamicHtmlCaseQuestions(question, idx, values);
              })}
            </div>
          </div>
        ) : null;
      })}

      {uniqueCustomTabNames.map((tabName: string) => {
        const filteredQuestions = sortedCaseQuestions.filter(
          (question) => question.customTabName === tabName && question.isActive
        );

        return filteredQuestions.length > 0 && tabName === TabFields.confirmAndSend ? (
          <div key={tabName}>
            {filteredQuestions.map((question: any, idx: number) => {
              return dynamicHtmlCaseQuestions(question, idx, values);
            })}
          </div>
        ) : null;
      })}
    </>
  );

  return (
    <div className={styles.companyFormModal}>
      {!showSpInputFields && (
        <>
          {showCategoryListForm && (
            <Icon onClick={() => setShowCategoryListForm(false)} icon="chevronLeft" size={IconSizes.sm} />
          )}
          <Typography
            msg={showCategoryListForm ? mobileFormTitle : 'Search for a service provider'}
            tag="div"
            size="xl"
            bold
          />
          <Typography
            color="footerColor"
            msg={
              showCategoryListForm
                ? mobileFormSubtitle
                : 'Search for the service providers that you wish to send notifications to'
            }
            tag="div"
            size="m"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={newSchema}
        onSubmit={(values) => {
          const accountDefs: AccountDetailsInput[] = [];
          const accKeys = Object.keys(values)
            .splice(4)
            .filter((q: string) => q.length > 30);
          const fileField = sortedProviderControls.filter(
            (field: any) => field.inputType === AccountDataDefinitionInputType.FileUpload && field.isMandatory === true
          );
          if (fileField.length > 0 && uploadedFiles.length === 0) {
            dispatch(notificationError(t('msg_to_upload_document' as Messages)));
            setIsSubmitting(false);
          } else {
            sortedProviderControls
              .filter((x: any) => x.inputType !== AccountDataDefinitionInputType.FileUpload)
              .forEach((item: any) => {
                const userEntered = values[item.id];
                if (
                  userEntered !== undefined ||
                  (item.inputType === AccountDataDefinitionInputType.Checkbox &&
                    checkIfCheckboxControlSelected(accKeys))
                ) {
                  if (item.inputType === AccountDataDefinitionInputType.Checkbox) {
                    const indexArr: string[] = [];
                    accKeys.forEach((headers: string) => {
                      if (headers.startsWith('checkbox') && values[headers] === true) {
                        const checkBoxNumber = headers.split('_')[1];
                        indexArr.push(item.inputValues[checkBoxNumber]);
                      }
                    });

                    accountDefs.push({
                      key: item.inputLabel,
                      type: item.inputType,
                      value: indexArr.join(';'),
                      isPrimaryIdentifier: item.isPrimaryIdentifier,
                    });
                  } else {
                    let eachVal = String(userEntered);
                    eachVal = eachVal === '[object Object]' ? userEntered.value : eachVal;
                    accountDefs.push({
                      key: item.inputLabel,
                      type: item.inputType,
                      value: eachVal,
                      isPrimaryIdentifier: item.isPrimaryIdentifier,
                    });
                  }
                }
              });
            const accPrim = accountDefs.find((item) => item.isPrimaryIdentifier)?.value || '';
            const accountIdentifierPrimaryValue =
              serviceProviderInputType === ServiceProviderIdentifierType.Custom
                ? accPrim
                : values.accountIdentifierPrimary;

            const secondaryValue = values.accountIdentifierSecondary;

            const accountIdentifierSecondaryValue =
              serviceProviderInputType === ServiceProviderIdentifierType.Custom ? '' : secondaryValue;

            const caseKeys = Object.keys(values)
              .splice(4)
              .filter((q: string) => q.length < 30);

            const caseSubmitValuesArray = caseKeys.map((ck: string) => {
              const qText = sortedCaseQuestions.find((scq: any) => scq.question.id === ck)?.question.fieldName;
              const splitFieldCode = qText?.split('_');
              const capitalizedWords = splitFieldCode?.map((sfc: string, idx: number) => {
                return idx === 0 ? sfc : capitalize(sfc);
              });
              const graphqlKey = capitalizedWords?.join('') as keyof DeathCaseUpdateMutationInput;
              if (graphqlKey && deathCaseUpdateMutationInputKeys.includes(graphqlKey)) {
                return [graphqlKey, values[ck]];
              }
              return [];
            });

            const filteredArray = caseSubmitValuesArray.filter((el: any) => {
              return el.length > 0;
            });

            const caseSubmitValues: CaseSubmitValues = filteredArray.reduce(
              (acc, [key, value]) => ({ ...acc, [key]: value }),
              {}
            );

            if (filteredArray.length > 0) {
              updateDCMutation({
                variables: {
                  input: {
                    id,
                    ...caseSubmitValues,
                    isSmartModalUpdate: true,
                    prevBuildingName: addressOverThree ? values.prevBuildingName : undefined,
                    prevBuildingNumber: addressOverThree ? values.prevBuildingNumber : undefined,
                    previousAddress1: addressOverThree ? values.previousAddress1 : undefined,
                    previousAddress2: addressOverThree ? values.previousAddress2 : undefined,
                    previousCity: addressOverThree ? values.previousCity : undefined,
                    previousPostalCode: addressOverThree ? values.previousPostalCode : undefined,
                    isAlsoExecutor: caseSubmitValues.meExecutor === 'Yes' ? true : undefined,
                    isAlsoAdministrator: caseSubmitValues.meAdministrator === 'Yes' ? true : undefined,
                  },
                },
              });
            }

            if (caseServiceProvider) {
              setIsSubmitting(true);
              if (serviceProviderInputType === ServiceProviderIdentifierType.Custom) {
                const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
                accountDefs.forEach((a: any) => {
                  const identifyCaseAccount = accountData?.find((ad: any) => ad.key === a.key);
                  updateCaseAccountDetails({
                    variables: {
                      input: {
                        key: identifyCaseAccount ? identifyCaseAccount?.key : a.key,
                        value: a.value !== null && typeof a.value === 'object' ? a.value.value : a.value,
                        id: identifyCaseAccount ? identifyCaseAccount?.id : '',
                        caseId: caseServiceProvider.id,
                      },
                    },
                  });
                });
              }
              updateCaseProviderDCMutation({
                variables: {
                  input: {
                    id: caseServiceProvider.id,
                    accountIdentifierPrimary: accountIdentifierPrimaryValue,
                    accountIdentifierSecondary: accountIdentifierSecondaryValue,
                  },
                },
              });
            } else {
              setIsSubmitting(true);
              createCaseProviderDCMutation({
                variables: {
                  input: {
                    baseCase: id,
                    accountIdentifierPrimary: accountIdentifierPrimaryValue,
                    accountIdentifierSecondary: accountIdentifierSecondaryValue,
                    serviceProvider: values.provider.value !== '' ? values.provider.value : autoProviderValue,
                    accountDetails: accountDefs,
                    // files: filesToSubmit.length > 0 ? filesToSubmit : undefined,
                  },
                },
              });
            }

            if (uploadedWillFiles.length > 0) {
              uploadedWillFiles.forEach((file: any) => {
                uploadDocumentsMutation({
                  baseCaseId: id,
                  documentType: DocumentDocumentType.Will,
                  files: [file],
                });
              });
            }
          }
        }}
      >
        {({ status, setFieldValue, values, handleSubmit }) => {
          const handleSubCategoryChange = (item: any) => {
            getProvidersData({ variables: { subCategory: item.value } });
            if (values.provider) {
              setFieldValue(ProviderFormFields.provider, emptySelectorValue);
            }
            if (serviceProviderInputType) {
              serviceProviderValues.updateQuery(() => {
                return {
                  serviceProvider: null,
                } as any;
              });
            }
          };

          const handleProviderChange = (item: any) => {
            const identifierType = providersValues.data?.serviceProviders?.find((x) => x.id === item.value)
              ?.identifierType;
            getServiceProvider({ variables: { id: item.value } });
            if (identifierType === ServiceProviderIdentifierType.Custom) {
              getSpDcCustomFields({ variables: { baseCase: id, serviceProvider: item.value } });
            }
            setAutoProviderValue(item.value);
            setShowInputFields(true);
          };

          const spListCallback = (item: any, companySelected: boolean) => {
            if (companySelected) {
              setShowCategoryListForm(false);
              handleProviderChange(item);
              setFieldValue(ProviderFormFields.provider, item);
            } else {
              handleSubCategoryChange(item);
              setShowCategoryListForm(false);
              setCategorySelected(item);
            }
          };

          return (
            <>
              {!showCategoryListForm ? (
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {!showSpInputFields && (
                    <>
                      <GridRow>
                        <GridCol size={controlSize}>
                          <Field
                            name={ProviderFormFields.allProvider}
                            label={t(Messages.labelSearchProvider)}
                            component={AutoComplete}
                            onSelectClick={handleAutoProviderChange}
                            placeholder={t(Messages.labelProviderAutoComplete)}
                            disabled={caseServiceProvider}
                            showIcon={isMobile}
                          />
                        </GridCol>
                      </GridRow>
                      {showOrSection && (
                        <GridRow>
                          <GridCol size={controlSize}>
                            <Typography
                              className={styles.providerSubtitle}
                              tag="p"
                              size="m"
                              msg={t(Messages.labelChooseProviderOption)}
                            />
                          </GridCol>
                        </GridRow>
                      )}
                      {showCategorySection && !isMobile && (
                        <>
                          <GridRow>
                            <GridCol size={controlSize}>
                              <Field
                                name={ProviderFormFields.category}
                                label={t(Messages.labelCategory)}
                                component={SelectInput}
                                options={serviceProviderCategories}
                                onSelectChange={handleSubCategoryChange}
                                disabled={caseServiceProvider}
                                selectClass={ClassNamesForOverwrite.SelectServiceProvider}
                                customStyles={customStyles}
                                isSearchable={false}
                              />
                            </GridCol>
                          </GridRow>

                          {providersValues.loading && <LoadingSpinner />}
                          <GridRow>
                            <GridCol size={controlSize}>
                              <Field
                                name={ProviderFormFields.provider}
                                label={t(Messages.labelProvider)}
                                component={SelectInput}
                                options={serviceProviders}
                                disabled={!serviceProviders || caseServiceProvider}
                                onSelectChange={handleProviderChange}
                              />
                            </GridCol>
                          </GridRow>
                        </>
                      )}

                      {showCategorySection && isMobile && (
                        <>
                          {!categorySelected && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(false);
                              }}
                              className={styles.buttonGaps}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={t(Messages.labelCategory)} tag="div" size="l" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}

                          {categorySelected && <Typography msg={t(Messages.labelCategory)} tag="div" size="l" />}

                          {categorySelected && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(false);
                              }}
                              className={styles.spSelected}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={categorySelected.label} tag="div" size="m" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}

                          {providersValues.loading && <LoadingSpinner />}

                          {!serviceProviders && (
                            <Typography
                              msg={t(Messages.labelProvider)}
                              tag="div"
                              size="l"
                              className={styles.choosCompanyOpaque}
                            />
                          )}

                          {serviceProviders && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(true);
                              }}
                              className={styles.buttonGaps}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={t(Messages.labelProvider)} tag="div" size="l" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {serviceProviderInputType && showSpInputFields && (
                    <>
                      <Typography
                        bold
                        className={styles.providerTitle}
                        msg={
                          serviceProviderInputType === ServiceProviderIdentifierType.CantNotify
                            ? t("title_can't_notify" as Messages)
                            : t(Messages.titleProviderDetails)
                        }
                        tag="h3"
                      />
                      <Typography
                        className={styles.providerSubtitle}
                        tag="p"
                        size="m"
                        msg={
                          serviceProviderInputType === ServiceProviderIdentifierType.CantNotify
                            ? t("desc_can't_notify" as Messages)
                            : 'Please provide details of this account, we will send this information directly to the service provider'
                        }
                      />

                      <Row className={styles.profLogoCompany} alignCenter constant size={12}>
                        <Col>
                          {serviceProviderValues.data?.serviceProvider.logo ? (
                            <div className={styles.innerCircle}>
                              <img
                                className={styles.profLogo}
                                src={serviceProviderValues.data?.serviceProvider.logo}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className={styles.initialLogoContainer}>
                              <span>{serviceProviderValues.data?.serviceProvider.name.charAt(0).toUpperCase()}</span>
                            </div>
                          )}
                        </Col>
                        <Col>
                          <Typography
                            msg={serviceProviderValues.data?.serviceProvider.name || ''}
                            tag="div"
                            size="l"
                            bold
                          />
                        </Col>
                      </Row>

                      {slaInfo && (
                        <CautionBox
                          containerClassName={styles.cautionBox}
                          info={`Based on previous response times from this company, you should expect a response within ${serviceProviderValues.data?.serviceProvider.userSlaInDays} days of sending the notification.`}
                          title={t('label_info' as Messages)}
                        />
                      )}

                      {isLifeCase &&
                        serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                        sortedProviderControls.length > 0 &&
                        sortedProviderControls
                          .filter((m: any) => m.isMandatory || m.isPrimaryIdentifier)
                          .map((item: any) => {
                            return dynamicHtml(item, values);
                          })}

                      <Typography msg={t('title_smart_account_info' as Messages)} tag="div" size="xl" bold />
                      <div className={styles.caseQuestionContainer}>
                        {!isLifeCase &&
                          serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                          sortedProviderControls.length > 0 &&
                          sortedProviderControls.map((item: any) => {
                            return dynamicHtml(item, values);
                          })}
                      </div>

                      {serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                        sortedCaseQuestions.length > 0 &&
                        caseQuestionsHtml(values)}
                    </>
                  )}

                  {serviceProviderInputType === ServiceProviderIdentifierType.CantNotify && (
                    <div id={serviceProviderValues.data?.serviceProvider.name} className="cant_notify">
                      <Typography
                        msg={t('label_cant_notify_content' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                        html
                        tag="div"
                        size="l"
                      />
                      <Button
                        className={styles.sendButtonSpacing}
                        onClick={() => {
                          window.dataLayer.push({
                            event: "Can't notify event",
                            eventProps: {
                              category: 'Button',
                              action: 'Clicked',
                              label: "Go to company's website",
                              value: serviceProviderValues.data?.serviceProvider.name,
                            },
                          });
                          window.open(serviceProviderValues.data?.serviceProvider.webSite, '_blank');
                        }}
                        isFullWidth
                        label={t('button_go_to_company_website' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                      />
                      <Typography
                        size="m"
                        color="footerColor"
                        className={styles.spSelected}
                        msg={t('label_cant_notify_button_helper' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                        tag="div"
                        textCenter
                        html
                      />
                    </div>
                  )}
                  <FormError formError={status} />

                  {serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify && !isLifeCase && (
                    <>
                      {serviceProviderInputType && (
                        <GridRow className={styles.sendButtonSpacing}>
                          <GridCol size={12}>
                            <Button
                              label={t('button_send_notification' as Messages)}
                              size={ButtonSizes.fill}
                              type={ButtonTypes.submit}
                              iconSize={IconSizes.s}
                              constant
                              icon="paper-plane"
                              disabled={isSubmitting}
                              loading={isSubmitting || serviceProviderValues.loading}
                              onClick={() => {
                                setSubmitAction('send');
                                handleSubmit();
                              }}
                            />
                          </GridCol>
                        </GridRow>
                      )}
                    </>
                  )}
                  {serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify && (
                    <GridRow className={classNames(styles.buttonGaps, styles.pb40)}>
                      <GridCol size={12}>
                        {serviceProviderInputType && (
                          <Button
                            label={secondButtonText}
                            size={ButtonSizes.fill}
                            iconSize={IconSizes.s}
                            icon={isLifeCase ? '' : 'save'}
                            style={isLifeCase ? undefined : ButtonStyles.transparent}
                            type={ButtonTypes.submit}
                            disabled={isSubmitting}
                            constant
                            loading={isSubmitting || serviceProviderValues.loading}
                            onClick={() => {
                              setSubmitAction('draft');
                              handleSubmit();
                            }}
                          />
                        )}
                      </GridCol>
                    </GridRow>
                  )}
                  {!showSpInputFields && (
                    <div className={classNames(styles.actions, styles.pb40)}>
                      <Row constant alignCenter>
                        <Typography msg="Can't find your provider? " tag="div" size="m" />
                        &nbsp;
                        <Typography
                          className={styles.handSymbol}
                          msg="Let us know"
                          underline
                          tag="div"
                          size="m"
                          tabIndex={0}
                          bold
                          onClick={onProviderButtonClick}
                          onKeyDown={(e: any) => {
                            if (e.keyCode === EnterKeyCode) {
                              onProviderButtonClick();
                            }
                          }}
                        />
                      </Row>
                    </div>
                  )}
                </Form>
              ) : (
                <SpList
                  isCompanyForm={companyForm}
                  spCallback={spListCallback}
                  categorySelected={categorySelected}
                  options={serviceProviderCategories}
                />
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default SmartModalBody;
