import { ExecutionResult, getOperationName } from 'apollo-link';

import ApolloClient from '@Graphql/graphqlClient';
import {
  DeathCaseProviderAddReferenceCodeInput,
  DeathCaseProviderAddReferenceCodeMutation,
  DeathCaseProviderAddReferenceCodeMutationVariables,
  CaseProviderCreateMutation,
  CaseProviderCreateMutationInput,
  CaseProviderCreateMutationVariables,
  ServiceProviderRequestNewMutationVariables,
  ServiceProviderRequestNewInput,
  ServiceProviderRequestNewMutation,
  PortalUserDeleteMutationInput,
  PortalDeleteUserMutation,
  PortalDeleteUserMutationVariables,
  DeleteDataDefinitionMutationInput,
  DeleteDataDefinitionMutation,
  DeleteDataDefinitionMutationVariables,
  UserAccountType,
  UpdateNotificationEmailMutation,
  UpdateNotificationEmailMutationVariables,
  CaseProviderUpdateEmailMutationInput,
  CaseProviderUpdateMutationInput,
  CaseProviderUpdateMutation,
  CaseProviderUpdateMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationAddReferenceCode,
  mutationCaseProviderUpdate,
  mutationUpdateNotificationEmail,
} from '@Graphql/providers/mutations';
import { mutationDeleteDataDefinition, mutationPortalDeleteUser } from '@Graphql/serviceProvidersPortal/mutations';
import { queryProfessionalPortalUsers, queryServiceProviderPortalUsers } from '@Graphql/serviceProvidersPortal/queries';

import { mutationDeathCaseProviderCreate, mutationNewServiceProvider } from './mutations';
import { queryCaseServiceProvider, queryCaseServiceProviders, queryServiceProviderDataFields } from './queries';

export const sendReferenceCodeMutation = async (input: DeathCaseProviderAddReferenceCodeInput) => {
  const result: ExecutionResult<DeathCaseProviderAddReferenceCodeMutation> = await ApolloClient.mutate<
    DeathCaseProviderAddReferenceCodeMutation,
    DeathCaseProviderAddReferenceCodeMutationVariables
  >({
    mutation: mutationAddReferenceCode,
    variables: {
      input,
    },
  });

  return result.data?.deathCaseProviderAddReferenceCode;
};

export const createDCProviderMutation = async (input: CaseProviderCreateMutationInput) => {
  const result: ExecutionResult<CaseProviderCreateMutation> = await ApolloClient.mutate<
    CaseProviderCreateMutation,
    CaseProviderCreateMutationVariables
  >({
    mutation: mutationDeathCaseProviderCreate,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryCaseServiceProviders) || ''],
  });

  return result.data?.caseProviderCreate;
};

export const updateDCProviderMutation = async (input: CaseProviderUpdateMutationInput) => {
  const result: ExecutionResult<CaseProviderUpdateMutation> = await ApolloClient.mutate<
    CaseProviderUpdateMutation,
    CaseProviderUpdateMutationVariables
  >({
    mutation: mutationCaseProviderUpdate,
    variables: {
      input,
    },
    refetchQueries: [
      getOperationName(queryCaseServiceProviders) || '',
      getOperationName(queryCaseServiceProvider) || '',
    ],
  });

  return result.data?.caseProviderUpdate;
};

export const sendNewProviderMutation = async (input: ServiceProviderRequestNewInput) => {
  const result: ExecutionResult<ServiceProviderRequestNewMutation> = await ApolloClient.mutate<
    ServiceProviderRequestNewMutation,
    ServiceProviderRequestNewMutationVariables
  >({
    mutation: mutationNewServiceProvider,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryCaseServiceProviders) || ''],
  });

  return result.data?.serviceProviderRequestNew;
};

export const caseProviderUpdateMutation = async (input: CaseProviderUpdateEmailMutationInput) => {
  const result: ExecutionResult<UpdateNotificationEmailMutation> = await ApolloClient.mutate<
    UpdateNotificationEmailMutation,
    UpdateNotificationEmailMutationVariables
  >({
    mutation: mutationUpdateNotificationEmail,
    variables: {
      input,
    },
  });

  return result.data?.updateNotificationEmail;
};

export const removeUserInProviderMutation = async (
  input: PortalUserDeleteMutationInput,
  userAccountType: UserAccountType
) => {
  const result: ExecutionResult<PortalDeleteUserMutation> = await ApolloClient.mutate<
    PortalDeleteUserMutation,
    PortalDeleteUserMutationVariables
  >({
    mutation: mutationPortalDeleteUser,
    variables: {
      input,
    },
    refetchQueries: [
      getOperationName(
        userAccountType === UserAccountType.ServiceProvider
          ? queryServiceProviderPortalUsers
          : queryProfessionalPortalUsers
      ) || '',
    ],
  });

  return result.data?.portalDeleteUser;
};

export const removeDataDefinitionMutation = async (input: DeleteDataDefinitionMutationInput) => {
  const result: ExecutionResult<DeleteDataDefinitionMutation> = await ApolloClient.mutate<
    DeleteDataDefinitionMutation,
    DeleteDataDefinitionMutationVariables
  >({
    mutation: mutationDeleteDataDefinition,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryServiceProviderDataFields) || ''],
  });

  return result.data?.deleteDataDefinition;
};
