import classNames from 'classnames';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FileView from '@Components/application/FileView';
import Paper from '@Components/application/GrayPaper/';
import CardTitle from '@Components/CardTitle';
import DataWithLabel from '@Components/DataWithLabel';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LinkButton from '@Components/LinkButton';
import { Messages } from '@Config/messages';
import {
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
  useNotifierIdDocumentsQuery,
  UserDocumentsNode,
  UserNode,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { languageBasedDate } from '@Utils/dates';
import { somethingWrongHandler } from '@Utils/modal';

import styles from '../SummaryInfoDeceased/SummaryInfoDeceased.scss';

export interface SummaryInfoDeceasedProps {
  deathCase: RestrictedDeathCaseNode;
  userAccountType?: string;
  deathCaseServiceProvider?: RestrictedCaseServiceProviderNode;
  accessCode?: string;
  user?: UserNode | null;
  setActiveTabCallback?: () => void;
}

const SummaryInfoNotifier: React.FunctionComponent<SummaryInfoDeceasedProps> = ({
  deathCase,
  userAccountType,
  deathCaseServiceProvider,
  accessCode = '',
}) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const enableTuCheck = useSelector(getEnableTuCheck);

  const notifierIdDocuments = useNotifierIdDocumentsQuery({
    variables: {
      caseServiceProvider: deathCaseServiceProvider ? deathCaseServiceProvider?.id : '',
    },
    fetchPolicy: 'network-only',
    skip: !deathCaseServiceProvider?.serviceProvider.showNotifierIdDocuments,
  });

  return (
    <Paper
      width="width-full"
      className={classNames(
        userAccountType ? styles.professionalPaper : styles.normalGrayPaper,
        styles.sameHeight,
        styles.flexBox
      )}
    >
      <div className={styles.deceasedContainer}>
        {deathCaseServiceProvider && (
          <Row justifyEnd constant>
            <LinkButton
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode);
                }
              }}
              linkTitle="Something wrong?"
              onClick={() => somethingWrongHandler(dispatch, deathCaseServiceProvider, accessCode)}
            />
          </Row>
        )}
      </div>
      {(deathCaseServiceProvider?.serviceProvider.isUserValidationRequired || enableTuCheck) && (
        <>
          <CardTitle
            heading="Notifier ID Verification"
            description="This notifier has had their identification verified"
          />

          <Row constant columnMobile>
            <Col>
              <DataWithLabel data="TransUnion " label={t(Messages.labelVerifiedBy)} dashForEmpty />
            </Col>
            <Col className={styles.tickContainer}>
              <Icon icon="doneAll" />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataWithLabel
                data={
                  deathCase.notifier.verifiedAt !== 'None'
                    ? languageBasedDate(deathCase.notifier.verifiedAt, currentLanguage)
                    : '-'
                }
                label={t(Messages.labelVerifiedAt)}
                dashForEmpty
              />
            </Col>
          </Row>
        </>
      )}

      {notifierIdDocuments.data?.notifierIdDocuments.map((fileDetails: UserDocumentsNode, idx: number) => (
        <>
          <CardTitle heading={t('title_notifier_id_document' as Messages)} />
          <div key={idx} className={styles.mb8}>
            <FileView key={idx} fileDetails={fileDetails} isGray isNotificationPage containerClassName={styles.p18} />
          </div>
        </>
      ))}

      {/* {user?.isStaff && hasNotifierDocs && (
        <Row className={styles.requestIdDcoument}>
          <Button
            label="View ID document"
            size={ButtonSizes.fill}
            style={ButtonStyles.transparent}
            onClick={setActiveTabCallback}
          />
        </Row>
      )} */}
    </Paper>
  );
};

export default SummaryInfoNotifier;
