import * as React from 'react';

import BillingCard from '@Components/application/BillingCard';
import { PaymentStatus } from '@Components/application/BillingCard/BillingCard';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { SubscriptionPaymentPaymentStatus, useUserSubscriptionPaymentsQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

//import { SubscriptionPaymentNode, useUserSubscriptionPaymentsQuery } from '@Graphql/graphqlTypes.generated';
import LcSubscriptionCard from './LcSubscriptionCard';
import styles from './PaymentsPage.scss';

export interface PaymentsPageProps {}

enum BillingTabs {
  TabLifePlans,
  TabPaymentHistory,
}

const PaymentsPage: React.FunctionComponent<PaymentsPageProps> = () => {
  const t = useTranslations();

  const [activeTab, setActiveTab] = React.useState(BillingTabs.TabLifePlans);

  const { data, loading } = useUserSubscriptionPaymentsQuery({
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const billingTabs = [{ title: t('label_ral_plans' as Messages) }, { title: t('label_payment_history' as Messages) }];

  return (
    <>
      <div className={styles.headerContainer}>
        <Typography bold tag="h3" size="xxl" msg={t('msg_title_settings_subitem_billing' as Messages)} />
        <div className={styles.tabPlacement}>
          <Tabs tabs={billingTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
      </div>
      <div className={styles.tabBody}>
        {activeTab === BillingTabs.TabLifePlans && (
          <div className={styles.ralWidth}>
            <Typography color="footerColor" size="l" msg={t('info_ral_registered' as Messages)} tag="div" />
            {data?.userSubscriptionPayments
              .sort((x: any) => x.createdAt)
              .filter((p: any) => p.paymentStatus === SubscriptionPaymentPaymentStatus.Paid)
              .map((usp: any, idx: number) => {
                return <LcSubscriptionCard userSubscription={usp} key={idx} />;
              })}
            <Typography
              className={styles.mt4}
              msg={t('info_payment_renewal' as Messages)}
              color="footerColor"
              tag="div"
              size="s"
            />
          </div>
        )}

        {activeTab === BillingTabs.TabPaymentHistory && (
          <div className={styles.historyWidth}>
            {/* <Row justifyBetween>
              <Col>
                <Typography msg={t('label_download_statement' as Messages)} tag="span" size="l" />
                <Icon icon="download" size={IconSizes.s24} className={styles.downloadIcon} />
              </Col>

              <Col>
                <Typography
                  className={styles.periodContainer}
                  msg={t('label_period' as Messages)}
                  tag="span"
                  size="l"
                />
                <Typography msg="March 2022" tag="span" size="l" bold />
              </Col>
            </Row> */}
            {data?.userSubscriptionPayments.map((us: any, idx: number) => {
              // const lifeCase = lifeCaseData.data?.lifeCases.edges.find(
              //   (lc: any) => lc.id === us.subscription.baseCase.id
              // );
              return (
                <BillingCard
                  // lifeCase={lifeCase?.node as LifeCaseNode}
                  userSubscription={us}
                  paymentStatus={PaymentStatus.Paid}
                  key={idx}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentsPage;
