import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory } from 'react-router';
import * as Yup from 'yup';

import CautionBox from '@Components/application/CautionBox';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Button';
import AutoComplete from '@Components/form/inputs/AutoComplete';
import Checkbox from '@Components/form/inputs/Checkbox';
import { CheckBoxTypes } from '@Components/form/inputs/Checkbox/Checkbox';
import DatePickerInput from '@Components/form/inputs/DatePickerInput';
import SelectInput from '@Components/form/inputs/SelectInput';
import TextInput from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import Typography from '@Components/Typography';
import { emptySelectorValue, EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { queryTotalNotificationsByStatus } from '@Graphql/dc/queries';
import {
  ServiceProviderIdentifierType,
  useServiceProviderCategoriesQuery,
  useServiceProviderLazyQuery,
  useServiceProvidersLazyQuery,
  useServiceProviderSubCategoriesLazyQuery,
  AccountDataDefinitionInputType,
  AccountDetailsInput,
  useServiceProviderDataFieldsLazyQuery,
  CaseServiceProviderFragment,
  useCaseAccountDetailsLazyQuery,
  UpdateCaseAccountDetailsMutation,
  UpdateCaseAccountDetailsMutationVariables,
  UserVerificationStatus,
  CaseProviderCreateMutation,
  CaseProviderCreateMutationVariables,
  CaseProviderUpdateMutation,
  CaseProviderUpdateMutationVariables,
  UserAccountType,
} from '@Graphql/graphqlTypes.generated';
import {
  mutationCaseProviderUpdate,
  mutationDeathCaseProviderCreate,
  mutationUpdateCaseAccountDetails,
} from '@Graphql/providers/mutations';
import { queryCaseServiceProviders } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import SpList from '@Routes/provider/SpList';
import { getUser } from '@Store/auth/auth.selector';
import { sendOne } from '@Store/dc/dc.actions';
import { normaliseGqlError } from '@Utils/form';
import { LooseObject, noop } from '@Utils/helpers';
import { idVerfiyfileUploadHandler, newProviderHandler } from '@Utils/modal';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';
import {
  validateAny,
  validateBankAccount,
  validateEmail,
  validatePhone,
  validateRequired,
  validateSelector,
  validateSortCode,
} from '@Utils/validator';

import { ModalProviderProps } from '../Modal';

import styles from './ProviderFormModalBody.scss';

export interface ProviderFormModalBodyProps {
  onClose?: () => void;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction: () => void;
  id: string;
  isLifeCase?: boolean;
  caseServiceProvider?: CaseServiceProviderFragment;
}

export enum ProviderFormFields {
  category = 'category',
  subCategory = 'subCategory',
  provider = 'provider',
  providerRadioControl = 'providerRadioControl',
  accountIdentifierPrimary = 'accountIdentifierPrimary',
  accountIdentifierSecondary = 'accountIdentifierSecondary',
  allProvider = 'allProvider',
}

export enum ProviderBankFieldLabels {
  accountNumber = 'account_number',
  sortCode = 'sort_code',
}

const ProviderFormModalBody: React.FunctionComponent<ModalProviderProps<ProviderFormModalBodyProps>> = ({
  modalData: { onClose = noop, secondButtonText, id, isLifeCase = false, caseServiceProvider },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const history = useHistory();

  const agentUser = user?.accountType === UserAccountType.Agent;

  const [showCategorySection, setShowCategorySection] = React.useState(true);
  const [showOrSection, setShowOrSection] = React.useState(true);

  const [autoProviderValue, setAutoProviderValue] = React.useState('');
  // const [uploadedFiles, setUploadedFiles] = React.useState<DocumentNode[]>([]);

  // const [filesToSubmit, setFilesToSubmit] = React.useState<File[]>([]);

  const [submitAction, setSubmitAction] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [showSpInputFields, setShowInputFields] = React.useState(caseServiceProvider !== undefined);
  const [showCategoryListForm, setShowCategoryListForm] = React.useState(false);
  const [categorySelected, setCategorySelected] = React.useState<{ label: string; value: string } | undefined>(
    undefined
  );

  const [companyForm, setCompanyForm] = React.useState(false);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  // const [updateDocumentsMutation] = useMutation<UploadDocumentMutation, any>(mutationUploadDocuments, {
  //   onCompleted: (output: any) => {
  //     dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
  //     const newDocument = uploadedFiles.concat(output.uploadDocument.document);
  //     setUploadedFiles(newDocument);
  //   },
  //   onError: (apiError: any) => {
  //     dispatch(notificationError(normaliseGqlError(apiError.message)));
  //   },
  // });

  const onProviderButtonClick = () => {
    onClose();
    closeModal();
    newProviderHandler(t, id, dispatch);
  };

  const categoryValues = useServiceProviderCategoriesQuery({
    fetchPolicy: 'network-only',
  });

  const [getSubCategoryData, subCategoriesValues] = useServiceProviderSubCategoriesLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getProvidersData, providersValues] = useServiceProvidersLazyQuery({
    fetchPolicy: 'cache-and-network',
    partialRefetch: true,
  });

  const [getServiceProvider, serviceProviderValues] = useServiceProviderLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getAccountDataDefinitions, accountDataDefinitionsValues] = useServiceProviderDataFieldsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [getCaseAccountDetailsValues, caseAccountDetailsValues] = useCaseAccountDetailsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
  });

  const [updateCaseAccountDetails] = useMutation<
    UpdateCaseAccountDetailsMutation,
    UpdateCaseAccountDetailsMutationVariables
  >(mutationUpdateCaseAccountDetails, {
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const [createCaseProviderDCMutation] = useMutation<CaseProviderCreateMutation, CaseProviderCreateMutationVariables>(
    mutationDeathCaseProviderCreate,
    {
      refetchQueries: [
        getOperationName(queryCaseServiceProviders) || '',
        getOperationName(queryTotalNotificationsByStatus) || '',
      ],
      onCompleted: (op: any) => {
        if (submitAction === 'send') {
          if (user?.verificationStatus === UserVerificationStatus.Verified) {
            dispatch(sendOne.started({ deathCaseProviderId: op.caseProviderCreate.caseServiceProvider.id }));
            closeModal();
          } else {
            idVerfiyfileUploadHandler(dispatch, op.caseProviderCreate.caseServiceProvider.id, submitAction);
          }
        } else {
          const toastMsg = isLifeCase ? 'Company added successfully' : `Company added to 'Ready to send'`;
          dispatch(notificationSuccess(toastMsg));
          closeModal();
        }
        setIsSubmitting(false);
        history.push(generatePath(isLifeCase ? Links.lcReady : Links.dcReady, { id }));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
        setIsSubmitting(false);
      },
    }
  );

  const [updateCaseProviderDCMutation] = useMutation<CaseProviderUpdateMutation, CaseProviderUpdateMutationVariables>(
    mutationCaseProviderUpdate,
    {
      refetchQueries: [
        getOperationName(queryCaseServiceProviders) || '',
        getOperationName(queryTotalNotificationsByStatus) || '',
      ],
      onCompleted: () => {
        if (submitAction === 'send') {
          if (user?.verificationStatus === UserVerificationStatus.Verified) {
            dispatch(sendOne.started({ deathCaseProviderId: caseServiceProvider ? caseServiceProvider?.id : '' }));
            closeModal();
          } else {
            idVerfiyfileUploadHandler(dispatch, caseServiceProvider?.id);
          }
        } else {
          closeModal();
        }
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  React.useEffect(() => {
    if (caseServiceProvider) {
      getSubCategoryData({
        variables: { parentCategory: caseServiceProvider.serviceProvider.subCategory.parentCategory.id },
      });
      getProvidersData({ variables: { subCategory: caseServiceProvider.serviceProvider.subCategory.id } });
      getServiceProvider({ variables: { id: caseServiceProvider.serviceProvider.id } });
      getAccountDataDefinitions({ variables: { serviceProvider: caseServiceProvider.serviceProvider.id } });
      getCaseAccountDetailsValues({
        variables: {
          caseServiceProvider: caseServiceProvider.id,
        },
      });
    }
    const paymentParam = history.location.state ? history.location.state : '';
    if (paymentParam === 'payment_success') {
      history.replace({
        state: null,
      });
    }
  }, [
    caseServiceProvider,
    getSubCategoryData,
    getProvidersData,
    getServiceProvider,
    getAccountDataDefinitions,
    getCaseAccountDetailsValues,
    history,
  ]);

  if (categoryValues.loading) {
    return <LoadingSpinner />;
  }

  function getControl(fieldType: string) {
    let controlName = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
      case AccountDataDefinitionInputType.Textarea:
        controlName = TextInput;
        break;
      case AccountDataDefinitionInputType.Dropdown:
        controlName = SelectInput;
        break;
      case AccountDataDefinitionInputType.Date:
        controlName = DatePickerInput;
        break;
      case AccountDataDefinitionInputType.Checkbox:
      case AccountDataDefinitionInputType.Radiobutton:
        controlName = Checkbox;
        break;
      default:
        break;
    }
    return controlName;
  }

  function getControlType(fieldType: string) {
    let controlType = null;

    switch (fieldType) {
      case AccountDataDefinitionInputType.Textbox:
        controlType = 'text';
        break;
      case AccountDataDefinitionInputType.Checkbox:
        controlType = CheckBoxTypes.checkbox;
        break;
      case AccountDataDefinitionInputType.Textarea:
        controlType = 'textarea';
        break;
      case AccountDataDefinitionInputType.Radiobutton:
        controlType = CheckBoxTypes.radio;
        break;
      default:
        break;
    }
    return controlType;
  }

  const dataForSelect = (queryData: any) =>
    queryData?.map((item: any) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const dataForDynamicSelect = (queryData: any) =>
    queryData?.map((item: any) => {
      return {
        value: item,
        label: item,
      };
    });

  const serviceProviderCategories = categoryValues.data?.serviceProviderCategories.map((mc: any) => {
    return {
      value: '',
      label: mc.name,
      options: mc.subCategories.map((sbc: any) => {
        return {
          value: sbc.id,
          label: sbc.name,
        };
      }),
    };
  });

  const serviceProviderSubCategories = dataForSelect(subCategoriesValues.data?.serviceProviderSubCategories);
  const serviceProviders = dataForSelect(providersValues.data?.serviceProviders);
  const serviceProviderInputType = serviceProviderValues.data?.serviceProvider?.identifierType;

  const categoryInitialValue =
    // caseServiceProvider
    //   ? serviceProviderCategories.find((c: any) => c.value === parentCategoryId):
    emptySelectorValue;
  const subCategoryInitialValue =
    caseServiceProvider && serviceProviderSubCategories
      ? serviceProviderSubCategories.find((s: any) => s.value === caseServiceProvider.serviceProvider.subCategory.id)
      : emptySelectorValue;
  const providerInitialValue =
    caseServiceProvider && serviceProviders
      ? serviceProviders.find((p: any) => p.value === caseServiceProvider.serviceProvider.id)
      : emptySelectorValue;

  const getExtraControls = () => {
    return accountDataDefinitionsValues.data?.serviceProviderDataFields
      ? accountDataDefinitionsValues.data?.serviceProviderDataFields.sort(
          (a: any, b: any) =>
            a.displayOrder - b.displayOrder ||
            b.isPrimaryIdentifier - a.isPrimaryIdentifier ||
            b.isMandatory - a.isMandatory
        )
      : [];
  };

  const sortedProviderControls = getExtraControls();

  const validatePrimaryAccountIdentifier = (): Yup.Schema<string> => {
    if (serviceProviderInputType === ServiceProviderIdentifierType.Email) {
      return validateEmail(t);
    }
    if (serviceProviderInputType === ServiceProviderIdentifierType.Phone) {
      return validatePhone(t);
    }
    if (serviceProviderInputType === ServiceProviderIdentifierType.Bank) {
      return validateBankAccount(t);
    }
    return validateRequired(t);
  };

  const validateCustomPrimaryField = (inputType: string): Yup.Schema<string> => {
    if (inputType === AccountDataDefinitionInputType.Dropdown) {
      return validateSelector(t);
    }
    // if (inputType === AccountDataDefinitionInputType.File) {
    //   return validateAny();
    // }
    return validateRequired(t);
  };

  const handleAutoProviderChange = (item: any) => {
    getServiceProvider({ variables: { id: item } });
    getAccountDataDefinitions({ variables: { serviceProvider: item } });
    setShowCategorySection(false);
    setShowOrSection(false);
    setAutoProviderValue(item);
    setShowInputFields(true);
  };

  let schemaObj: LooseObject = {};
  schemaObj = {
    [ProviderFormFields.category]: showCategorySection ? validateSelector(t) : validateAny(),
    [ProviderFormFields.subCategory]: showCategorySection ? validateSelector(t) : validateAny(),
    [ProviderFormFields.provider]: showCategorySection ? validateSelector(t) : validateAny(),
    [ProviderFormFields.accountIdentifierPrimary]:
      serviceProviderInputType && serviceProviderInputType !== ServiceProviderIdentifierType.Custom
        ? validatePrimaryAccountIdentifier()
        : validateAny(),
    [ProviderFormFields.accountIdentifierSecondary]:
      serviceProviderInputType === ServiceProviderIdentifierType.Bank ? validateSortCode(t) : validateAny(),
  };

  const reqAccountDataDefinitions = sortedProviderControls.filter(
    (control: any) => control.isPrimaryIdentifier === true || control.isMandatory === true
  );

  reqAccountDataDefinitions.forEach((f: any) => {
    schemaObj[`PrimaryField_${f.id}`] = validateCustomPrimaryField(f.inputType);
  });
  const newSchema = Yup.object(schemaObj);

  let initialValues: LooseObject = {};
  initialValues = {
    [ProviderFormFields.category]: categoryInitialValue,
    [ProviderFormFields.subCategory]: subCategoryInitialValue,
    [ProviderFormFields.provider]: providerInitialValue,
    [ProviderFormFields.allProvider]: '',
    [ProviderFormFields.accountIdentifierPrimary]: caseServiceProvider?.accountIdentifierPrimary || '',
    [ProviderFormFields.accountIdentifierSecondary]: caseServiceProvider?.accountIdentifierSecondary || '',
  };

  const setAccountInputValues = (f: any) => {
    const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
    const currentControl = accountData?.find((x: any) => f.inputLabel === x.key);
    if (f.inputType === AccountDataDefinitionInputType.Dropdown) {
      const dropdownValues = dataForDynamicSelect(f.inputValues);
      const dropdownValue = dropdownValues.find((d: any) => d.value === currentControl?.value);
      return dropdownValue;
    }
    return currentControl?.value;
  };

  sortedProviderControls.forEach((f: any) => {
    if (f.inputType === AccountDataDefinitionInputType.Checkbox) {
      const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
      const currentControl = accountData?.find((x: any) => f.inputLabel === x.key);
      if (currentControl) {
        const definedArray = currentControl.value.split(';');
        //eslint-disable-next-line
        f.inputValues.map((iv: string, indexPos: number) => {
          initialValues[`checkbox_${indexPos}_PrimaryField_${f.id}`] = definedArray.includes(iv);
        });
      }
    } else {
      initialValues[`PrimaryField_${f.id}`] = setAccountInputValues(f);
    }
  });

  const checkIfCheckboxControlSelected = (accKeys: string[]) => {
    const isPresent: boolean[] = [];
    accKeys.forEach((headers: string) => {
      if (headers.startsWith('checkbox')) {
        isPresent.push(true);
      } else {
        isPresent.push(false);
      }
    });
    if (isPresent.includes(true)) {
      return true;
    }

    return false;
  };

  const nonCustomNAIdentifierType = () => {
    if (
      serviceProviderInputType === ServiceProviderIdentifierType.Custom ||
      serviceProviderInputType === ServiceProviderIdentifierType.CantNotify
    ) {
      return false;
    }
    return true;
  };

  // const removeCertificateHandler = (fileId: string, index: number) => {
  //   dispatch(removeDocuments.started({ id: fileId, index, documentType: DocumentDocumentType.Certificate }));
  //   const fileNameToBeDeleted = uploadedFiles.find((doc: DocumentNode) => doc.id === fileId)?.fileName;
  //   const existingUploadedDocs = uploadedFiles.filter((doc: DocumentNode) => doc.id !== fileId);
  //   const exisFileDocs = filesToSubmit.filter((doc: File) => doc.name !== fileNameToBeDeleted);
  //   setUploadedFiles(existingUploadedDocs);
  //   setFilesToSubmit(exisFileDocs);
  // };

  const controlSize = isMobile ? 12 : 10;

  const dynamicHtml = (item: any, idx: number) => {
    if (item.inputType === AccountDataDefinitionInputType.Dropdown) {
      return (
        <GridRow>
          <GridCol size={controlSize}>
            <Field
              name={`PrimaryField_${item.id}`}
              type={getControlType(item.inputType)}
              component={getControl(item.inputType)}
              label={item.inputLabel}
              options={dataForDynamicSelect(item.inputValues)}
              required={item.isMandatory || item.isPrimaryIdentifier}
            />
          </GridCol>
        </GridRow>
      );
    }
    if (item.inputType === AccountDataDefinitionInputType.Radiobutton) {
      return (
        <>
          <GridRow className={styles.buttonGaps}>
            <GridCol size={controlSize}>
              <Typography msg={item.inputLabel} tag="div" size="l" />
            </GridCol>
          </GridRow>
          {item.inputValues.map((inpVal: any, index: number) => (
            <GridRow key={`div_${index}`}>
              <GridCol size={12}>
                <Field
                  name={`PrimaryField_${item.id}`}
                  type={getControlType(item.inputType)}
                  component={getControl(item.inputType)}
                  value={inpVal}
                  label={inpVal}
                  required={item.isMandatory || item.isPrimaryIdentifier}
                />
              </GridCol>
            </GridRow>
          ))}
        </>
      );
    }

    // if (item.inputType === AccountDataDefinitionInputType.File) {
    //   return (
    //     <>
    //       <Typography
    //         msg={`${item.inputLabel}${item.isMandatory || item.isPrimaryIdentifier ? '*' : ''}`}
    //         tag="span"
    //         size="l"
    //       />
    //       <GridRow>
    //         <GridCol size={controlSize}>
    //           <FileInput
    //             onDrop={(files: File[]) => {
    //               setFilesToSubmit(files);
    //               updateDocumentsMutation({
    //                 variables: {
    //                   input: {
    //                     baseCaseId: id,
    //                     documentType: DocumentDocumentType.Other,
    //                     files,
    //                   },
    //                 },
    //               });
    //             }}
    //             files={uploadedFiles}
    //             fileNameCenter={true}
    //             onRemoveClick={removeCertificateHandler}
    //           />
    //         </GridCol>
    //       </GridRow>
    //     </>
    //   );
    // }

    if (item.inputType === AccountDataDefinitionInputType.Checkbox) {
      return (
        <>
          <GridRow className={styles.buttonGaps}>
            <GridCol size={controlSize}>
              <Typography msg={item.inputLabel} tag="span" size="l" />
              {item.isMandatory || (item.isPrimaryIdentifier && <Typography msg="*" tag="span" size="l" />)}
            </GridCol>
          </GridRow>
          {item.inputValues.map((inpVal: any, index: number) => (
            <GridRow key={`div_${index}`}>
              <GridCol size={12}>
                <Field
                  name={`checkbox_${index}_PrimaryField_${item.id}`}
                  type={getControlType(item.inputType)}
                  component={getControl(item.inputType)}
                  label={inpVal}
                />
              </GridCol>
            </GridRow>
          ))}
        </>
      );
    }

    if (item.inputType === AccountDataDefinitionInputType.Date) {
      return (
        <GridRow key="idx">
          <GridCol size={controlSize}>
            <Field
              name={`PrimaryField_${item.id}`}
              // type={getControlType(item.inputType)}
              component={DatePickerInput}
              label={item.inputLabel}
              idSelector={`date${idx}`}
              required={item.isPrimaryIdentifier || item.isMandatory}
            />
          </GridCol>
        </GridRow>
      );
    }

    return (
      <GridRow key="idx">
        <GridCol size={controlSize}>
          <Field
            name={`PrimaryField_${item.id}`}
            type={getControlType(item.inputType)}
            component={getControl(item.inputType)}
            required={item.isPrimaryIdentifier || item.isMandatory}
            label={item.inputLabel}
            infoMessage={
              item.inputLabel.toLowerCase().includes('additional comments')
                ? t('tooltip_desc_additional_comments' as Messages)
                : undefined
            }
            infoTitle={
              item.inputLabel.toLowerCase().includes('additional comments')
                ? t('tooltip_title_additional_comments' as Messages)
                : undefined
            }
          />
        </GridCol>
      </GridRow>
    );
  };

  const cannotNotify = serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify;

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      borderBottom: 'unset',
      padding: '1px',
      marginTop: '-39px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderTop: 'unset !important',
      fontSize: '16px',
      paddingLeft: '32px !important',
      paddingBottom: '8px !important',
      paddingTop: 'unset !important',
      backgroundColor: state.isFocused ? '#DFDFDF' : '',
    }),
    groupHeading: (provided: any) => ({
      ...provided,
      color: '#252525',
      fontWeight: '700',
      paddingLeft: '16px',
      marginBottom: 'unset',
      textTransform: 'capitalize',
      fontSize: '16px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      marginLeft: '8px',
    }),
  };

  const mobileFormTitle = companyForm ? 'Select service provider' : 'Filter by category';
  const mobileFormSubtitle = companyForm
    ? 'Search manually for the company you need to notify'
    : 'Select the category that the company you want to notify belongs to';

  const slaInfo = cannotNotify && !isLifeCase && serviceProviderValues.data?.serviceProvider.userSlaInDays !== null;

  return (
    <div className={styles.companyFormModal}>
      {!showSpInputFields && (
        <>
          {showCategoryListForm && (
            <Icon onClick={() => setShowCategoryListForm(false)} icon="chevronLeft" size={IconSizes.sm} />
          )}
          <Typography
            msg={showCategoryListForm ? mobileFormTitle : 'Search for a service provider'}
            tag="div"
            size="xl"
            bold
          />
          <Typography
            color="footerColor"
            msg={
              showCategoryListForm
                ? mobileFormSubtitle
                : 'Search for the service providers that you wish to send notifications to'
            }
            tag="div"
            size="m"
          />
        </>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={newSchema}
        onSubmit={(values) => {
          const accountDefs: AccountDetailsInput[] = [];
          const accKeys =
            serviceProviderInputType === ServiceProviderIdentifierType.Custom
              ? Object.keys(values).splice(4)
              : Object.keys(values);

          // const fileField = sortedProviderControls.filter(
          //   (field: any) => field.inputType === AccountDataDefinitionInputType.File && field.isMandatory === true
          // );
          // if (fileField.length > 0 && uploadedFiles.length === 0) {
          //   dispatch(notificationError(t('msg_to_upload_document' as Messages)));
          //   setSubmitting(false);
          // } else {
          sortedProviderControls
            //.filter((x: any) => x.inputType !== AccountDataDefinitionInputType.File)
            .forEach((item: any) => {
              const userEntered = values[`PrimaryField_${item.id}`];
              if (
                userEntered !== undefined ||
                (item.inputType === AccountDataDefinitionInputType.Checkbox && checkIfCheckboxControlSelected(accKeys))
              ) {
                if (item.inputType === AccountDataDefinitionInputType.Checkbox) {
                  const indexArr: string[] = [];
                  accKeys.forEach((headers: string) => {
                    if (headers.startsWith('checkbox') && values[headers] === true) {
                      const checkBoxNumber = headers.split('_')[1];
                      indexArr.push(item.inputValues[checkBoxNumber]);
                    }
                  });

                  accountDefs.push({
                    key: item.inputLabel,
                    type: item.inputType,
                    value: indexArr.join(';'),
                    isPrimaryIdentifier: item.isPrimaryIdentifier,
                  });
                } else {
                  let eachVal = String(userEntered);
                  eachVal = eachVal === '[object Object]' ? userEntered.value : eachVal;
                  accountDefs.push({
                    key: item.inputLabel,
                    type: item.inputType,
                    value: eachVal,
                    isPrimaryIdentifier: item.isPrimaryIdentifier,
                  });
                }
              }
            });
          const accPrim = accountDefs.find((item) => item.isPrimaryIdentifier)?.value || '';
          const accountIdentifierPrimaryValue =
            serviceProviderInputType === ServiceProviderIdentifierType.Custom
              ? accPrim
              : values.accountIdentifierPrimary;

          const secondaryValue = values.accountIdentifierSecondary;

          const accountIdentifierSecondaryValue =
            serviceProviderInputType === ServiceProviderIdentifierType.Custom ? '' : secondaryValue;

          if (caseServiceProvider) {
            setIsSubmitting(true);
            if (serviceProviderInputType === ServiceProviderIdentifierType.Custom) {
              const accountData = caseAccountDetailsValues.data?.caseAccountDetails;
              accountDefs.forEach((a: any) => {
                const identifyCaseAccount = accountData?.find((ad: any) => ad.key === a.key);
                updateCaseAccountDetails({
                  variables: {
                    input: {
                      key: identifyCaseAccount ? identifyCaseAccount?.key : a.key,
                      value: a.value,
                      id: identifyCaseAccount ? identifyCaseAccount?.id : '',
                      caseId: caseServiceProvider.id,
                    },
                  },
                });
              });
            }
            updateCaseProviderDCMutation({
              variables: {
                input: {
                  id: caseServiceProvider.id,
                  accountIdentifierPrimary: accountIdentifierPrimaryValue,
                  accountIdentifierSecondary: accountIdentifierSecondaryValue,
                },
              },
            });
          } else {
            setIsSubmitting(true);
            createCaseProviderDCMutation({
              variables: {
                input: {
                  baseCase: id,
                  accountIdentifierPrimary: accountIdentifierPrimaryValue,
                  accountIdentifierSecondary: accountIdentifierSecondaryValue,
                  serviceProvider: values.provider.value !== '' ? values.provider.value : autoProviderValue,
                  accountDetails: accountDefs,
                  // files: filesToSubmit.length > 0 ? filesToSubmit : undefined,
                },
              },
            });
          }
          // }
        }}
      >
        {({ status, setFieldValue, values, handleSubmit }) => {
          const handleSubCategoryChange = (item: any) => {
            getProvidersData({ variables: { subCategory: item.value } });
            if (values.provider) {
              setFieldValue(ProviderFormFields.provider, emptySelectorValue);
            }
            if (serviceProviderInputType) {
              serviceProviderValues.updateQuery(() => {
                return {
                  serviceProvider: null,
                } as any;
              });
            }
          };

          const handleProviderChange = (item: any) => {
            const identifierType = providersValues.data?.serviceProviders?.find((x) => x.id === item.value)
              ?.identifierType;
            getServiceProvider({ variables: { id: item.value } });
            if (identifierType === ServiceProviderIdentifierType.Custom) {
              getAccountDataDefinitions({ variables: { serviceProvider: item.value } });
            }
            setShowInputFields(true);
          };

          const spListCallback = (item: any, companySelected: boolean) => {
            if (companySelected) {
              setShowCategoryListForm(false);
              handleProviderChange(item);
              setFieldValue(ProviderFormFields.provider, item);
            } else {
              handleSubCategoryChange(item);
              setShowCategoryListForm(false);
              setCategorySelected(item);
            }
          };

          return (
            <>
              {!showCategoryListForm ? (
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  {!showSpInputFields && (
                    <>
                      <GridRow>
                        <GridCol size={controlSize}>
                          <Field
                            name={ProviderFormFields.allProvider}
                            label={t(Messages.labelSearchProvider)}
                            component={AutoComplete}
                            onSelectClick={handleAutoProviderChange}
                            placeholder={t(Messages.labelProviderAutoComplete)}
                            disabled={caseServiceProvider}
                            showIcon={isMobile}
                          />
                        </GridCol>
                      </GridRow>
                      {showOrSection && (
                        <GridRow>
                          <GridCol size={controlSize}>
                            <Typography
                              className={styles.providerSubtitle}
                              tag="p"
                              size="m"
                              msg={t(Messages.labelChooseProviderOption)}
                            />
                          </GridCol>
                        </GridRow>
                      )}
                      {showCategorySection && !isMobile && (
                        <>
                          <GridRow>
                            <GridCol size={controlSize}>
                              <Field
                                name={ProviderFormFields.category}
                                label={t(Messages.labelCategory)}
                                component={SelectInput}
                                options={serviceProviderCategories}
                                onSelectChange={handleSubCategoryChange}
                                disabled={caseServiceProvider}
                                selectClass={ClassNamesForOverwrite.SelectServiceProvider}
                                customStyles={customStyles}
                                isSearchable={false}
                              />
                            </GridCol>
                          </GridRow>

                          {providersValues.loading && <LoadingSpinner />}
                          <GridRow>
                            <GridCol size={controlSize}>
                              <Field
                                name={ProviderFormFields.provider}
                                label={t(Messages.labelProvider)}
                                component={SelectInput}
                                options={serviceProviders}
                                disabled={!serviceProviders || caseServiceProvider}
                                onSelectChange={handleProviderChange}
                              />
                            </GridCol>
                          </GridRow>
                        </>
                      )}

                      {showCategorySection && isMobile && (
                        <>
                          {!categorySelected && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(false);
                              }}
                              className={styles.buttonGaps}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={t(Messages.labelCategory)} tag="div" size="l" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}

                          {categorySelected && <Typography msg={t(Messages.labelCategory)} tag="div" size="l" />}

                          {categorySelected && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(false);
                              }}
                              className={styles.spSelected}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={categorySelected.label} tag="div" size="m" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}

                          {providersValues.loading && <LoadingSpinner />}

                          {!serviceProviders && (
                            <Typography
                              msg={t(Messages.labelProvider)}
                              tag="div"
                              size="l"
                              className={styles.choosCompanyOpaque}
                            />
                          )}

                          {serviceProviders && (
                            <Row
                              onClick={() => {
                                setShowCategoryListForm(true);
                                setCompanyForm(true);
                              }}
                              className={styles.buttonGaps}
                              constant
                              alignCenter
                              justifyBetween
                            >
                              <Typography msg={t(Messages.labelProvider)} tag="div" size="l" />
                              <Icon icon="chevronRight" size={IconSizes.s} />
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {serviceProviderInputType && showSpInputFields && (
                    <>
                      <Typography
                        bold
                        className={styles.providerTitle}
                        msg={
                          serviceProviderInputType === ServiceProviderIdentifierType.CantNotify
                            ? t("title_can't_notify" as Messages)
                            : t(Messages.titleProviderDetails)
                        }
                        tag="h3"
                      />
                      <Typography
                        className={styles.providerSubtitle}
                        tag="p"
                        size="m"
                        msg={
                          serviceProviderInputType === ServiceProviderIdentifierType.CantNotify
                            ? t("desc_can't_notify" as Messages)
                            : 'Please provide details of this account, we will send this information directly to the service provider'
                        }
                      />

                      <Row className={styles.profLogoCompany} alignCenter constant size={12}>
                        <Col>
                          {serviceProviderValues.data?.serviceProvider.logo ? (
                            <div className={styles.innerCircle}>
                              <img
                                className={styles.profLogo}
                                src={serviceProviderValues.data?.serviceProvider.logo}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className={styles.initialLogoContainer}>
                              <span>{serviceProviderValues.data?.serviceProvider.name.charAt(0).toUpperCase()}</span>
                            </div>
                          )}
                        </Col>
                        <Col>
                          <Typography
                            msg={serviceProviderValues.data?.serviceProvider.name || ''}
                            tag="div"
                            size="l"
                            bold
                          />
                        </Col>
                      </Row>

                      {slaInfo && (
                        <CautionBox
                          containerClassName={styles.cautionBox}
                          info={`Based on previous response times from this company, you should expect a response within ${serviceProviderValues.data?.serviceProvider.userSlaInDays} days of sending the notification.`}
                          title={t('label_info' as Messages)}
                        />
                      )}

                      {nonCustomNAIdentifierType() && (
                        <>
                          <GridRow>
                            <GridCol size={controlSize}>
                              <Field
                                name={ProviderFormFields.accountIdentifierPrimary}
                                type="text"
                                component={TextInput}
                                label={t(
                                  `${Messages.fieldProviderPrefix}${(serviceProviderInputType !==
                                  ServiceProviderIdentifierType.Bank
                                    ? serviceProviderInputType
                                    : ProviderBankFieldLabels.accountNumber
                                  ).toLowerCase()}` as Messages
                                )}
                              />
                            </GridCol>
                          </GridRow>
                          {serviceProviderInputType === ServiceProviderIdentifierType.Bank && (
                            <GridRow>
                              <GridCol size={controlSize}>
                                <Field
                                  name={ProviderFormFields.accountIdentifierSecondary}
                                  type="text"
                                  component={TextInput}
                                  label={t(
                                    `${Messages.fieldProviderPrefix}${(serviceProviderInputType !==
                                    ServiceProviderIdentifierType.Bank
                                      ? serviceProviderInputType
                                      : ProviderBankFieldLabels.sortCode
                                    ).toLowerCase()}` as Messages
                                  )}
                                />
                              </GridCol>
                            </GridRow>
                          )}
                        </>
                      )}

                      {isLifeCase &&
                        serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                        sortedProviderControls.length > 0 &&
                        sortedProviderControls
                          .filter((m: any) => m.isMandatory || m.isPrimaryIdentifier)
                          .map((item: any, idx: number) => {
                            return dynamicHtml(item, idx);
                          })}

                      {!isLifeCase &&
                        serviceProviderInputType === ServiceProviderIdentifierType.Custom &&
                        sortedProviderControls.length > 0 &&
                        sortedProviderControls.map((item: any, idx: number) => {
                          return dynamicHtml(item, idx);
                        })}
                    </>
                  )}

                  {serviceProviderInputType === ServiceProviderIdentifierType.CantNotify && (
                    <div id={serviceProviderValues.data?.serviceProvider.name} className="cant_notify">
                      <Typography
                        msg={t('label_cant_notify_content' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                        html
                        tag="div"
                        size="l"
                      />
                      <Button
                        className={styles.sendButtonSpacing}
                        onClick={() => {
                          //  window.dataLayer.push({
                          //   event: "Can't notify event",
                          //   eventProps: {
                          //     category: 'Button',
                          //     action: 'Clicked',
                          //     label: "Go to company's website",
                          //     value: serviceProviderValues.data?.serviceProvider.name,
                          //   },
                          // });
                          window.open(serviceProviderValues.data?.serviceProvider.webSite, '_blank');
                        }}
                        isFullWidth
                        label={t('button_go_to_company_website' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                      />
                      <Typography
                        size="m"
                        color="footerColor"
                        className={styles.spSelected}
                        msg={t('label_cant_notify_button_helper' as Messages).replaceAll(
                          '[COMPANY NAME]',
                          serviceProviderValues.data ? serviceProviderValues.data?.serviceProvider.name : ''
                        )}
                        tag="div"
                        textCenter
                        html
                      />
                    </div>
                  )}
                  <FormError formError={status} />

                  {serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify && !isLifeCase && (
                    <>
                      {serviceProviderInputType && (
                        <GridRow className={styles.sendButtonSpacing}>
                          <GridCol size={12}>
                            {agentUser ? (
                              <Tooltip
                                message={t('tooltip_agent_no_permission' as Messages)}
                                position={TooltipPosition.top}
                              >
                                <Button
                                  label={t('button_send_notification' as Messages)}
                                  size={ButtonSizes.fill}
                                  type={ButtonTypes.submit}
                                  iconSize={IconSizes.s}
                                  constant
                                  icon="paper-plane"
                                  disabled={isSubmitting || agentUser}
                                  loading={isSubmitting || serviceProviderValues.loading}
                                  onClick={() => {
                                    setSubmitAction('send');
                                    handleSubmit();
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <Button
                                label={t('button_send_notification' as Messages)}
                                size={ButtonSizes.fill}
                                type={ButtonTypes.submit}
                                iconSize={IconSizes.s}
                                constant
                                icon="paper-plane"
                                disabled={isSubmitting || agentUser}
                                loading={isSubmitting || serviceProviderValues.loading}
                                onClick={() => {
                                  setSubmitAction('send');
                                  handleSubmit();
                                }}
                              />
                            )}
                          </GridCol>
                        </GridRow>
                      )}
                    </>
                  )}
                  {serviceProviderInputType !== ServiceProviderIdentifierType.CantNotify && (
                    <GridRow className={classNames(styles.buttonGaps, styles.pb40)}>
                      <GridCol size={12}>
                        {serviceProviderInputType && (
                          <Button
                            label={secondButtonText}
                            size={ButtonSizes.fill}
                            iconSize={IconSizes.s}
                            icon={isLifeCase ? '' : 'save'}
                            style={isLifeCase ? undefined : ButtonStyles.transparent}
                            type={ButtonTypes.submit}
                            disabled={isSubmitting}
                            constant
                            loading={isSubmitting || serviceProviderValues.loading}
                            onClick={() => {
                              setSubmitAction('draft');
                              handleSubmit();
                            }}
                          />
                        )}
                      </GridCol>
                    </GridRow>
                  )}
                  {!showSpInputFields && (
                    <div className={classNames(styles.actions, styles.pb40)}>
                      <Row constant alignCenter>
                        <Typography msg="Can't find your provider? " tag="div" size="m" />
                        &nbsp;
                        <Typography
                          className={styles.handSymbol}
                          msg="Let us know"
                          underline
                          tag="div"
                          size="m"
                          tabIndex={0}
                          bold
                          onClick={onProviderButtonClick}
                          onKeyDown={(e: any) => {
                            if (e.keyCode === EnterKeyCode) {
                              onProviderButtonClick();
                            }
                          }}
                        />
                      </Row>
                    </div>
                  )}
                </Form>
              ) : (
                <SpList
                  isCompanyForm={companyForm}
                  spCallback={spListCallback}
                  categorySelected={categorySelected}
                  options={serviceProviderCategories}
                />
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProviderFormModalBody;
