import { Dispatch } from 'redux';

import { PaperWidths } from '@Components/Paper';
import { AppVersion, ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderFragment,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  DeathCaseListCardFragment,
  DeathCaseNode,
  DocumentDocumentType,
  LifeCaseFragment,
  RestrictedCaseServiceProviderNode,
  RestrictedDeathCaseNode,
} from '@Graphql/graphqlTypes.generated';
import { showModal } from '@Store/app/app.actions';
import { removeDocuments } from '@Store/dc/dc.actions';
import { Translator } from '@Utils/types';

export const deleteConfirmationHandler = (
  t: Translator,
  dispatch: Dispatch,
  documentType: DocumentDocumentType,
  isLifeCase?: boolean
) => (fileId: string, index: number) => {
  return dispatch(
    showModal({
      type: ModalTypes.confirmModal,
      params: {
        data: {
          onClose: () => dispatch(removeDocuments.started({ id: fileId, index, documentType, isLifeCase })),
          secondButtonText: 'Do not remove',
          buttonText: 'Remove',
        },
        title: t(Messages.modalRemoveDocumentTitle),
        subtitle: t(Messages.modalRemoveDocumentSubtitle),
        modalSize: PaperWidths.m,
      },
    })
  );
};

export const addProviderHandler = (
  t: Translator,
  id: DeathCaseNode['id'],
  dispatch: Dispatch,
  isLifeCase: boolean,
  appVersion: string,
  deathCaseData?: DeathCaseFragment,
  changeUrl?: () => void
) => {
  return dispatch(
    showModal({
      type: appVersion === AppVersion.second ? ModalTypes.smartModal : ModalTypes.providerFormModal,
      params: {
        data: {
          secondButtonText: isLifeCase ? t('button_add_companies' as Messages) : t('button_save_to_drafts' as Messages),
          buttonText: t(Messages.buttonCancel),
          isLifeCase,
          id,
          deathCaseData,
        },
        title: '',
        subtitle: '',
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
        scrollBar: true,
        isRenewModal: true,
        onClose: () => changeUrl && changeUrl(),
      },
    })
  );
};

export const editProviderHandler = (
  t: Translator,
  id: DeathCaseNode['id'],
  dispatch: Dispatch,
  isLifeCase: boolean,
  caseServiceProvider: CaseServiceProviderFragment,
  appVersion?: string
) => {
  return dispatch(
    showModal({
      type: appVersion === AppVersion.second ? ModalTypes.smartModal : ModalTypes.providerFormModal,
      params: {
        data: {
          secondButtonText: t(Messages.buttonSave),
          buttonText: t(Messages.buttonCancel),
          isLifeCase,
          id,
          caseServiceProvider,
        },
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
        scrollBar: true,
      },
    })
  );
};

export const addUserHandler = (t: Translator, dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.addUserModal,
      params: {
        title: t(Messages.modalAddUserFormTitle),
        modalSize: PaperWidths.l,
        fixedFormHeight: false,
        data: {
          firstButtonText: 'Send invite',
        },
      },
    })
  );
};

export const preventHandler = (isPaymentRequired: string | undefined, dispatch: Dispatch, priceId: string) => {
  return dispatch(
    showModal({
      type: ModalTypes.confirmModal,
      params: {
        data: {
          paymentMessage: isPaymentRequired,
          priceId,
        },
        title: '',
        modalSize: PaperWidths.m,
        isPaymentModal: true,
      },
    })
  );
};

export const logoUploadConfirmationHandler = (
  dispatch: Dispatch,
  isServiceProvider?: boolean,
  serviceProviderId?: string
) => {
  return dispatch(
    showModal({
      type: ModalTypes.logoPreviewModal,
      params: {
        data: {
          isServiceProvider,
          serviceProviderId,
        },
        title: '',
        modalSize: PaperWidths.m,
      },
    })
  );
};

export const showEmptyModalHandler = (title: string, content: string, dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.emptyContentModal,
      params: {
        data: {
          content,
        },
        title,
        modalSize: PaperWidths.m,
        isPaymentModal: true,
      },
    })
  );
};

export const termsAndConidtionsModalHandler = (dispatch: Dispatch, termsVersion?: string) => {
  return dispatch(
    showModal({
      type: ModalTypes.termsAndConditionsModal,
      params: {
        data: {
          termsVersion,
        },
        title: '',
        modalSize: PaperWidths.mm,
        isPaymentModal: true,
      },
    })
  );
};

export const newProviderHandler = (t: Translator, id: DeathCaseNode['id'], dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.newProviderFormModal,
      params: {
        data: {
          secondButtonText: 'Request',
          buttonText: t(Messages.buttonCancel),
          id,
        },
        title: 'Request a New Service Provider',
        subtitle:
          'Is the Service Provider you are looking for, is missing in the list? Send us the name of the provider and we shall add then at the earliest possible.',
        modalSize: PaperWidths.m,
        fixedFormHeight: true,
      },
    })
  );
};

export const idVerfiyfileUploadHandler = (
  dispatch: Dispatch,
  deathCaseServiceProviderId?: string,
  submitAction?: string
) => {
  return dispatch(
    showModal({
      type: ModalTypes.idVerfificationFileUploadModal,
      params: {
        data: {
          deathCaseServiceProviderId,
          submitAction,
        },
        modalSize: PaperWidths.verification,
        isNotificationModal: true,
      },
    })
  );
};

export const inviteCollaboratorHandler = (
  t: Translator,
  id: DeathCaseNode['id'],
  dispatch: Dispatch,
  isLifeCase: boolean
) => {
  dispatch(
    showModal({
      type: ModalTypes.inviteToCollaborateFormModal,
      params: {
        data: {
          secondButtonText: 'Send invite',
          buttonText: t(Messages.buttonCancel),
          id,
          isLifeCase,
        },
        // title: t(Messages.modalInviteCollaboratorTitle),
        // subtitle: t(Messages.modalInviteCollaboratorSubtitle),
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
      },
    })
  );
};

export const slugBasedDataModalHandler = (t: Translator, slug: string, dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.slugBasedDataModal,
      params: {
        data: {
          slug,
          buttonText: t(Messages.buttonGoBack),
        },
        title: t(Messages.modalPrivacyTitle),
        modalSize: PaperWidths.m,
      },
    })
  );
};

export const infoModalHandler = (t: Translator, title: Messages, subtitle: Messages, dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.confirmModal,
      params: {
        data: {
          buttonText: t(Messages.labelOk),
        },
        title: t(title),
        subtitle: t(subtitle),
        modalSize: PaperWidths.m,
      },
    })
  );
};

export const overFreeProvidersModalHandler = (t: Translator, onClose: () => void, dispatch: Dispatch) =>
  dispatch(
    showModal({
      type: ModalTypes.confirmModal,
      params: {
        data: {
          onClose,
          secondButtonText: t(Messages.buttonGoBack),
          buttonText: t(Messages.labelYes),
        },
        title: t(Messages.modalOverFreeProvidersTitle),
        subtitle: t(Messages.modalOverFreeProvidersSubtitle),
        modalSize: PaperWidths.m,
      },
    })
  );

export const confirmRegisteredDeathCaseHandler = (t: Translator, dispatch: Dispatch) => {
  return dispatch(
    showModal({
      type: ModalTypes.registeredDeathCaseConfirmFormModal,
      params: {
        data: {
          secondButtonText: t(Messages.buttonConfirm),
          buttonText: t(Messages.buttonReject),
        },
        title: t(Messages.modalConfirmRegisteredDeathCase),
        modalSize: PaperWidths.m,
        isPaymentModal: true,
      },
    })
  );
};

export const notificationStatusChangeHandler = (
  dispatch: Dispatch,
  accessCode: string,
  status: CaseServiceProviderStatus
) => {
  return dispatch(
    showModal({
      type: ModalTypes.notificationStatusUpdateFormModal,
      params: {
        data: {
          buttonText: 'Change status',
          accessCode,
          status,
        },
        title: 'Update status of death notification',
        modalSize: PaperWidths.l,
      },
    })
  );
};

export const somethingWrongHandler = (
  dispatch: Dispatch,
  deathCaseServiceProvider: RestrictedCaseServiceProviderNode,
  accessCode: string
) => {
  return dispatch(
    showModal({
      type: ModalTypes.somethingWrongModal,
      params: {
        data: {
          deathCaseServiceProvider,
          accessCode,
        },
        //title: 'Is there something wrong with the notification?',
        modalSize: PaperWidths.l,
        isNotificationModal: true,
      },
    })
  );
};

export const moreDCHandler = (dispatch: Dispatch, caseData: DeathCaseListCardFragment) => {
  dispatch(
    showModal({
      type: ModalTypes.mobileDcCardActionsModal,
      params: {
        data: {
          caseData,
        },
        title: '',
        subtitle: '',
        modalSize: PaperWidths.m,
        showCloseIcon: false,
      },
    })
  );
};

export const discoveryHandler = (dispatch: Dispatch, t: Translator, baseCaseId: string, isFirstTime?: boolean) => {
  dispatch(
    showModal({
      type: ModalTypes.discoveryModal,
      params: {
        data: {
          isFirstTime,
          baseCaseId,
        },
        title: !isFirstTime ? t('title_how_add_companies' as Messages) : '',
        subtitle: '',
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
      },
    })
  );
};

export const loadingHandler = (
  dispatch: Dispatch,
  logoName: string,
  title: string,
  firstMessage: string,
  secondMessage: string,
  firstMessageType: string
) => {
  dispatch(
    showModal({
      type: ModalTypes.loadingModal,
      params: {
        data: {
          logoName,
          title,
          firstMessage,
          secondMessage,
          firstMessageType,
        },
        title: '',
        subtitle: '',
        modalSize: PaperWidths.m672,
      },
    })
  );
};

export const deathCaseNotificationHandler = (
  dispatch: Dispatch,
  serviceId: string,
  isLifeCase?: boolean,
  caseData?: DeathCaseFragment | LifeCaseFragment | RestrictedDeathCaseNode,
  isDocUploaded?: boolean,
  backgroundColor?: string,
  goToTab?: () => void
) => {
  dispatch(
    showModal({
      type: ModalTypes.deathCaseNotificationModal,
      params: {
        data: {
          isLifeCase,
          caseData,
          serviceId,
          isDocUploaded,
          backgroundColor,
          goToTab,
        },
        title: '',
        subtitle: '',
        modalSize: PaperWidths.l1016,
        isNotificationModal: true,
      },
    })
  );
};

export const paymentHandler = (
  dispatch: Dispatch,
  caseId: string | undefined,
  packageTitle?: string,
  hideBasic?: boolean
) => {
  dispatch(
    showModal({
      type: ModalTypes.paymentFormModal,
      params: {
        data: {
          title: packageTitle || `You need to subscribe to add more service providers`,
          bodyMsg: `Simple planning to reduce the administrative burden on the family after a death for just`,
          // onClose: () => switchToSubscription(),
          baseCase: caseId || '',
          hideBasic,
        },
        title: '',
        modalSize: PaperWidths.m672,
        noPadding: true,
      },
    })
  );
};

export const discoveryDescHandler = (dispatch: Dispatch, caseData: LifeCaseFragment | undefined) => {
  dispatch(
    showModal({
      type: ModalTypes.discoveryDescModal,
      params: {
        data: {
          baseCase: caseData ? caseData.id : '',
        },
        title: '',
        modalSize: PaperWidths.m672,
        noPadding: true,
      },
    })
  );
};

export const paymentModeHandler = (
  amount: number,
  dispatch: Dispatch,
  baseCase: string,
  priceId: string,
  packageId: string
) => {
  dispatch(
    showModal({
      type: ModalTypes.paymentModeModal,
      params: {
        data: {
          amount,
          baseCase,
          priceId,
          packageId,
        },
        title: '',
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
      },
    })
  );
};

export const saveChangesHandler = (
  dispatch: Dispatch,
  path: string,
  subpage?: string,
  id?: string,
  isLogout?: boolean
) => {
  return dispatch(
    showModal({
      type: ModalTypes.saveChangesModal,
      params: {
        data: {
          path,
          subpage,
          id,
          isLogout,
        },
        title: '',
        modalSize: PaperWidths.yotiWidth,
      },
    })
  );
};

export const obNeverLeaveHandler = (dispatch: Dispatch, lifeCaseId: string) => {
  return dispatch(
    showModal({
      type: ModalTypes.obNeverLeaveModal,
      params: {
        data: {
          lifeCaseId,
        },
        title: '',
        modalSize: PaperWidths.m581,
      },
    })
  );
};

export const paymentUpgradeHandler = (t: Translator, dispatch: Dispatch, caseData: LifeCaseFragment | undefined) => {
  dispatch(
    showModal({
      type: ModalTypes.paymentUpgradeModal,
      params: {
        data: {
          title: t('title_upgrade_ll_discovery' as Messages),
          bodyMsg: t('info_upgrade_ll_discovery' as Messages),
          // onClose: () => switchToSubscription(),
          baseCase: caseData ? caseData.id : '',
        },
        title: '',
        modalSize: PaperWidths.m672,
        noPadding: true,
      },
    })
  );
};

export const cancelSubscriptionConfirmHandler = (dispatch: Dispatch, subscriptionId: string) => {
  dispatch(
    showModal({
      type: ModalTypes.cancelSubscriptionConfirmModal,
      params: {
        data: {
          subscriptionId,
        },
        title: '',
        modalSize: PaperWidths.m672,
      },
    })
  );
};

export const basicPaymentHandler = (
  t: Translator,
  dispatch: Dispatch,
  caseId: string,
  isHospice: boolean,
  isFromServices?: boolean,
  modalTitle?: string
) => {
  dispatch(
    showModal({
      type: ModalTypes.basicServicePayment,
      params: {
        data: {
          title: isHospice ? t('title_hospice_life_case_created' as Messages) : modalTitle,
          bodyMsg: isHospice
            ? t('desc_hospice_life_case_created' as Messages)
            : `Simple planning to reduce the administrative burden on the family after a death for just`,
          firstButtonMsg: isHospice ? t('button_continue_to_payment' as Messages) : 'Subscribe now',
          secondButtonMsg: 'No thanks',
          caseId,
          isHospice,
          isFromServices,
        },
        title: '',
        modalSize: PaperWidths.mm,
        showCloseIcon: !isHospice,
      },
    })
  );
};

export const renewSubscriptionHandler = (dispatch: Dispatch, cancelledSubscription: any) => {
  dispatch(
    showModal({
      type: ModalTypes.renewSubscription,
      params: {
        data: {
          cancelledSubscription,
        },
        title: '',
        modalSize: PaperWidths.m672,
        noPadding: true,
        disableOutsideClick: true,
      },
    })
  );
};

export const registerLifeCaseModal = (dispatch: Dispatch, packageSelected: any) => {
  return dispatch(
    showModal({
      type: ModalTypes.registerLCModal,
      params: {
        title: '',
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
        scrollBar: true,
        data: {
          packageSelected,
        },
      },
    })
  );
};

export const newWelcomeModal = (dispatch: Dispatch, isIllProp?: boolean) => {
  return dispatch(
    showModal({
      type: ModalTypes.newWelcomeModal,
      params: {
        data: {
          isIllProp,
        },
        title: '',
        modalSize: PaperWidths.m672,
        isNotificationModal: true,
        scrollBar: true,
        showCloseIcon: false,
        disableOutsideClick: true,
      },
    })
  );
};
