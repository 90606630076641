import { useMutation } from '@apollo/react-hooks';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import Col from '@Components/layout/Col';
import SelectOnly, { ClassNamesForOverwrite } from '@Components/Select/SelectOnly';
import { Messages } from '@Config/messages';
import { CasePortalAssignUserMutation, CasePortalAssignUserMutationVariables } from '@Graphql/graphqlTypes.generated';
import { mutationCasePortalAssignUser } from '@Graphql/serviceProvidersPortal/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import { normaliseGqlError } from '@Utils/form';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './CaseSummary.scss';
import { AssigneeSelectionOptions } from './CaseSummaryContainer';

export interface SpStaffAssigneeProps {
  rec: any;
  usersList: any;
  staffAssigneeCallback?: (notifcationRef: string, assigneeId: string) => void;
  assignedList?: AssigneeSelectionOptions[];
}

const SpAssignee: React.FunctionComponent<SpStaffAssigneeProps> = ({
  rec,
  usersList,
  assignedList,
  staffAssigneeCallback,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [assignUser] = useMutation<CasePortalAssignUserMutation, CasePortalAssignUserMutationVariables>(
    mutationCasePortalAssignUser,
    {
      onCompleted: () => {
        dispatch(notificationSuccess(t(Messages.notificationProviderUserAssigned)));
      },
      onError: (error: any) => {
        dispatch(notificationError(normaliseGqlError(error.message)));
      },
    }
  );

  const currentStaff = assignedList?.find((asl: AssigneeSelectionOptions) => asl.notificationRef === rec.id);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      border: '1px solid #C4C4C4 !important',
      borderRadius: '4px',
      paddingLeft: '8px !important',
      paddingRight: '6px !important',
    }),
  };

  const getSelectedOption = () => {
    if (currentStaff) {
      return usersList.find((userDetail: any) => userDetail.value === currentStaff.staffAssigneeId);
    }
    if (rec.staffAssigned.serviceProviderUser && usersList) {
      return usersList.find((userDetail: any) => userDetail.value === rec.staffAssigned.serviceProviderUser.id);
    }
    return null;
  };

  const selectedOption = getSelectedOption();

  const [assignedOption, setAssignedOption] = React.useState(selectedOption);

  React.useEffect(() => {
    setAssignedOption(selectedOption);
  }, [selectedOption]);

  const handleUserChange = (item: any) => {
    setAssignedOption(item);
    //eslint-disable-next-line no-unused-expressions
    staffAssigneeCallback && staffAssigneeCallback(rec.id, item.value);
    //eslint-disable-next-line
    assignUser({ variables: { input: { caseId: rec.id, assignUserId: item.value } } });
  };

  return (
    <Col className={styles.assignedToSelectSpacing} textCenter alignCenter>
      <SelectOnly
        selectClass={ClassNamesForOverwrite.SelectContainment}
        options={usersList}
        value={assignedOption}
        onChange={handleUserChange}
        placeholder={Messages.fieldUnassignedPlaceholder}
        customStyles={customStyles}
      />
    </Col>
  );
};

export default SpAssignee;
